import SubmitButton from 'components/form/SubmitButton'
import React from 'react'
import { Link, Redirect, RouteComponentProps } from 'react-router-dom'

import oops from 'assets/images/oops.svg'
import EventsAdapter from 'helpers/EventsAdapter'
import Routing from 'constants/routingConstants'
import WorkizLang from 'language/WorkizLang'
import User from 'helpers/User'
import { nl2br } from 'helpers/StringFormats'
import urlConstants from 'constants/urlConstants'
import NetworkManager from 'helpers/NetworkManager'

const oopsImage = process.env.REACT_APP_WEB ? NetworkManager.buildBaseUrl(urlConstants.assets.oops) : oops;
/**
 * 
 * This page is renders when user / account is blocked for login
 * Reasons could be - account canceled, billing issues, user ip block etc.
 */
function NotAllowed(props: RouteComponentProps) {
    const loginStatus = User.get().userData?.blockLoginStatus

    /**
     * Renders main action button.
     * If we have an action url from backend we will provide it, 
     * so the user will be able to go there and solve whatever issue they were having
     * Else the action will be to just try again
     */
    const Action = () => {
        if (loginStatus?.actionURL) {
            return (
                <div className="margin-horizontal">
                    <SubmitButton
                        onClick={() => {
                            EventsAdapter.openExternalLink(loginStatus.actionURL)
                        }}
                        title={loginStatus.actionTitle ?? "Check it in Workiz web"}
                    />
                </div>
            )
        }
        return (
            <div className="margin-horizontal">
                <SubmitButton
                    onClick={() => {
                        props.history.push(Routing.Home)
                    }}
                    title={"Try Reloading"}
                />
            </div>
        )
    }

    const TryAgain = () => {
        if (loginStatus?.actionURL) {
            return (
                <Link to={Routing.Home} className="pointer _clearLink center" >
                    Try Again
                </Link>
            )
        }
        // There was no action so url, so main button is already showing "Try Again"
        // No need to show this same action again ;)
        return null;
    }

    /**
     * Always give the user the ability to logout and login with another credentials, 
     * so it won't be stuck forever in this page
     */
    const Logout = () => {
        return (
            <div className="center-content margin-top margin-bottom flex-column">
                <p className="small-margin-bottom">
                    OR
                </p>
                <div>
                    <a onClick={() => EventsAdapter.logout()} className={"pointer _clearLink center"}>
                        {WorkizLang.get().login.logout}
                    </a>
                </div>
            </div>
        )
    }

    if (!User.get().userData) {
        return (
            <Redirect to={Routing.Home} />
        )
    }

    return (
        <div className="full-size flex-column padding-horizontal padding-vertical">
            <div className="flex-one center-content margin-horizontal flex-column">
                <img src={oopsImage} />
                <h2 className="margin-top">
                    Oops
                </h2>
                <p className="margin-top margin-bottom center-text">
                    {nl2br(loginStatus?.reason || "Your login has failed")}
                </p>
                <TryAgain />

            </div>
            <Action />
            {
                !process.env.REACT_APP_WEB &&
                <Logout />
            }
        </div>
    )
}

export default NotAllowed
