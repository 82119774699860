import { CallHistoryItemPrepped, CallsHistoryItem } from 'constants/types'
import moment from 'moment'
import React, { useState,useEffect } from 'react'
import * as stylesHelper from 'styles/stylesHelper'
import ToolTip from 'react-tooltip-lite'
import AudioBar from 'components/layout/AudioBar'
import './callHistoryPage.scss'
import { goToURL } from 'helpers/WorkizHelper'
import urlConstants from 'constants/urlConstants'
import Modal from 'components/layout/Modal'
import User from 'helpers/User'
import Restrictions from 'constants/restrictions'
import SendRecordingsForm from 'components/form/SendRecordingsForm'
import AddNew from '../ModalsContents/AddNew'
import ActionButtons from 'components/layout/ActionButtons'
import ListRow from 'components/layout/ListRow'
import { Button } from '@workiz/ui'; 
import WorkizLang from 'language/WorkizLang'
import TwilioManager from 'helpers/TwilioManager'
import { ContactSuggestion } from 'constants/types'
import { phoneFormat } from 'helpers/StringFormats'
import * as dialerConstants from 'constants/dialerConstants';

type modalOptions = "" | "plus" | "send"

function CallHistoryPage(props: { call: CallHistoryItemPrepped | null, setActiveCallModal: (item: CallHistoryItemPrepped | null) => void }) {

    const [openModal, setOpenModal] = useState<modalOptions>("")
    const [callToObject, setCallToObject] = useState<ContactSuggestion | undefined>(undefined)
    const trimmedCallerDesc = props.call?.callerDesc.trim();
    const isClient = props.call?.type === "3" && Number(props.call?.client_id) > 0
    let desc =  (isClient && trimmedCallerDesc) || props.call?.callerNumber || trimmedCallerDesc;
    if (!isClient && User.get().shouldUseMasking() ) {
        desc = dialerConstants.maskingNumber
    }
    useEffect(() => {
        if (props.call) {
            const call = props.call
            const callTo = (call.direction === "inbound" ? call.fromNumber : call.toNumber) ?? ""
            setCallToObject({
                client_company_name: "",
                data: call.client_id ?? "",
                name: call.clientName ?? "",
                phone: callTo,
                phoneFormatted: phoneFormat(callTo, false, true),
                type: call.client_id ? "3" : "-1",
                client_id: call.client_id ?? "",
                email: ""
            })
        }
    }, [props.call])
    
    if (!props.call) return <div />

    const getModalContent = () => {
        switch (openModal) {
            case "plus":
                return <AddNew call={call} />
            case "send":
                return (
                    <SendRecordingsForm
                        closeModal={() => setOpenModal("")}
                        recordings={call.recordings}
                    />
                )

            default:
                break;
        }
        return <span />
    }

    const getModalHeader = () => {
        switch (openModal) {
            case "plus":
                return WorkizLang.get().workizWeb.addNew
            case "send":
                return WorkizLang.get().workizWeb.sendVia
            default:
                break;
        }
        return ""
    }

    const call = props.call
    const styleObj = stylesHelper.getCallStatusDesign(props.call)
    

    const renderSendRecording = () => {
        return (
            <div onClick={() => setOpenModal("send")} className="pointer bold hoverLink margin-left">
                Send Recording
            </div>
        )
    }

    const renderRecordings = () => {
        const shouldRenderSendRecordings = call.recordings.length > 0;

        return (
            <>
                <AudioBar recordings={call.recordings} />
                {shouldRenderSendRecordings ? <div>{renderSendRecording()}</div> : null}
            </>
        )
    }

    const getNumberBySide = (call: CallsHistoryItem, side: "other" | "account") => {
        return side === "other" ? (call.direction === "outgoing" ? call.toNumber : call.fromNumber) : (call.direction === "outgoing" ? call.fromNumber : call.toNumber);
    }

    const getActionButtons = (call: CallHistoryItemPrepped) => {
        const buttons: Record<string, any> = {};
        if (!User.get().shouldUseMasking() && call.phone) {
            buttons.call = {
                icon: "wfi-Phone",
                text: "Call",
                action: (e :React.MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    TwilioManager.getInstance().placeCall(call.phone,callToObject)
                }}
        }
        if (User.get().isAllowed(Restrictions.Messaging) && call.isClient) {
            buttons.message = {
                icon: "wfi-message",
                text: "Message",
                action: () => {
                  const messagingURL = `${urlConstants.workizWeb.message}${call?.client_id ?? ""}-${call?.client_id ? "3" : ""}`;
                  goToURL(messagingURL, true);
                },
              };
            }
        return buttons;
        }
    
    const getCallInfoList = () => {
        const info_array = [
          { title: "Status", value:<div className='flex-1 center-content'><div className='icon-container'><i className={styleObj[call?.direction!]}></i></div><div className='small-margin-left'>{styleObj.label}</div></div> },
          { title: call?.direction == "outgoing"? "From": "To", value: <div>{getNumberBySide(call,"account")}</div>  },
        ];
      
        if (call.flow_name) {
            info_array.splice(1, 0, { title: "Call flow", value: <div>{call.flow_name}</div> });
        }
        const infoElements = info_array.map((row, i) => (
          <ListRow
            key={i}
            title={<div>{row.title}</div>}
            rightTop={row.value}
            withoutBorderBottom={i === 1}
          />
        ));
      
        return (
          <div className='info-list'>
            {infoElements}
          </div>
        );
      };
        
    
    return (
            <div className= "tab-container" >
            <div className="flex-column padding-screen" style={{marginTop:24}} >
                <div className="flex-row pointer" onClick={() => props.setActiveCallModal(null)}>
                    <i className="wfi-back-chevron margin-right bold"></i>
                    <div className="bold">Call details</div>
                </div>
            </div>
            <div className='with-scroll padding-screen min-height-tab'>
                <div className="center-text callDescLine big-text">{desc}</div> 
                {!User.get().shouldUseMasking() && call.isClient && <div className="center-text font-14 callDescNumber">{getNumberBySide(call,"other")}</div>}
                <div className="center-content add-new-container">
                {(User.get().isAllowed(Restrictions.AddJobs) && (call.isClient || (call.type != "0"))) &&
                <Button extraClass='without-outline' size="big" icon='wfi-big big-text' type="secondary" text = "Add new" clickHandler={() => setOpenModal("plus")}/>}
                </div>
                <div>
                    <ActionButtons extraClass='call-details' buttons = {getActionButtons(call)}/>
                </div>
            
                {getCallInfoList()}
                {renderRecordings()}
            
                <Modal
                    show={openModal !== ""}
                    onClose={() => setOpenModal("")}
                    title={getModalHeader()}
                >
                    {getModalContent()}
                </Modal>
                </div>
            </div>
    )
}

export default CallHistoryPage
