import React, { useState } from 'react'
import './form.scss'

/**
 * 
 * @param {import('react').InputHTMLAttributes} props 
 * @returns 
 */
function FloatingLabelInput(props) {

    const [isFocused, setIsFocused] = useState(false);
    const [hasError, setHasError] = useState("")

    return (
        <div className={props.className}>
            <input
                {...props}
                className={"floatingLabelInput " + (hasError ? "with-error" : "")}
                onFocus={() => setIsFocused(true)}
                onChange={(e) => {
                    if (e.target.value.length) {
                        setHasError("")
                    }
                    if (typeof props.onChange === "function") {
                        props.onChange(e)
                    }
                }}
                onBlur={() => { props.value?.length ? setHasError("") : setHasError("This field is required") }}
            />
            <div className="margin-horizontal input-error red">
                {hasError}
            </div>
        </div>
    )
}

export default FloatingLabelInput
