import moment from 'moment'
import React, { Component } from 'react'


interface TimerProps {
    active: boolean,
    startingTime: moment.Moment,
}

interface TimerState {
    timeStr: string
}

export default class Timer extends Component<TimerProps, TimerState> {

    state = {
        timeStr: "00:00:00",
    }

    interval?: NodeJS.Timeout

    componentDidMount() {
        this.intervalCallback();
        this.setProps()
    }

    componentDidUpdate() {
        this.setProps();
    }

    stopInterval() {
        if (this.interval) {
            clearInterval(this.interval)
            this.interval = undefined
        }
    }

    intervalCallback = () => {
        if (!this.props.active) {
            this.setState({ timeStr: "00:00:00" })
            this.stopInterval()
            return;
        }
        const duration = moment.duration(moment().diff(this.props.startingTime));
        const timeStr = moment.utc(duration.asMilliseconds()).format("HH:mm:ss")
        this.setState({ timeStr: timeStr })
    }

    setProps() {
        if (this.props.active && !this.interval) {
            this.interval = setInterval(this.intervalCallback.bind(this), 1000);
        }
    }

    render() {
        return (
            <div >
                {this.state.timeStr}
            </div>
        )
    }
}
