import React from 'react'
import './modal.scss'

interface ModalProps {
    title?: string,
    /** default is true, if false - content will not have scroll ability */
    scrollAble?: boolean,
    fullscreen?: boolean,
    show: boolean,
    headComponent?: string | JSX.Element ,
    onClose: VoidFunction,
    titleComponent?: string | JSX.Element ,
    darkTitle?: boolean,
    hideClose?: boolean,
    extraClass?: string,
}

function Modal(props: React.PropsWithChildren<ModalProps>) {

    let visibleClass = "";
    if (props.show) {
        visibleClass = "visible";
    }

    const Title = () => {
        if (props.title) {
            return <div className="modal-title flex-one">{props.title}</div>
        }
        return <div className={"flex-one"} />;
    }

    const CloseButton = () => {
        if (props.hideClose) return null;
        return <i className={" wfi-x pointer close-icon"} onClick={props.onClose} />
    }

    const getTitle = () => {
        if (props.titleComponent) {
            return props.titleComponent
        }
        return (
            <div className={`flex-row modal-header ${props.darkTitle ? "dark" : ""}`}>
                <Title /> <CloseButton />
            </div>
        )
    }

    return (
        <>
            <div className={"modal-container " + visibleClass} onClick={props.onClose} />
            <div className={`modal animated scroll-container ${props.fullscreen ? "fullscreen" : ""} ${visibleClass} ${props.extraClass ?? ""}`}>
                {getTitle()}
                {props.headComponent ? props.headComponent : null}
                <div className={props.scrollAble === false ? "" : " with-scroll"}>
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default Modal
