
import { CallQueueReduxState, callQueueActions, CallQueueActionType } from 'constants/storeConstants';

export const setCallQueue = (payload: string): CallQueueActionType => {
    return {
        type: callQueueActions.SetList,
        payload
    }
}

export const pushToCallsHistory = () => {

}

export const updateCallsHistoryItem = () => {

}

export const setOpenQueueName = (payload: string) => {
    return {
        type: callQueueActions.SetOpenQueueName,
        payload
    }
}

export const setQueueOpenStatus = (payload: string) => {
    return {
        type: callQueueActions.setQueueOpenStatus,
        payload
    }
}