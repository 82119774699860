import TwilioManager from 'helpers/TwilioManager';
import React from 'react'
import "./userStatus.scss"

interface UserStatusProps {
    username?: string,
    onClick: VoidFunction
}


function UserStatus(props: UserStatusProps) {
    let initials = "W";
    if (typeof props.username === "string") {
        initials = props.username.toUpperCase().split(" ").map(_ => _[0]).join("").substr(0, 2);
    }

    return (
        <div className="status-indicator" onClick={props.onClick}>
            <div>
                <span className={"statusIndicatorText"}>{initials}</span>
                <span className={"indicator " + (TwilioManager.getInstance().available ? "available" : "unAvailable")}></span>
            </div>
        </div>
    )
}

export default UserStatus
