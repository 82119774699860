import React from 'react'
import ListRow from 'components/layout/ListRow'
import { createForm } from 'helpers/WorkizHelper'
import urlConstants from 'constants/urlConstants'
import WorkizLang from 'language/WorkizLang'
import { CallHistoryItemPrepped } from 'constants/types'
import User from 'helpers/User'
import Addons from 'constants/addons'
import Restrictions from 'constants/restrictions'

function AddNew(props: { call: CallHistoryItemPrepped }) {

    const call = props.call

    const createJob = (isLead: boolean) => {
        createForm({
            adgroup_id: props.call.ad_group_id,
            call_sid: props.call.call_sid, 
            client_id: (props.call.user_type == "3" || props.call.type == "3") ? props.call.client_id : undefined,
            fromCall: true,
            primary_phone: props.call.phone,
        }, isLead ? urlConstants.workizWeb.createLead : urlConstants.workizWeb.createJob)
    }

    const createClient = () => {
        createForm({
            adgroup_id: props.call.ad_group_id, 
            call_sid: props.call.call_sid, 
            newClient: "1",
            primary_phone: props.call.phone,
        }, urlConstants.workizWeb.createClients)
    }

    const lang = WorkizLang.get().workizWeb

    return (
        <div>
            {!call.isClient && <ListRow  isLeftIconInsideRow title={lang.client} iconName={"wfi-clients-users big-text blue"} onClick={() => createClient()} />}
            {User.get().isAddonOn(Addons.Leads) && User.get().isAllowed(Restrictions.Leads) ? <ListRow isLeftIconInsideRow title={lang.lead} iconName={" wfi-leads big-text blue"} onClick={() => createJob(true)} /> : null}
            <ListRow isLeftIconInsideRow title={lang.job} iconName={"wfi-job big-text blue"} onClick={() => createJob(false)} />
        </div>
    )
}

export default AddNew
