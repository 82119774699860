import React, { useState } from 'react'
import './activeCall.scss';
import { useSelector } from 'react-redux';
import { DialerRootReducer } from 'store/reducers/rootReducer';
import IncomingCall from './IncomingCall';
import ConferenceCall from './ConferenceCall';
import OnCallContent from './OnCallContent';
import TransferringCall from './TransferringCall'
import CallQIndicator from 'components/dialer/CallQIndicator';
import WorkizLang from 'language/WorkizLang';
import Modal from 'components/layout/Modal';
import CallsHistory from 'containers/Dialer/Tabs/CallsHistory';
import CallNotifications from 'components/layout/CallNotifications';
import NetworkManager from 'helpers/NetworkManager'
import urlConstants from 'constants/urlConstants'

interface ActiveCallProps {
    queueCounter: number,
    active: boolean | undefined
}

type headerModalTypes = "" | "queue";

interface Warnings {
    connection: string,
    audio: string,
    reconnecting: string
}

const warningData: Warnings = {
    connection:WorkizLang.get().dialer.wifiWarning,
    audio: WorkizLang.get().dialer.audioWarning,
    reconnecting:  WorkizLang.get().dialer.reconnecting
}

function ActiveCall(props: ActiveCallProps) {
  
    const voiceState = useSelector((state: DialerRootReducer) => state.voice)
    const incomingCallClass = voiceState.ringing ? "incoming-active" : "";
    const [modalOpen, setModalOpen] = useState<headerModalTypes>("")
    const [hideNotification, setHideNotification] = useState(false)

    const getTitleComponent = () => {
        return (
            <div className={`flex-row modal-header`}>
                <div  onClick={() => setModalOpen("")}>
                    <i className={"wi-chevron-left font-16"} />
                    <div className={"flex-one modal-title "}>{WorkizLang.get().dialer.callsInQueue}</div>
                </div>
               
               
            </div>
        )
    }
    const Content = () => {
        if (voiceState.ringing) {
            return (
                <>
                    <IncomingCall />
                    <Modal
                        show={modalOpen !== ""}
                        title={WorkizLang.get().dialer.callsInQueue}
                        onClose={() => setModalOpen("")}
                        fullscreen={true}
                        titleComponent = {getTitleComponent()}
                    >
                        <CallsHistory active queueView setActiveCallModal={() => { }} />
                    </Modal>
                </> 
            )
        }
        if (voiceState.onConference) {
            return <ConferenceCall />
        }
        if (voiceState.transferring) {
            return <TransferringCall />
        }
        return <OnCallContent />
    }

    const Header = () => {
        const desc = voiceState.ringing ? "" : WorkizLang.get().dialer.activeCall

        return (
            <div className={"header center flex-row mini-margin-top padding-screen"}>
                <div className="flex-one">{desc}</div>
                <CallQIndicator queueCounter={props.queueCounter} onClick={() => setModalOpen("queue")}/>
            </div>
        )
    }

    const Notification = () => {
        if(voiceState.activeCall && voiceState.activeCall.activeWarning?.length && voiceState.activeCall.warningType) {
            setHideNotification(false)
            const warningType = voiceState.activeCall.activeWarning.includes('reconnecting') ? 'reconnecting' : voiceState.activeCall.warningType;
            const warningTypeAsKey = warningType as keyof Warnings;
            return (
                <CallNotifications icon={"wfi-Warning-Circle-fill"} text={warningData[warningTypeAsKey]} onClose={()=>setHideNotification(true)}/>
            )
        }
        return null
    }

    return (
        <div className={props.active? "": "inactive"}>
            <div className={"flex-column activeCallContainer animated " + " " + incomingCallClass}>
                <Header />
                {!hideNotification &&<Notification />}
                <Content />
            </div>
        </div>
    )
}

export default ActiveCall
