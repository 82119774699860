import Restrictions from 'constants/restrictions'
import { tabTypes } from 'constants/storeConstants'
import User from 'helpers/User'
import WorkizLang from 'language/WorkizLang'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setActiveTab } from 'store/actions/navigationActions'
import { DialerRootReducer } from 'store/reducers/rootReducer'
import { CallHistoryItemPrepped } from "../../constants/types";
import { trackEvent, WorkizEvents } from '../../helpers/TrackActions';

const tabsIcons = {
    [tabTypes.KEYPAD]: "wfi-Dial-Pad",
    [tabTypes.CONTACTS]: "wfi-clients-users",
    [tabTypes.HISTORY]: "wfi-timeline"
}

const activeTagPos = {
    [tabTypes.KEYPAD]: "66%",
    [tabTypes.CONTACTS]: "33%",
    [tabTypes.HISTORY]: 0
}

const eventsTabsConfig = {
    [tabTypes.KEYPAD]: WorkizEvents.ClickedKeypadDialer,
    [tabTypes.CONTACTS]: WorkizEvents.ClickedContactsDialer,
    [tabTypes.HISTORY]: WorkizEvents.ClickedRecentsDialer
}

function Footer(props: { setActiveCallModal: (item: CallHistoryItemPrepped | null) => void}) {

    const navState = useSelector((state: DialerRootReducer) => state.navigation)
    const dispatch = useDispatch()
    const isAllowedClients = User.get().isAllowed(Restrictions.Clients);
   
    if (!isAllowedClients) {
        activeTagPos[tabTypes.KEYPAD] = "50%"
    }
   
    const handleClick = ( kind: tabTypes ) => {
        dispatch(setActiveTab(kind))
        props.setActiveCallModal(null)
        trackEvent(eventsTabsConfig[kind])
    }
    const Tab = (props: { kind: tabTypes, label: string}) => {
        const selectedTab = useSelector((state: DialerRootReducer) => state.navigation.tab)
        return (
            <div
                className={`flex-one center bottom-tab pointer ${selectedTab === props.kind ? " selected" : ""}`}
                onClick={() => handleClick(props.kind)}>
                <i className={tabsIcons[props.kind]}  />
                <div className={`tab-label ${selectedTab === props.kind ? " selected" : ""}`}>{props.label}</div>
            </div>
        );
    }

    return (
        <div className={"flex-row bottom-tabs"}>
            <Tab kind={tabTypes.HISTORY} label={WorkizLang.get().dialer.history} />
            {isAllowedClients && <Tab kind={tabTypes.CONTACTS} label={WorkizLang.get().dialer.contacts} />}
            <Tab kind={tabTypes.KEYPAD} label={WorkizLang.get().dialer.keypad} />
        </div>
    )
}

export default Footer
