import { CallsHistoryItem } from "constants/types";

interface StatusStyleObj {
    inbound: string,
    outgoing: string,
    label: string
}

const callStatusClassNames: { [key: string]: StatusStyleObj } = {
    completed: {
        outgoing: "wfi-Outgoing-call bright-text ",
        inbound: "wfi-Incoming-call bright-text ",
        label: "Completed call",
    },
    "no-answer": {
        outgoing: "wfi-Outgoing-call red",
        inbound: "wfi-Incoming-call red",
        label: "No answer",
    },
    voicemail: {
        outgoing: "wfi-Voicemail bright-text",
        inbound: "wfi-Voicemail bright-text",
        label: "Left voicemail",
    },
    ringing: {
        outgoing: "wfi-Phone-Actions-Ring green",
        inbound: "wfi-Phone-Actions-Ring green",
        label: "Active call",
    },
    "in-progress": {
        outgoing: "wfi-Phone-Actions-Ring green",
        inbound: "wfi-Phone-Actions-Ring green",
        label: "Active call",
    },
    rejected: {
        outgoing: "wfi-Outgoing-call red",
        inbound: "wfi-Incoming-call red",
        label: "No answer",
    }
}

export const getCallStatusDesign = (call: CallsHistoryItem): StatusStyleObj => {
    let callStatus = call.dial_call_status ?? "";
    if (!callStatusClassNames[callStatus]) {
        if (call.rejected == 1 || call.dial_call_status == "busy") {
            callStatus = "rejected";
        } else {
            if (!call.recording_url) {
                callStatus = "no-answer";
            }
            if (call.call_status == "in-progress") {
                callStatus = "in-progress";
            }
        }
    }

    if (call.voicemail == "1" && call.recording_url) {
        callStatus = "voicemail";
    }

    if (callStatusClassNames[callStatus]) {
        return callStatusClassNames[callStatus]
    }
    return callStatusClassNames.completed;
}
