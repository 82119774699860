import React from 'react'
import './callButton.scss'

interface CallButtonProps {
    disable?: boolean,
    isHangup?: boolean,
    onClick?: VoidFunction,
    extraClass?: string
    small?: boolean,
    iconClassName?: string,
    dark?: boolean,
}

function CallButton(props: CallButtonProps) {

    let containerClassName = "call-button";
    if (props.isHangup) {
        containerClassName += " hangup";
    }
    if (props.disable) {
        containerClassName += " disabled"
    } else {
        containerClassName += " pointer"
    }

    if (props.small) containerClassName += " small"
    if (props.dark) containerClassName += " dark"

    if (props.extraClass && props.iconClassName) {
        containerClassName += " " + props.extraClass;
    }

    let iconClassName = props.iconClassName
    if (!iconClassName) {
        iconClassName = props.isHangup ? "wfi-hang-up" : "wfi-call"
        if (!props.disable)
            containerClassName += " circle-border"
    }

    const content = (
        <div className={containerClassName} onClick={props.disable ? undefined : props.onClick} >
            <i className={iconClassName} />
        </div>
    )

    if (!props.iconClassName && !props.disable) {
        return (
            <div className={"circle-border " + props.extraClass ?? ""}>
                {content}
            </div>
        )
    }
    return content;
}

export default CallButton
