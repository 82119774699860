import React from 'react'
import EventsAdapter from 'helpers/EventsAdapter'
import SubmitButton from 'components/form/SubmitButton'
import TwilioManager from 'helpers/TwilioManager'
import oops from 'assets/images/oops.svg'
import urlConstants from 'constants/urlConstants'
import NetworkManager from 'helpers/NetworkManager'

const oopsImage = process.env.REACT_APP_WEB ? NetworkManager.buildBaseUrl(urlConstants.assets.oops) : oops;

function DeviceFailure(props: { msg: string }) {
    return (
        <div className="full-size flex-column padding-horizontal padding-vertical">
            <div className="flex-one center-content margin-horizontal flex-column">
                <img src={oopsImage} />
                <h2 className="margin-top">
                    Oops
                </h2>
                <p className="margin-top  center-text">
                    {props.msg}
                </p>
            </div>
            <div className="margin-horizontal">
                <SubmitButton
                    onClick={() => {
                        TwilioManager.getInstance().getToken()
                    }}
                    title="Try Again"
                />
            </div>
            <div className="center-content margin-top margin-bottom flex-column">
                <div>
                    <a onClick={() => EventsAdapter.logout()} className={"pointer margin-top _clearLink center"}>
                        Logout
                    </a>
                </div>
            </div>
        </div>
    )
}

export default DeviceFailure
