import styles from "./actionButtons.module.scss";

interface ActionButtonProps {
  buttons: Object;
  extraClass?: string,
  iconStyle?:string,
  textStyle?:string
}

const ActionButtons = (props:ActionButtonProps) => {
    if (!props.buttons || Object.keys(props.buttons).length === 0) {
      return null;
    }
    const extraClassName = props.extraClass ?? ""
    return (
      <div className="flex-space-between">
        {Object.values(props.buttons).map((button, index) => {
          return (
            <div key={index} className={styles["action-button"] + ' ' + styles[extraClassName]} onClick={button.action}>
              <i className={styles["action-icon"] + ' ' + styles[extraClassName]+ ' ' + button.icon } />
              {button.text&&<div className={styles["action-text"]+ ' ' + styles[extraClassName]}>{button.text}</div>}
            </div>
          );
        })}
      </div>
    );
  };


  export default ActionButtons
