import { UserReduxState, UserActions, UserActionType } from "constants/storeConstants";
import {clearNumber} from "../../helpers/StringFormats";

const initialState: UserReduxState = {
  numbers: []
}

function userReducer(state: UserReduxState = initialState, action: UserActionType = { type: UserActions.setNumbers, payload: []}): UserReduxState{
  switch (action.type){
    case UserActions.setNumbers:
      const numbers = (action.payload || []).map((userNumbers: any) =>{
        return {...userNumbers, number: clearNumber(userNumbers.number)}
      });
      return {...state, numbers};
  }
  return state
}

export default userReducer;