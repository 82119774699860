import React from 'react'
import './activeCall.scss';

interface CallActionProps {
    iconName: string,
    title: string,
    onClick: VoidFunction,
}

function CallAction(props: CallActionProps) {
    return (
        <div className="callAction" onClick={props.onClick}>
            <div className="icon-container">
            <i className={`${props.iconName}`} />
            </div>
            <div className="call-action-title">
                {props.title}
            </div>
        </div>
    )
}

export default CallAction;
