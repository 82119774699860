import moment from "moment"
import { CallHistoryItemPrepped, CallQueueItem, CallsHistoryItem, ConferenceParticipant, ParsedCallQueue, PhoneNumber, activeModal } from "./types";


//#region Voice Actions


export enum voiceActions {
    None = "",
    SetCallingFrom = "CALLS_SET_CALLING_FROM",
    SetActiveCall = "CALLS_SET_ACTIVE_CALL",
    FinalizeCall = "CALLS_FINALIZE_CALL",
    Ringing = "CALLS_RINGING",
    StateChanged = "CALLS_STATE_CHANGED",
    UpdateActiveCall = "CALLS_UPDATE_ACTIVE_CALL",
}

export interface VoiceReduxState {
    deviceReady?: boolean,
    deviceLoading?: boolean,
    lastError?: string,
    callingFrom?: string,
    available?: boolean,
    ringing?: boolean,
    onCall?: boolean,
    muted?: boolean,
    onHold?: boolean,
    onConference?: boolean,
    conferenceParticipants?: Array<any>,
    activeCall?: ActiveCall,
    transferring?: boolean,
    initiatedConf?: boolean,
    beforeConnecting?:boolean
}

export interface ActiveCall {
    isIncoming?: boolean,
    user_id?: string | number,
    user_type?: string | number,
    full_name?: string,
    phone?: string,
    email?: string,
    job_id?: string,
    client_id?: string,
    call_time?: moment.Moment,
    call_sid?: string,
    ad_group_id?: string,
    parent_call_sid?: string,
    on_conference?: boolean,
    conferenceParticipants?: { [key: string]: ConferenceParticipant },
    participantsCounter?: number,
    ad_group_name?: string
    flow?: string,
    warningType?: string,
    activeWarning?: Array<any> 
    is_recording?: string | number,
    newCallQ?: boolean,
    activeModal?: activeModal,
    client_contact_id?: string,
    parent_client_name?: string
}

export interface VoiceActionType {
    type: voiceActions,
    activeCall?: ActiveCall,
    callingFrom?: string,
    payload?: VoiceReduxState,
}

//#endregion Voice Actions

//#region Navigation Actions

export enum navigationActions {
    None = "",
    SetTab = "NAVIGATION_SET_TAB"
}

export enum tabTypes {
    KEYPAD = "keypad",
    CONTACTS = "contacts",
    HISTORY = "history"
}

export interface NavigationActionType {
    type: navigationActions,
    payload: tabTypes
}

export interface NavigationReduxState {
    tab: tabTypes
}

//#endregion

//#region Calls History


export enum callsHistoryActions {
    None = "",
    SetList = "CALL_HISTORY_ACTION_SET_LIST",
    UpdateItem = "CALL_HISTORY_ACTION_UPDATE_ITEM",
}

export interface CallsHistoryActionType {
    type: callsHistoryActions,
    payload?: CallHistoryItemPrepped,
    list: Array<CallHistoryItemPrepped>
}

export interface CallsHistoryReduxState {
    calls: Array<CallHistoryItemPrepped>
}

//#endregion

//#region CallQueue

export enum callQueueActions {
    None = "",
    SetList = "CALL_QUEUE_ACTION_SET",
    SetUserGroups = "CALL_QUEUE_ACTION_SET_USER_GROUPS",
    SetOpenQueueName = "CALL_QUEUE_ACTION_SET_OPEN_QUEUE_NAME",
    setQueueOpenStatus = "CALL_QUEUE_ACTION_SET_QUEUE_OPEN_STATUS"
}

export interface CallQueueActionType {
    type: callQueueActions,
    payload: string,
}

export interface CallQueueReduxState {
    jsonMSG: string,
    userGroups: any,
    queueList: Array<CallQueueItem>,
    queueObject: { [key: string]: ParsedCallQueue },
    openQueueName: string,
    allCallQCallsObject : { [key: string]: CallQueueItem },
    isOpenCallQueues: { [key: string]: boolean }
}

export interface UserReduxState {
    numbers: Array<PhoneNumber>
}

export enum UserActions {
    setNumbers = "SET_NUMBERS"
}

export interface UserActionType {
    type: UserActions,
    payload: any,
}