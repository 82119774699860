const urlConstants = {
    user: {
        login: "login",
        socialLogin: "socialLogin",
        logout: "logout",
        refreshToken: "refreshToken",
        ping: "ping",
        getUserData: "ajaxc/SAJ/getDialerSettings/",
        forgotPassword: "login/?forgot_password=desktop_phone",
        isUserAvailable:"ajaxc/user/isUserAvailableOnDesktopPhone/",
        create2FA: "ajaxc/2FA/create2FA/",
        verify2FA: "ajaxc/2FA/authenticate/",
    },
    voiceSettings: {
        getAccessToken: "ajaxc/ct_makeCall/generateToken/",
        getNumbers: "ajaxc/ct_makeCall/getAllNumbers/",
    },
    data: {
        callsHistory: "ajaxc/calls/getCallsHistory/",
        getContacts: "ajaxc/ct_makeCall/findUserPhone/",
        getClients: "ajaxc/ct_makeCall/fetchUsers/",
        getSmartSuggestions: "ajaxc/calls/getContactRecommendation/",
        recommendationTapped: "ajaxc/calls/triedRecommendation/",
        getContactsOnSearch: "ajaxc/ct_makeCall/fetchContacts/",
        getBadgeIconCounter: "ajaxc/calls/badge/",
        logWarningDuringCalls: "ajaxc/calls/logWarningDuringCall"
    },
    queue: {
        getQueueGroups: "ajaxc/calls/getUsersQueuesUserGroups/",
    },
    workizWeb: {
        createJob: "root/newJob",
        createLead: "root/newLead",
        createClients: "root/clients",
        client: "root/client/",
        message: "root/messaging/",
        sendRecording: "ajaxc/messaging/sendRecording/",
        number:"root/numbers/"
    },
    voice: {
        transfer: "ajaxc/calls/transfer/",
        hold: "ajaxc/calls/hold/",
        unHold: "ajaxc/calls/unhold/",
        joinConference: "ajaxc/calls/startConference/",
        addToConference: "ajaxc/calls/addToConference/",
        removeRecipient: "ajaxc/calls/removeParticipant/",
        setSeen: 'ajaxc/calls/setSeen/',
        setConferenceParticipants: 'ajaxc/calls/setConferenceParticipants/',
        getCallerInfo: 'ajaxc/calls/getCallerInformation/'
    }, 
    assets: {
        ringtone: "_assets/audio/ringtone.mp3",
        notificationIcon: "_assets/img/workizNotify.png",
        mainLoader: "_assets/img/dialerLoading.gif",
        noResult: "_assets/svg/noContactNew.svg",
        chill: '_assets/img/Chill.png',
        oops: '_assets/svg/oops.svg',
        incomingCallBackground: "_assets/img/incomingCallBackground.png"
    }
}

export default urlConstants;
