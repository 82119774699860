import urlConstants from 'constants/urlConstants';
import NetworkManager from 'helpers/NetworkManager';
import React, { useEffect, useState } from 'react'
import * as data from 'helpers/Data'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { closeSocket } from 'helpers/Socket'

import './style.scss'
import splashImage from 'assets/images/SplashImage.png'
import logo from 'assets/images/LogoBig.svg'
import { setTimeoutAsync } from 'helpers/WorkizHelper';
import WorkizLang from 'language/WorkizLang';
import Routing from 'constants/routingConstants';
import {initFeatureFlags} from 'helpers/FeatureFlags';

function SplashScreen(props: RouteComponentProps) {

    const [loginStatus, setLoginStatus] = useState<boolean | null>(null)
    const [animationPos, setAnimationPos] = useState(0)

    useEffect(() => {
        // start splash animations
        closeSocket();
        animate();
        return () => {

        }
    }, []);

    const animate = async () => {
        let pos = 0
        await setTimeoutAsync(700)
        while (pos < 3) {
            setAnimationPos(++pos)
            await setTimeoutAsync(700)
        }
        await setTimeoutAsync(500)
        checkLoginStatus()
    }

    const checkLoginStatus = async () => {
        const res = await NetworkManager.auth(urlConstants.user.ping, {}, true).catch((e) => {
            console.log("error", e)
            setLoginStatus(false)
        })
        console.log(res);
        if (res) {
            const flag = await data.getUserData()
            if (flag) {
                setLoginStatus(true)
                initFeatureFlags(false);
            } else {
                props.history.push(Routing.NotAllowed)
            }
        }
    }

    if (loginStatus === true) {
        return <Redirect to={Routing.Main} />
    }
    if (loginStatus === false) {
        return <Redirect to={Routing.Login} />
    }

    return (
        <div className={"splash splash-" + animationPos}>
            <img className="splashImage" src={splashImage} />
            <div className="splash-bottom">
                <img className="splashLogo" src={logo} />
                <span className="splashText">
                    {WorkizLang.get().login.splashTitle}
                </span>
            </div>
        </div>
    )
}

export default SplashScreen
