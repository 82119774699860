enum Addons {
    Booking = "booking",
    CalSync = "calSync",
    CallTracking = "calltracking",
    Chatbot = "chatbot",
    Commissions = "commissions",
    CompanyCam = "companyCam",
    CustomFields = "customFields",
    CustomReports = "customReports",
    CustomDocs = "customdoc",
    Developer = "developer",
    ExternalCompanies = "external",
    Franchises = "franchises",
    GoogleLocal = "googleLocal",
    Gusto = "gusto",
    Inventory = "inventory",
    Leads = "leads",
    MailChimp = "mailchimp",
    Map = "map",
    Metros = "metros",
    Payments = "payments",
    QuickBooks = "quickbooks",
    Subcontractors = "subcontractors",
    Tasks = "tasks",
    Thumbtack = "thumbtack",
    WorkizPhone = "workizPhone",
    Zapier = "zapier",
    Zoom = "zoom",
}

export default Addons;