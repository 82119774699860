import React from 'react'

import "./qIndication.scss"
import { WorkizEvents, trackEvent } from 'helpers/TrackActions'

function CallQIndicator(props: { queueCounter: number, onClick?: VoidFunction }) {

    const openCallQueueList = () => {
        if (props.queueCounter >= 0 && typeof props.onClick === "function") {
            props.onClick()
        }
        trackEvent(WorkizEvents.ClickedCallQueueDialer)
    }

    return (
        <div className="queue-indicator" onClick={openCallQueueList}>
            {props.queueCounter > 0 && <span className={"queue-counter"}>{props.queueCounter}</span>}
            <i className="wfi-Call-queue" />
        </div>
    )
}

export default CallQIndicator
