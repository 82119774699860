import React from 'react'
import "./tabs.scss"

interface TabContainerProps {
    active: boolean
}

function TabContainer(props: React.PropsWithChildren<TabContainerProps>) {
    const style = process.env.REACT_APP_WEB ? { left: props.active ? 0 : "100%", opacity: props.active ? 1 : 0, display: props.active ? 'flex' : 'none' }
    : { left: props.active ? 0 : "100%", opacity: props.active ? 1 : 0 }
    return (
        <div className={"tab-container"} style={style}>
            {props.children}
        </div>
    )
}

export default TabContainer
