import React, { useState } from 'react'
import SubmitButton from 'components/form/SubmitButton'
import WorkizLang from 'language/WorkizLang'
import NetworkManager from 'helpers/NetworkManager'
import urlConstants from 'constants/urlConstants'
import TwilioManager from 'helpers/TwilioManager'
import User from 'helpers/User'
import ListRow from 'components/layout/ListRow'
import { setTimeoutAsync } from 'helpers/WorkizHelper'
import EventsAdapter from 'helpers/EventsAdapter'
import * as data from 'helpers/Data'
import { trackEvent, WorkizEvents } from 'helpers/TrackActions'

type test_result_type = "" | "failed" | "success"

function UserSettings() {
    const [loadingLogout, setLoadingLogout] = useState(false)
    const [loadingAvailability, setLoadingAvailability] = useState(false)
    const [outputTestPassed, setOutputTestPassed] = useState<test_result_type>("")
    const [inputTestPassed, setInputTestPassed] = useState<test_result_type>("")

    const testInputDevice = async () => {
        console.log('rest input');
        
        const res = await TwilioManager.getInstance().testInputDevice()
        if (res) {
            setInputTestPassed("success")
        } else {
            setInputTestPassed("failed")
        }
        await setTimeoutAsync(3000)
        setInputTestPassed("")
    }

    const testOutputDevice = async () => {
        const res = await TwilioManager.getInstance().testOutputDevice()
        if (res) {
            setOutputTestPassed("success")
        } else {
            setOutputTestPassed("failed")
        }
        await setTimeoutAsync(3000)
        setOutputTestPassed("")
    }

    const logout = async () => {
        setLoadingLogout(true)
        trackEvent(WorkizEvents.LoggedOut)
        // clear session in backend
        await NetworkManager.auth(urlConstants.user.logout, {}, true).catch(() => { })
      
        // clear cached tokens
        NetworkManager.setAuthToken("")
        NetworkManager.setAuthRefreshToken("")
        // clear user based instances
        TwilioManager.clearInstance()
        TwilioManager.getInstance().setDialerSettings(true,"")
        User.clearInstance()
        setLoadingLogout(false)
        // go back to splash screen
        EventsAdapter.logout()
    }

    const changeAvailability = async () => {
        setLoadingAvailability(true)
        await setTimeoutAsync(500)
        TwilioManager.getInstance().changeAvailability()
        trackEvent(WorkizEvents.ClickedSetAvailabilityDialer)
    }

    const getTestLabel = (status: test_result_type) => {
        switch (status) {
            case "failed":
                return <small className={"red"}>{"Last device test has failed"}</small>

            case "success":
                return <small className={""}>{"Last device test was successful"}</small>

            default:
                return undefined;
        }
    }

    const isAvailable = TwilioManager.getInstance().available

    return (
        <div>
            <>
            <ListRow
                title={isAvailable ? WorkizLang.get().settings.markUnavailable : WorkizLang.get().settings.markAvailable}
                onClick={changeAvailability}
                isLoading={loadingAvailability}
            />
            </>
            {
                !process.env.REACT_APP_WEB &&
                <>
                    <ListRow
                        title={"Test input device"}
                        iconName={"wfi-speaker blue"}
                        leftBottom={getTestLabel(inputTestPassed)}
                        onClick={testInputDevice}
                        isLeftIconInsideRow
                        />
                    <ListRow
                        title={"Test output device"}
                        iconName={"wfi-speaker blue"}
                        leftBottom={getTestLabel(outputTestPassed)}
                        onClick={testOutputDevice}
                        isLeftIconInsideRow
                    />
                    <ListRow
                        title={WorkizLang.get().login.logout}
                        iconName="wfi-logout red"
                        onClick={logout}
                        isLoading={loadingLogout}
                        isLeftIconInsideRow
                    />
                    <ListRow
                        title={`Version ${window.require('electron').remote.app.getVersion()}`}
                    />
                </>
            }
        </div>
    )
}

export default UserSettings
