import { voiceActions, VoiceActionType, VoiceReduxState, ActiveCall } from 'constants/storeConstants';

export function setActiveCall(activeCall: ActiveCall): VoiceActionType {
    return {
        type: voiceActions.SetActiveCall,
        activeCall
    }
}

export function setVoiceState(payload: VoiceReduxState): VoiceActionType {
    return {
        type: voiceActions.StateChanged,
        payload
    }
}

export function updateActiveCall(activeCall: ActiveCall): VoiceActionType {
    return {
        type: voiceActions.UpdateActiveCall,
        activeCall
    }
}
