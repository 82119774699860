const AppRouting = {
    Home: "/",
    Splash: "/splash",
    Login: "/login",
    _2FA: "/2fa",
    NotAllowed: "/not-allowed",
    NoNumbers: "/no-number",
    Main: "/main",
}

const  WebRouting = {
    Home: "/dialer/",
    Splash: "/dialer/splash",
    Login: "/dialer/login",
    _2FA: "/2fa",
    NotAllowed: "/dialer/not-allowed",
    NoNumbers: "/dialer/no-number",
    Main: "/dialer/main",
}

const Routing = process.env.REACT_APP_WEB ?WebRouting : AppRouting;
export default Routing;