
import moment from 'moment';
import { voiceActions, VoiceActionType, VoiceReduxState } from 'constants/storeConstants';

/**
 * This constant is used for testing purposes only
 * Must make sure this is not in use in any commit!
 */
const stubOnCallState: VoiceReduxState = {
    callingFrom: "", onConference: false, available: false, ringing: true, onCall: false, muted: false, onHold: false, transferring: false,
    activeCall: {
        call_time: moment(),
        client_id: "1000",
        email: "dana@workiz.com",
        full_name: "Dana Macarona",
        isIncoming: false,
        phone: "0506600022",
        on_conference: false,
        user_type: "3",
        conferenceParticipants: {
            "ROSG": {
                call_sid: "ROSG",
                call_status: "participant-join",
                fullName: "Daniela",
                isActive: true,
                isMe: false,
                participant_name: "Daniela",
                participant_number: "0508009090",
                participant_sid: "ROSG",
                participant_type: "2",
                phone: "0508009090",
                position: 0,
                is_holding: true,
            },
            "BBO": {
                call_sid: "BBO",
                call_status: "participant-join",
                fullName: "Gabi",
                isActive: true,
                isMe: false,
                participant_name: "Gabi",
                participant_number: "0508009090",
                participant_sid: "BBO",
                participant_type: "2",
                phone: "0508009090",
                position: 1,
            }
        },
        participantsCounter: 1,
        parent_call_sid: "CAR",
        flow: "My Lovely Flow", 
        ad_group_name: "Google",
    }
}


const initialState: VoiceReduxState = // stubOnCallState
 // const x =
{
    callingFrom: "", activeCall: {}, deviceLoading: true,
}

function voiceReducer(state: VoiceReduxState = initialState, action: VoiceActionType = { type: voiceActions.None }): VoiceReduxState {
    switch (action.type) {

        case voiceActions.FinalizeCall:
            return initialState;

        case voiceActions.Ringing:
            return { ...state, ringing: true , beforeConnecting: false};

        case voiceActions.SetActiveCall:
            if (action.activeCall) {
                return { ...state, activeCall: action.activeCall, beforeConnecting: false }
            }
            break;
        case voiceActions.SetCallingFrom:
            if (action.callingFrom) {
                return { ...state, callingFrom: action.callingFrom }
            }
            break;

        case voiceActions.StateChanged:
            if (action.payload) {
                return { ...state, ...action.payload }
            }
            break;

        case voiceActions.UpdateActiveCall:
            if (action.activeCall) {
                return {
                    beforeConnecting: false,
                    ...state, activeCall: {
                        ...state.activeCall,
                        ...action.activeCall
                    }
                }
            }
            break;
        default:
            break;
    }
    return state;
}

export default voiceReducer;
