
import { CallsHistoryReduxState, CallsHistoryActionType, callsHistoryActions } from 'constants/storeConstants';

const initialState: CallsHistoryReduxState = {
    calls: []
}

function callsHistoryReducer(state: CallsHistoryReduxState = initialState, action: CallsHistoryActionType = { type: callsHistoryActions.None, list: [] }): CallsHistoryReduxState {
    switch (action.type) {

        case callsHistoryActions.SetList:
            return { calls: action.list };

        case callsHistoryActions.UpdateItem:
            if (action.payload) {
                const calls = [...state.calls];
                const idx = state.calls.findIndex(_ => _.call_sid === action.payload!.call_sid);
                if (idx == -1) {
                    calls.unshift(action.payload)
                } else {
                    calls[idx] = { ...calls[idx], ...action.payload }
                }
                return { calls };
            }
            break;
        default:
            break;
    }
    return state;
}

export default callsHistoryReducer;
