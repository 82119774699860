import User from "./User";
import React from 'react';
import * as dialerConstants from 'constants/dialerConstants';

const newlineRegex = /(\r\n|\r|\n)/g;


export const phoneFormat = (phone?: string, showInit = false, useMasking = false): string => {
    if (!phone) return "";
    if (useMasking && User.get().shouldUseMasking()) {
        return dialerConstants.maskingNumber;
    }
    const cleaned = ('' + phone).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        const intlCode = showInit ? (match[1] ? '+1 ' : '') : ""
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return phone;
}

export const maskPhoneNumber = (phone: string): string => {
    if (isNumber(phone)) {
        const num = clearNumber(phone);
        if (!num) return phone;

        if (num.length <= 10) {
            const x = num.replace(/[^0-9\+]/g, '').match(/(1|)(\d{0,3})(\d{0,3})(\d{0,4})/);
            if (x) {
                return (!x[3] ? x[2] : '(' + x[2] + ') ' + x[3] + (x[4] ? '-' + x[4] : ''));
            }
        }
    }
    return phone;
};




export const isNumber = (str: string): boolean => {
    const firstChar = str.charAt(0)
    return !isNaN(Number(firstChar)) || firstChar === "(" || firstChar === "+"
}

export const clearNumber = (str: string): string => {
    return str.replace(/\D/g, '')
}

export const nl2br = (str: string) => {
    const lines = str.split(newlineRegex);
    const jsx = lines.map((line, idx) => {
        if (line.match(newlineRegex)) {
            return React.createElement("br", { key: idx })
        }
        return line;
    })
    return jsx;
}


export const formatDate = (inputDate: string): string  => {
    const currentDate = new Date();
    const date = new Date(inputDate);
    
    // Check if it's today
    if (date.toDateString() === currentDate.toDateString()) {
      const options = { hour: 'numeric', minute: '2-digit', hour12: true } as Intl.DateTimeFormatOptions;
      return date.toLocaleTimeString('en-US', options).toLowerCase();
    }
    
    // Check if it's yesterday
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);
    if (date.toDateString() === yesterday.toDateString()) {
      const options = { hour: 'numeric', minute: '2-digit', hour12: true } as Intl.DateTimeFormatOptions;
      return `Yesterday, ${date.toLocaleTimeString('en-US', options).toLowerCase()}`;
    }
    
    // For other days
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' });
    const formattedDate = `${month} ${day}th`;
    return formattedDate;
  }