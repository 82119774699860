import React from "react";
import { phoneFormat } from "helpers/StringFormats"
import { getContactTypeDesc, isWorkizNumber } from "helpers/WorkizHelper"
import NetworkManager from 'helpers/NetworkManager'
import './contactSuggestions.scss'
import Loader from "components/layout/Loader";
import Modal from "components/layout/Modal";
import SearchBar from "components/form/SearchBar";
import ListRow from "components/layout/ListRow";
import NoResults from "components/layout/NoResults";
import TwilioManager from "helpers/TwilioManager";
import urlConstants from "constants/urlConstants";
import WorkizLang from "language/WorkizLang";

/** todo: language out */


interface Suggestion {
    [x: string]: any
}

interface ContactSuggestionsProps {
    isOpen: boolean,
    title?: string,
    allowWorkizNumber?: boolean,
    onSelect?: (selectedContact?: any) => void,
    onClose: VoidFunction,
}

interface ContactSuggestionsState {
    isLoading: boolean,
    searchStr: string,
    suggestionsList: Array<Suggestion>,
    showSuggestions: boolean,
    smartSuggestions: Array<any>
}


class ContactSuggestions extends React.Component<ContactSuggestionsProps, ContactSuggestionsState> {

    state = {
        suggestionsList: [],
        smartSuggestions: [],
        searchStr: "",
        isLoading: true,
        showSuggestions: true,
    }

    componentDidMount() {
        this.loadUserOptions("");
    }

    UNSAFE_componentWillReceiveProps(nextProps: ContactSuggestionsProps) {
        if (nextProps.isOpen && !this.props.isOpen) {
            if (this.state.isLoading) {
                this.loadUserOptions("", true); // avoid extra loading
            }
            if (!this.state.showSuggestions) {
                this.setState({ showSuggestions: true });
            }
        }
    }

    shouldComponentUpdate(nextProps: ContactSuggestionsProps, nextState: ContactSuggestionsState) {
        return (
            nextProps.isOpen != this.props.isOpen ||
            nextProps.title != this.props.title ||
            nextState.searchStr != this.state.searchStr ||
            nextState.isLoading != this.state.isLoading ||
            nextState.showSuggestions != this.state.showSuggestions
        )
    }

    loadUserOptions = async (searchStr: string, force = false) => {
        if (!this.props.isOpen && !force) return;
        this.setState({ isLoading: true });

        const smartSuggestions = await TwilioManager.getInstance().getContactSuggestions()
        this.setState({ smartSuggestions })

        NetworkManager.post("ajaxc/ct_makeCall/findUserPhone/",
            { search_str: searchStr, force_results: 1 })
            .then(json => {
                // if results came up but person kept on typing.. ;)
                if (searchStr != this.state.searchStr) return;

                if (json.suggestions && Array.isArray(json.suggestions)) {
                    const filtered = json.suggestions.filter(_item => _item.phone != 0);
                    const searchedNumber = phoneFormat(searchStr);
                    if (!filtered.length && searchedNumber) {
                        filtered.push({
                            name: searchedNumber,
                            phone: searchedNumber,
                            type: -1,
                        })
                    }
                    const withSoftPhones: Array<any> = [];
                    filtered.forEach(item => {
                        if (item.type == 0) {
                            withSoftPhones.push({ ...item, is_dialer: true });
                        }
                        if (this.props.allowWorkizNumber || !isWorkizNumber(item.phone)) {
                            withSoftPhones.push(item);
                        } else if (item.name == searchedNumber && item.type == -1) {
                            // fsSendEvent(fsEvents.TRIED_ADDING_WORKIZ_NUMBER_TO_CALL, { searchStr })
                        }
                    });
                    this.setState({ suggestionsList: withSoftPhones, isLoading: false });
                } else {
                    this.setState({ isLoading: false });
                }
            })
            .catch(() => {
                this.setState({ isLoading: false })
            });
    }

    searchChanged = (term: string) => {
        this.loadUserOptions(term)
    }

    searchTextChange = (term: string) => {
        this.setState({ isLoading: true, searchStr: term })
    }

    renderUser = (user: any, index: number, extraCallback: (user: any) => void) => {
        const userPhoneFormatted = phoneFormat(user.phone);
        if (!userPhoneFormatted) return;
        const userPhoneMasked = phoneFormat(user.phone, false, true);
        const callback = () => {
            if (this.props.onSelect) {
                this.props.onSelect(user);
            }
            if (typeof extraCallback === "function") {
                extraCallback(user);
            }
        }

        return (
            <ListRow
                key={"user_option_" + index}
                title={user.name}
                leftBottom={user.name !== userPhoneFormatted ? <span className={"small"}>{userPhoneMasked}</span> : undefined}
                rightTop={<span className="small bright-text">{user.is_dialer ? "Soft Phone" : getContactTypeDesc(user.type)}</span>}
                rightBottom={user.added_comment ? <span className="small">{user.added_comment}</span> : ""}
                onClick={callback}
            />
        )
    }

    setRecommendationSelected = (user_id: string, client_id: string) => {
        const params = { user_id, client_id };
        NetworkManager.post(urlConstants.data.recommendationTapped, params)
            .catch(() => { });
    }

    renderSmartSuggestions = () => {
        const jsx = this.state.smartSuggestions.map(({ contact_id, contact_type, contact_name, connection_reason, phone_number, user_id, client_id }, index) => {
            return this.renderUser({
                phone: phone_number, name: contact_name, type: +contact_type, added_comment: connection_reason, data: contact_id,
            }, index, () => this.setRecommendationSelected(user_id, client_id));
        });

        return (
            <div className={`suggestionsContainer`}>
                <h5 className="flex-row padding-horizontal margin-bottom ">
                    <div className="flex-one center-text bold">
                        Suggested
                    </div>
                    <div className={"pointer"} onClick={() => this.setState({ showSuggestions: false })}>
                        <i className="wi-cross" />
                    </div>
                </h5>
                {jsx}
            </div>
        );
    }

    shouldShowSuggestions = (): boolean => {
        return this.state.showSuggestions && this.state.smartSuggestions.length > 0;
    }





    getTitleComponent = () => {
        return (
            <div className={`flex-row modal-header`}>
                <div onClick={this.props.onClose}>
                    <i className={"wi-chevron-left font-16"} />
                    <div className={"flex-one modal-title "}>{this.props.title}</div>
                </div>
               
                {this.shouldShowSuggestions() ? <div className={"pointer padding-horizontal"} onClick={() => this.setState({ showSuggestions: false })}>
                    <i className={"wi-search"} />
                </div> : null}
            </div>
        )
    }


    getSearchBar = () => {
        return (
            <SearchBar
                autoFocus={this.props.isOpen ? true : false}
                onSearchChanged={this.searchChanged}
                onTextChange={this.searchTextChange}
                searchTimeout={800}
                placeholder={WorkizLang.get().data.searchContacts}
               

                className={"margin-horizontal padding-vertical padding-screen relative"}
                inputExtraClass={"padding-input border-input border-radius"}
                searchIcon="wfi-search"
                iconExtraClass="search-icon-input"
            />
        );
    }

    renderHeadContent = () => this.shouldShowSuggestions() ? this.renderSmartSuggestions() : this.getSearchBar();

    renderList = () => {
        const hasNoData = !this.state.isLoading && !this.state.suggestionsList.length && !this.shouldShowSuggestions();

        if (hasNoData) {
            return <NoResults title={WorkizLang.get().data.noContacts} />
        }

        return this.state.suggestionsList.map((_, idx) => this.renderUser(_, idx, () => { }));
    }


    render() {
        return (
            <Modal
                onClose={this.props.onClose}
                titleComponent={this.getTitleComponent()}
                headComponent={this.renderHeadContent()}
                show={this.props.isOpen} fullscreen
            >
                {this.state.isLoading ? <Loader /> : this.renderList()}
            </Modal>
        );
    }

}

export default ContactSuggestions;
