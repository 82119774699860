import config from './envConfig'
import EventsAdapter from './EventsAdapter'

const TOK_KEY = "jwt-main-workiz";
const REF_TOK_KEY = "jwt-refresh-workiz";
const sessionExpiredMsg = "Session Expired"

export interface NetworkResponseType {
    flag?: boolean,
    msg?: string,
    data?: Array<any> | Record<string, unknown> | string | any,
    [x: string]: any,
}

class NetworkManager {

    /** @type {AxiosStatic} */
    static axios = require('axios');
    static authToken = "";
    static _2FASession = "";
    static buildAuthURL = (url: string) => {
        return config.AUTH_URL + url;
    }

    static buildBaseUrl = (url: string) => {
        const urlPrefix = process.env.REACT_APP_WEB ? `https://${window.location.host}/` : config.BASE_URL;
        return urlPrefix + url;
    }

    static setAuthToken(token: string) {
        NetworkManager.authToken = token;
        EventsAdapter.setAuthToken(TOK_KEY, token);
    }

    static set2FASession(session: string) {
        NetworkManager._2FASession = session;
        localStorage.setItem('_2FASession', session); 
    }

    static get2FASession() {
        if (!NetworkManager._2FASession) {
            NetworkManager._2FASession = localStorage.getItem('_2FASession') || ''; 
        }
        return NetworkManager._2FASession;
    }

    static setAuthRefreshToken(token: string) {
        EventsAdapter.setAuthToken(REF_TOK_KEY, token);
    }

    static getAuthRefreshToken() {
        return EventsAdapter.getAuthToken(REF_TOK_KEY)
    }

    static async getAuthTokenHeaders() {
        if (NetworkManager.authToken) {
            return {
                "Authorization": `Bearer ${NetworkManager.authToken}`
            }
        }
        const token = EventsAdapter.getAuthToken(TOK_KEY)
        if (token) {
            return {
                "Authorization": `Bearer ${token}`
            }
        }
        return false;
    }

    static async auth(url: string, dataObject: Record<string, unknown>, useAuthToken = false) {
        return NetworkManager.apiCall(NetworkManager.buildAuthURL(url), dataObject, useAuthToken)
    }

    static async post(url: string, dataObject: Record<string, unknown>, useAuthToken = true) {
        return NetworkManager.apiCall(NetworkManager.buildBaseUrl(url), dataObject, useAuthToken)
    }

    static async refreshToken() {
        return new Promise((resolve, reject) => {
            const refToken = NetworkManager.getAuthRefreshToken();
            if (!refToken) {
                resolve(false)
                return
            }
            /** @type {AxiosRequestConfig} */
            const settings = {
                method: "POST", data: { token: refToken }, url: NetworkManager.buildAuthURL("refreshToken"),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            };

            const onFail = () => {
                resolve(false)
                EventsAdapter.logout()
            }

            NetworkManager.axios(settings)
                .then((response: any) => {
                    if (response.data && response.data.accessToken) {
                        NetworkManager.setAuthToken(response.data.accessToken)
                        resolve(true)
                    } else {
                        onFail();
                    }
                })
                .catch(onFail)
        })
    }

    static apiCall(url: string, dataObject: Record<string, unknown>, useAuthToken: boolean, useRefreshToken = true): Promise<NetworkResponseType> {
        return process.env.REACT_APP_WEB ? NetworkManager.apiCallWeb(url, dataObject) : NetworkManager.apiCallApp(url,dataObject, useAuthToken, useRefreshToken);
    }

    private static apiCallApp(url: string, dataObject: Record<string, unknown>, useAuthToken: boolean, useRefreshToken = true): Promise<NetworkResponseType> {
        return new Promise(async (resolve, reject) => {

            let extraHeaders = {};
            if (useAuthToken) {
                extraHeaders = await NetworkManager.getAuthTokenHeaders();
                // if we didn't get auth tokens - throw user to 
                if (!extraHeaders) {
                    reject("missing auth")
                    return;
                }
            }

            /** @type {AxiosRequestConfig} */
            const settings = {
                method: "POST", data: dataObject, url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'workiz-dialer-csrf': "TRUE",
                    'dialer2FASession': NetworkManager.get2FASession(),
                    ...extraHeaders
                },
            };

            NetworkManager.axios(settings)
                .then(async (response: any) => {
                    if (response.data == sessionExpiredMsg && useAuthToken && useRefreshToken) {
                        const flag = await NetworkManager.refreshToken()
                        if (flag) {
                            try {
                                const res = await NetworkManager.apiCall(url, dataObject, useAuthToken, false);
                                if (res) {
                                    if (typeof(res) == "string" && res === sessionExpiredMsg) {
                                        EventsAdapter.logout();
                                        reject(sessionExpiredMsg);
                                    }
                                    resolve(res);
                                }
                            } catch (error) {
                                reject(error);
                            }
                        }
                        EventsAdapter.logout();
                        reject(sessionExpiredMsg);
                    } else {
                        resolve(response.data)
                    }
                })
                .catch((error: any) => reject("Bad response from server" + error));
        });
    }

    private static apiCallWeb(url: string, dataObject: Record<string, unknown>): Promise<NetworkResponseType> {
        return new Promise(async (resolve, reject) => {

            /** @type {AxiosRequestConfig} */
            const settings = {
                method: "POST", data: dataObject, url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'workiz-dialer-csrf': "TRUE"
                },
            };

            NetworkManager.axios(settings)
                .then(async (response: any) => {
                    if (response.data == sessionExpiredMsg) {
                        reject("Session Expired");
                    } else {
                        resolve(response.data)
                    }
                })
                .catch((error: any) => reject("Bad response from server" + error));
        });
    }

    public static serializeObjectToQuery(params: { [x: string]: any }): string {
        const str = [];
        for (const key in params) {
            if (params[key]) {
                str.push(encodeURIComponent(key) + "=" + encodeURIComponent(params[key]));
            }
        }
        return str.join("&");
    }

}

export default NetworkManager;
