import { combineReducers } from 'redux';
import { VoiceReduxState, NavigationReduxState, CallsHistoryReduxState, CallQueueReduxState, UserReduxState } from 'constants/storeConstants';
import voiceReducer from './voiceReducer';
import navigationReducer from './navigationReducer';
import callsHistoryReducer from './callsHistoryReducer';
import callQueueReducer from './callsQueueReducer';
import userReducer from './userReducer';
import { DefaultRootState } from 'react-redux';

export interface DialerRootReducer extends DefaultRootState {
    voice: VoiceReduxState,
    navigation: NavigationReduxState,
    callsHistory: CallsHistoryReduxState,
    callQueue: CallQueueReduxState,
    user: UserReduxState
}

const rootReducer = combineReducers({
    voice: voiceReducer,
    navigation: navigationReducer,
    callsHistory: callsHistoryReducer,
    callQueue: callQueueReducer,
    user: userReducer
});

export default rootReducer;