import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { HashRouter, Route, Switch, BrowserRouter } from "react-router-dom";
import SplashScreen from 'containers/Splash/SplashScreen';
import LoginScreen from 'containers/Login/LoginScreen';
import DialerMain from 'containers/Dialer/DialerMain';
import NoPhoneNumber from 'containers/Dialer/ErrorPages/NoPhoneNumber';
import WebLogin from 'containers/WebLogin/WebLogin';
import { IpcRendererEvent } from 'electron/main';
import EventsAdapter from 'helpers/EventsAdapter'

import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import User from 'helpers/User';
import { initTracking } from 'helpers/TrackActions';
import Routing from 'constants/routingConstants'
import NotAllowed from './Dialer/ErrorPages/NotAllowed';
import _2faScreen from './Login/_2faScreen';
import * as dataHelper from 'helpers/Data';
import { v4 as uuid } from 'uuid';
import TwilioManager from 'helpers/TwilioManager';

Sentry.init({
    dsn: "https://11cd0876b18847a19e0ad6d2e2205d8b@o120871.ingest.sentry.io/5936836",
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    beforeSend: (event) => {
        try {
            event.extra = event.extra || {};
            event.tags = event.tags || {};
            event.environment = (process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV) === "development" ? "DEV" : "PROD";
            const _u = User.get().userData
            if (_u) {
                event.user = {
                    email: _u.user?.email,
                    id: _u.user?.id,
                    username: _u.user?.name
                }
                event.extra.account_id = _u.acid
                event.extra.app_version = EventsAdapter.getVersion()
            }
        }
        catch (err) { 
            console.log("error")
        }
        return event;
    },
})


const Login = process.env.REACT_APP_WEB ? WebLogin : SplashScreen;

const Routes = (routeProps: {handledDeepLink: any, deepLinkCall: any}) => {
    return (
        <>
            <Route path={Routing.Home} exact component={Login} />
            <Route path={Routing.Splash} exact component={Login} />
            <Route path={Routing._2FA} render={props => <_2faScreen {...props} />} />
            <Route path={Routing.Login} render={props => <LoginScreen {...props} />} />
            <Route path={Routing.NoNumbers} render={props => <NoPhoneNumber {...props} />} />
            <Route path={Routing.NotAllowed} render={props => <NotAllowed {...props} />} />
            <Route path={Routing.Main} render={props => <DialerMain {...props} handledDeepLink={routeProps.handledDeepLink} deepLinkCall={routeProps.deepLinkCall} />} />
        </>
    )
}

export const App = () => {
    const [deepLinkCall, setDeepLinkCall] = useState<any>(null)

    useEffect(() => {
        initTracking()

        if(!process.env.REACT_APP_WEB) {
            const deepLinkingEvent = (e: IpcRendererEvent, data: any) => {
                console.log("from deep link in app", data)
                setDeepLinkCall(data)
            }

            // to deep link - open workiz://params..
            EventsAdapter.addEventListener("deep-linking-opened", deepLinkingEvent)
    
            return () => {
                EventsAdapter.removeEventListener("deep-linking-opened", deepLinkingEvent)
            }
        } else {
            console.log('process.env.REACT_APP_WEB', process.env.REACT_APP_WEB)
            EventsAdapter.browserSetup(TwilioManager.getInstance());
        }
    }, [])


    return (
        process.env.REACT_APP_WEB ? 
        <div className="center-content">
        <div className='webMeasurement'>
            <BrowserRouter>
                <Switch>
                    <Routes deepLinkCall={deepLinkCall} handledDeepLink={setDeepLinkCall}/>
                </Switch>
            </BrowserRouter>
        </div>
        </div>
        :
        <HashRouter>
            <Routes deepLinkCall={deepLinkCall} handledDeepLink={setDeepLinkCall}/>
        </HashRouter>
    )
}


export default App;
