
import { CallsHistoryActionType, callsHistoryActions } from 'constants/storeConstants';
import { CallHistoryItemPrepped, CallsHistoryItem } from 'constants/types';

export const setCallsHistory = (list: Array<CallHistoryItemPrepped>): CallsHistoryActionType => {
    return {
        type: callsHistoryActions.SetList,
        list
    }
}

export const pushToCallsHistory = () => {

}

export const updateCallsHistoryItem = (call: CallHistoryItemPrepped): CallsHistoryActionType => {
    return {
        type: callsHistoryActions.UpdateItem,
        payload: call,
        list: []
    }
}