
import { navigationActions, NavigationActionType, NavigationReduxState, tabTypes } from 'constants/storeConstants';

const initialState: NavigationReduxState = {
    tab: tabTypes.KEYPAD
}

function navigationReducer(state: NavigationReduxState = initialState, action: NavigationActionType = { type: navigationActions.None, payload: tabTypes.KEYPAD }): NavigationReduxState {
    switch (action.type) {

        case navigationActions.SetTab:
            return { tab: action.payload }
        default:
            break;
    }
    return state;
}

export default navigationReducer;
