import urlConstants from 'constants/urlConstants'
import NetworkManager from 'helpers/NetworkManager'
import React from 'react'
import './noResults.scss'

interface NoResultsProps {
    title: string,
    explain?: string,
    explainUrl?: string,
    svg?: string,
}

const getImageLink = (svg: string | undefined) => {
    if(process.env.REACT_APP_WEB) {
        return NetworkManager.buildBaseUrl(svg || urlConstants.assets.noResult)
    }

    return svg ?? NetworkManager.buildBaseUrl(urlConstants.assets.noResult);
}

function NoResults(props: NoResultsProps) {
    return (
        <div className={"verticalAlignContainer"}>
            <div className={"verHorCenter"}>
                <div>
                    <img src={getImageLink(props.svg)} />
                </div>
                <div>
                    {props.title && <h3 className="thin margin-top align-center">{props.title}</h3>}
                    {props.explain &&
                        <h4 className={"thin _nmt align-center " + (props.explainUrl ? "_underline" : "")}>
                            {props.explainUrl ?
                                <a href={props.explainUrl} target="_blank" className={"link"}>{props.explain}</a> :
                                <span>{props.explain}</span>
                            }
                        </h4>}
                </div>
            </div>
        </div>
    )
}

export default NoResults
