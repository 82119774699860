import React, { useState } from 'react'
import CallAction from './CallAction'
import TwilioManager from 'helpers/TwilioManager'
import CallButton from 'components/dialer/CallButton'
import WorkizLang from 'language/WorkizLang'
import { useSelector, useDispatch } from 'react-redux'
import { DialerRootReducer } from 'store/reducers/rootReducer'
import * as voiceActions from 'store/actions/voiceActions'
import Modal from 'components/layout/Modal'
import Keypad from 'components/dialer/Keypad'
import AddNew from '../ModalsContents/AddNew'
import { goToURL, isUnknownContact } from 'helpers/WorkizHelper'
import urlConstants from 'constants/urlConstants'
import ContactSuggestions from '../ModalsContents/ContactSuggestions'
import HoldCall from '../ModalsContents/HoldCall'
import Timer from 'components/dialer/Timer'
import moment from 'moment'
import { phoneFormat } from 'helpers/StringFormats'
import User from 'helpers/User'
import Restrictions from 'constants/restrictions'
import { parseCallFromActiveCall } from 'helpers/Parser'
import Loader from 'components/layout/Loader'
import  { ActiveCall } from 'constants/storeConstants';
import ActionButtons from 'components/layout/ActionButtons'
import { CallHistoryItemPrepped } from 'constants/types'


type suggestionTypes = "" | "transfer" | "conference"

const suggestionTitles: { [key: string]: string } = {
    transfer: WorkizLang.get().dialer.transfer,
    conference: WorkizLang.get().dialer.addToCall
}


function OnCallContent() {
    
    const lang = WorkizLang.get().dialer;
    const voiceState = useSelector((state: DialerRootReducer) => state.voice)
    
    const [suggestionsOpen, setSuggestionsOpen] = useState<suggestionTypes>("")
    const [keypadOpen, setKeypadOpen] = useState(false)

    const dispatch = useDispatch();

    const callActionsConfig = [
        { title: voiceState.muted ? lang.unmute : lang.mute, iconName: voiceState.muted ? "wfi-Microphone-Off" : "wfi-Microphone", onClick: () => TwilioManager.getInstance().mute() },
        { title: lang.keypad, iconName: "wfi-Dial-Pad", onClick: () => setKeypadOpen(true) },
        { title: lang.transfer, iconName: "wfi-Phone-Actions-Next", onClick: () => setSuggestionsOpen("transfer") },
        { title: lang.addToCall, iconName: "wfi-add-contact", onClick: () => setSuggestionsOpen("conference") },
        { title: voiceState.onHold ? lang.unHold : lang.hold, iconName: "wfi-hold", onClick: () => voiceState.onHold ? TwilioManager.getInstance().holdCall(true) : dispatch(voiceActions.updateActiveCall({ ...voiceState.activeCall, activeModal: 'hold' })) },
      ];

    const getActionButtons = (call: ActiveCall | undefined) => {
        const buttons: Record<string, any> = {};
        if (User.get().isAllowed(Restrictions.AddJobs)) {
          buttons.addNew = {
            icon: "wfi-big",
            text: "Add new",
            action: () => {
              const currentCall: ActiveCall = { ...voiceState.activeCall, activeModal: "add-action" };
              dispatch(voiceActions.updateActiveCall(currentCall));
            }
          };
        }

        if (User.get().isAllowed(Restrictions.Messaging) && voiceState.activeCall?.user_type == "3") {
            buttons.message = {
              icon: "wfi-message",
              text: "Message",
              action: () => {
                const messagingURL = `${urlConstants.workizWeb.message}${call?.client_id ?? ""}-${call?.client_id ? "3" : ""}`;
                goToURL(messagingURL, true);
              },
            };
          }
      
        return buttons;
      };
      

    const getModalContent = () => {
        switch (voiceState.activeCall?.activeModal) {
            case "add-action":
                const callSid = (voiceState.activeCall?.parent_call_sid || voiceState.activeCall?.call_sid) ?? "";
                const call = TwilioManager.getInstance().getCallBySid(callSid);
                if (call) {
                    return <AddNew call={call} />
                }

                const partialCall = parseCallFromActiveCall(voiceState.activeCall ?? {})
                return <AddNew call={partialCall} />

            case "hold":
                return <HoldCall onClose={() => {
                    const currentCall: ActiveCall = {...voiceState.activeCall, activeModal: ''};
                    dispatch(voiceActions.updateActiveCall(currentCall))
                }} />
            default:
                break;
        }
        return <div><div>{WorkizLang.get().alerts.oops}...</div><div>{WorkizLang.get().alerts.somethingWrong} :(</div></div>
    }

    const getModalHeader = (): string => {
        switch (voiceState.activeCall?.activeModal) {
            case "add-action":
                return WorkizLang.get().workizWeb.addNew

            case "hold":
                return WorkizLang.get().dialer.holdCall
            default:
                break;
        }
        return ""
    }

    const contactSelectedAction = (contact: any) => {
        switch (suggestionsOpen) {
            case "transfer":
                TwilioManager.getInstance().transferCall(contact);
                break;

            case "conference":
                TwilioManager.getInstance().addToCall(contact)
                break;

            default:
                break;
        }
        setSuggestionsOpen("")
    }


    const canBeClient = () => {
        return (voiceState.activeCall?.user_type == "3" && Number(voiceState.activeCall.client_id) > 0) || Number(voiceState.activeCall?.client_id ?? 0) <= 0
    }

    const renderActionBox = () => {
        return (
            <div style = {{width:"100%"}} className='padding-screen'>
            <div className="actionsRow">
                {keypadOpen ? (
                    <>
                    <Keypad onClick={(digit) => TwilioManager.getInstance().sendDigit(digit)} />
                    <div className="hideButton" onClick={() => setKeypadOpen(false)}>
                    <i className="wfi-up" />{WorkizLang.get().dialer.hide}
                    </div>
                    </>
                ) : (
                    <>
                    {callActionsConfig.map((action, index) => (
                        <CallAction key={index} title={action.title} iconName={action.iconName} onClick={action.onClick} />
                    ))}
                    <div className="callAction" />
                    <div className="buttons-container center-content">
                    <CallButton isHangup={true} onClick={() => TwilioManager.getInstance().disconnect()} />
                    </div>
                    </>
                )}
            </div>
            </div>
        );
    }
  
    const isUnknown = isUnknownContact(voiceState.activeCall)
    const beforeConnecting = voiceState?.beforeConnecting
    const fullName = beforeConnecting ? 'connecting' : isUnknown ? voiceState.activeCall?.phone : voiceState.activeCall?.full_name 
    return (
        <> 
            <div className="center-content flex-column" >
                <div className="header-container">
                    <div className={"flex-one  font-16 text-ellipsis"}>
                        <div className="contact-name">{fullName}</div>
                        <div className="timer">
                        <Timer active={voiceState.onCall ?? false} startingTime={voiceState.activeCall?.call_time ?? moment()} />
                        </div>
                    </div>
                </div>
                {canBeClient() && <div className='action-buttons-container'>
                    <ActionButtons extraClass='active-call' buttons = {getActionButtons(voiceState.activeCall)}/>
                </div>}
                {renderActionBox()}
            </div>
          
            {
                !voiceState.activeCall?.isIncoming || 
                typeof(voiceState.activeCall?.is_recording) && voiceState.activeCall?.is_recording === '1' ?
                <div className='recordingLogo'>
                    <div className={`logo ${beforeConnecting ? ' standby' : ''}`}></div>
                    <span>{beforeConnecting ? WorkizLang.get().dialer.recordingStandby : WorkizLang.get().dialer.recordingOn}</span>
                </div>
                : null
            }
            <Modal title={getModalHeader()} scrollAble={false} onClose={() => {
                const currentCall: ActiveCall = {...voiceState.activeCall, activeModal: ''};
                dispatch(voiceActions.updateActiveCall(currentCall))
            }} show={Boolean(voiceState.activeCall?.activeModal)}>
                {getModalContent()}
            </Modal>
            <ContactSuggestions
                isOpen={suggestionsOpen !== ""}
                onSelect={contactSelectedAction}
                title={suggestionTitles[suggestionsOpen] ?? ""}
                onClose={() => setSuggestionsOpen("")}
                allowWorkizNumber={suggestionsOpen === "transfer"}
            />
        </>
    )
}

export default OnCallContent
