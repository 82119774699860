import React from 'react'
import "./form.scss"
import Loader from 'components/layout/Loader';

/**
 * 
 * @param {{
 *  title: string,
 *  onClick: VoidFunction,
 *  isLoading?: boolean,
 *  disable?: boolean,
 *  small?: boolean,
 *  cancelAction?: boolean,
 *  extraClass?: string,
 * }} props 
 * @returns 
 */
function SubmitButton(props) {
    let content = props.title;
    if (props.isLoading) {
        content = <div><Loader small={props.small} /></div>
    }

    let containerClass = "submitButton blue pointer "

    if (props.disable) containerClass += " disabled "
    if (props.small) containerClass += " small "
    if (props.cancelAction) containerClass += " cancel "
    if (props.extraClass) containerClass += props.extraClass

    return (
        <div
            className={containerClass}
            onClick={(props.disable || props.isLoading) ? () => { } : props.onClick}
        >
            {content}
        </div>
    )
}

export default SubmitButton
