import React from 'react'
import "./listRow.scss"
import Loader from './Loader';
import ActionButtons from './ActionButtons';
type Printable = string | JSX.Element 

interface ListRowProps {
    onClick?: VoidFunction,
    iconName?: string,
    title?: Printable,
    small?: Printable,
    rightTop?: Printable,
    leftBottom?: Printable,
    rightBottom?: Printable,
    isLoading?: boolean,
    queueListRow?: boolean,
    actionButtons?: Object,
    withoutBorderBottom?: boolean,
    isLeftIconInsideRow?: boolean,
    leftTop?: Printable,
    rightSection?: Printable
}

function ListRow(props: ListRowProps) {

    let itemClass = "list-item";
   
    const containerClass = "item-container";
   
    if (typeof props.onClick === "function") {
        itemClass += " pointer";
    }
    if (!props.withoutBorderBottom) {
        itemClass += " border-bottom";
    }

    if(props.queueListRow){
        itemClass += " queueListRow";
    }

    const LeftIcon = ({ extraClass }: { extraClass?: string }) => {
        if (props.iconName) {
            return <i className={props.iconName + " " + extraClass + " left-icon"} />;
        }
        return null;
    }
    const LeftElement = ({ smallText, element, extraClass }: { smallText?: Printable, element?: Printable, extraClass?: string }) => {
        if (element) {
            return (
                <>
                    <div className={"flex-one text-ellipsis margin-right " + (extraClass ?? "")}>
                        {element}
                        {smallText && <small><br />{smallText}</small>}
                    </div>
                </>
            )
        }
        return <div className={"flex-one"} />;
    }
    
      

    const RightElement = ({ element }: { element?: Printable }) => {
        if (element)
            return <div>{element}</div>
        return null;
    }

    const RightSection = ({ element }: { element?: Printable }) => {
        if (element)
            return <div className={"flex-row small-padding-vertical"}>{element}</div>
        return null;
    }
    
 
    return (
        <div className='item-container-all'>
        <div className={containerClass}>
        {!props.isLeftIconInsideRow && <LeftIcon extraClass={props.leftTop?"big-padding-top":""}/>}
        <div onClick={props.onClick} className={itemClass}>
            <div className={props.rightSection?"flex-space-between":""}>
                <div>
                {props.leftTop &&  <LeftElement element={props.leftTop} />}
                {!props.iconName && !props.title && !props.rightTop ? null :
                    <div className={"flex-row list-child center-content"}>
                        {props.isLeftIconInsideRow && <LeftIcon extraClass ="inner-icon" />}
                        <LeftElement smallText={props.isLoading ? undefined : props.small} element={props.isLoading ? <Loader small /> : props.title} extraClass="row-title" />
                        <RightElement element={props.rightTop} />
                    </div>}
                {!props.leftBottom && !props.rightBottom ? null :
                    <div className={"flex-row bottom-list-child"}>
                        <LeftElement element={props.leftBottom} />
                        <RightElement element={props.rightBottom} />
                    </div>}
                </div>
                {props.rightSection && <RightSection element={props.rightSection}/>}
            </div>
            {props.actionButtons && <ActionButtons  buttons = {props.actionButtons}/>}
        </div>
        </div>
        </div>
    )
}

export default ListRow
