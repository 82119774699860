import React, { Component } from 'react'
import * as textValidation from 'helpers/TextValidation'
import SubmitButton from './SubmitButton'
import ButtonRadio from './ButtonRadio'
import NetworkManager from 'helpers/NetworkManager'
import urlConstants from 'constants/urlConstants'
import { trackEvent, WorkizEvents } from '../../helpers/TrackActions';
import CallNotifications from '../layout/CallNotifications'
import WorkizLang from 'language/WorkizLang'


type sendRecordingFields = "to" | "via" | "msg" | "src"
interface SendRecordingsProps {
    closeModal: VoidFunction,
    recordings: Array<string>
}

interface SendRecordingState {
    fields: {
        [key: string]: string
    },
    valid: string,
    loading: boolean,
    sendFailed: boolean
}

const SEND_VIA_OPTIONS = [
    { value: "sms", label: "Text" },
    { value: "email", label: "Email" },
]

class SendRecordingsForm extends Component<SendRecordingsProps, SendRecordingState> {

    state = {
        fields: {
            via: "sms",
            to: "",
            msg: "Please see attached recording",
            src: "",
        },
        valid: "",
        loading: false,
        sendFailed: false,
    }

    active = false;

    componentDidMount() {
        this.active = true;
    }

    componentWillUnmount() {
        this.active = false;
    }

    validate = (fields: { [key: string]: string }, require = false) => {
        let valid = "";
        if (!fields.to) {
            valid = require ? "This field is required" : ""
        } else {
            if (fields.via === "sms" && !textValidation.validateRegex(fields.to, "phone")) {
                valid = "Invalid phone number"
            }
            if (fields.via === "email" && !textValidation.validateRegex(fields.to, "email")) {
                valid = "Invalid email address"
            }
        }
        this.setState({ valid })
        return (valid === "")
    }

    onChange = (value: any, key: sendRecordingFields) => {
        if (this.state.loading) return;
        const fields = { ...this.state.fields }
        fields[key] = value;
        if (key === "via") {
            this.validate(fields);
        }
        this.setState({ fields })
    }

    submit = async () => {
        if (this.validate(this.state.fields, true)) {
            this.setState({ loading: true })

            const obj = {
                ...this.state.fields, 
                src: this.props.recordings.join(",")
            }
            const res = await NetworkManager.post(urlConstants.workizWeb.sendRecording, obj).catch(() => {})
            if (res && res.flag) {
                this.props.closeModal()
                trackEvent(WorkizEvents.RecordingSent, {via: this.state.fields.via})
            } else {
                if (this.active) {
                    this.setState({ sendFailed: true })
                }
            }
            this.setState({ loading: false })
        }
    }

    renderButtons = () => {
        return (
            <div className="flex-column margin-top">
                <div className="margin-bottom">
                        <SubmitButton
                            onClick={this.submit}
                            title="Send"
                            small
                            disable={this.state.valid != ""}
                            isLoading={this.state.loading}
                            extraClass='recording-button'
                        />
                    </div>
                    <div>
                        <SubmitButton
                            onClick={this.props.closeModal}
                            title="Cancel"
                            small
                            cancelAction
                            extraClass = 'recording-button'
                        />
                    </div>
            </div>
        )
    }

    render() {
        return (
            <div className="send-recording-form">
                <ButtonRadio
                    onChange={(val) => this.onChange(val, "via")}
                    options={SEND_VIA_OPTIONS}
                    value={this.state.fields.via}
                />
                <input onFocus={() => this.setState({ valid: "" })} onBlur={() => this.validate(this.state.fields)} placeholder="To" onChange={e => this.onChange(e.target.value, "to")} type="text" value={this.state.fields.to} />
                <div className={`red animated ${this.state.valid ? "" : "invisible"}`}>
                    {this.state.valid}
                </div>
                <textarea onChange={e => this.onChange(e.target.value, "msg")} value={this.state.fields.msg} />
                {this.renderButtons()}
                {this.state.sendFailed && <CallNotifications onClose= {()=>{this.setState({sendFailed:false})}} icon={"wfi-Warning-Circle-fill"} text={WorkizLang.get().sendRecording.failed} />}
            </div>
        )
    }
}

export default SendRecordingsForm
