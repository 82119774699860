import urlConstants from 'constants/urlConstants';
import { Device, Call} from '@twilio/voice-sdk';
import NetworkManager from './NetworkManager';
import { store } from 'index';
import * as voiceActions from 'store/actions/voiceActions';
import moment from 'moment';
import { clearNumber, phoneFormat } from './StringFormats';
import User from './User';
import { CallHistoryItemPrepped, CallQueueItem, CallsHistoryItem, ConferenceParticipant, ContactSuggestion } from 'constants/types';
import { ActiveCall, VoiceReduxState } from 'constants/storeConstants';
import { setTimeoutAsync } from './WorkizHelper';
import EventsAdapter from 'helpers/EventsAdapter'
import { trackEvent, WorkizEvents } from './TrackActions';
import WorkizLang from 'language/WorkizLang';
import { notifyIncomingCall } from './Notify';
import {isEnabled} from 'helpers/FeatureFlags';
import * as FeatureFlagsNames from 'constants/FeatureFlagsNames';

const delay = (ms:any) => new Promise(res => setTimeout(res, ms));

enum TwilioErrorCode {
    TOKEN_EXPIRED = 31205,
    TOKEN_EXPIRED_WARNING = 20104,
    GENERAL_TWILIO_CLIENT_ERROR = 31000
}

const stubActiveCall: ActiveCall = {
    call_time: moment(),
    client_id: "1000",
    email: "dana@workiz.com",
    full_name: "Dana Macarona",
    isIncoming: false,
    phone: "0506600022",
    user_type: "3",
    parent_call_sid: "AI254654032106540",
};

const connectionWarningOptions: string[] = ['high-rtt','low-mos','high-jitter','high-packet-loss','high-packets-lost-fraction'
                        ,'low-bytes-received','low-bytes-sent','ice-connectivity-lost'];
const audioWarningOptions: string[] = ['constant-audio-input-level','constant-audio-output-level'];

class TwilioManager {

    //#region Instance

    private static instance?: TwilioManager;
    private accessToken?: string;
    private device?: Device;
    private activeIncomingCall?: Call;
    private activeCallInQueue?: CallQueueItem;
    private activeCall?: Call;
    private activeCallObj?: ActiveCall;
    public available = this.getDialerSettings().isAvailable === "1";
    private lastTokenFetch: moment.Moment;
    private callingFrom = "";
    private callContactSuggestions?: Array<any>
    private changingHoldState = false;
    private extraConnectCallback: VoidFunction = () => { }
    public pendingConfParticipant?: ConferenceParticipant
    public handledCallQueues: { [key: string]: boolean } = {}
    private notification?: Notification
    private wasErrorConnectionDuringCall = false

    private constructor() {
        this.lastTokenFetch = moment().subtract(1, "day");
    }

    public static getInstance(): TwilioManager {
        if (TwilioManager.instance == null) {
            TwilioManager.instance = new TwilioManager();
        }
        return TwilioManager.instance;
    }

    public static clearInstance() {
        if (TwilioManager.instance && TwilioManager.instance.device) {
            TwilioManager.instance.device.destroy()
            TwilioManager.instance = undefined;
        }
    }

    //#endregion Instance

    //#region State

    public setDialerSettings(isAvailable: boolean, callingFrom: string) {
        const settings = {isAvailable:isAvailable?"1":"0", callingFrom}
        localStorage.setItem('dialerSettings', JSON.stringify(settings));
    }

    public getDialerSettings()  {
        const settings = localStorage.getItem('dialerSettings');
        if (settings) {
            return JSON.parse(settings)
        }
        return {
            isAvailable: "1"
        };
    }
    

    public setCallingFrom(val: string) {
         this.callingFrom = val 
         this.setDialerSettings(this.available, val);
    }

    public changeAvailability = async () => {
        this.available = !this.available;

        if(process.env.REACT_APP_WEB) {
            EventsAdapter.setDialerDefaults(this.available)
        }
        this.setDialerSettings(this.available, this.callingFrom);
        this.getToken()
        trackEvent(WorkizEvents.ChangedDialerAvailability, { available: this.available })
    }

    public async getToken() {
        this.log("getting token")
        store.dispatch(voiceActions.setVoiceState({ deviceReady: false, deviceLoading: true, lastError: "" }))
        const res = await NetworkManager.post(urlConstants.voiceSettings.getAccessToken, { avalible: this.available ? "true" : "false" })
            .catch(console.warn);
        if (res && res.data && typeof res.data === "string") {
            this.accessToken = res.data;
            this.initiateDevice();
            this.lastTokenFetch = moment();
        } else {
            store.dispatch(voiceActions.setVoiceState({ deviceReady: false, deviceLoading: false, lastError: "Unknown error has occurred" }))
        }
    }

    public getCallBySid = (callSid: string): null | CallHistoryItemPrepped => {
        const callsHistory = store.getState().callsHistory.calls
        const item = callsHistory.find(_ => _.call_sid === callSid)
        return item ?? null
    }

    private getContactByCallSid = (callSid: string): ActiveCall => {
        const item = this.getCallBySid(callSid)
        if (item) {
            let obj: ActiveCall = {
                ad_group_id: item.ad_group_id,
                ad_group_name: item.group_name,
                client_id: item.client_id,
                user_type: Number(item.client_id) > 0 ? "3" : (Number(item.uid_called) > 0 ? "0" : "-1"),
                full_name: item.from == item.fromNumber ? WorkizLang.get().data.unknownName : item.from,
                phone: item.fromNumber,
                job_id: item.job_id,
                user_id: item.uid_called,
                flow: item.flow_name,
                parent_call_sid: callSid,
                client_contact_id: item.client_contact_id,
                parent_client_name: item.parent_client_name
            }
            if (item.direction == "outgoing") {
                obj = {
                    ...obj,
                    user_id: item.uid_answered,
                    full_name: item.to == item.toNumber ? WorkizLang.get().data.unknownName : item.to,
                    phone: item.toNumber,
                    user_type: Number(item.client_id) > 0 ? "3" : (Number(item.uid_answered) > 0 ? "0" : "-1"),
                }
            }
            return obj;
        }
        return {
            parent_call_sid: callSid
        }
    }

    public setNotification = () => {
        if (this.activeCallObj && this.activeCallObj.parent_call_sid) {
            let callerDesc = this.activeCallObj?.full_name;
            if (this.activeCallObj?.full_name === WorkizLang.get().data.unknownName && !User.get().shouldUseMasking()) {
                callerDesc = phoneFormat(this.activeCallObj?.phone)
            }
            const title = callerDesc + " is calling you";
            this.notification = notifyIncomingCall({
                title: title,
                body: "Click to answer",
                tag: this.activeCallObj?.parent_call_sid
            })

            this.notification.addEventListener("click", () => {
                this.connectIncoming()
                this.notification = undefined
            })
            
            if(process.env.REACT_APP_WEB) {
                EventsAdapter.addIncomingMsgToWindow(callerDesc, () => this.connectIncoming(), () => this.rejectIncoming());
            }
        }
    }

    public removeNotification = () => {
        if (this.notification) {
            this.notification.close()
            this.notification = undefined
        }

        if(process.env.REACT_APP_WEB) {
            EventsAdapter.removeIncomingMsgToWindow();
        }
    }

    public setActiveCallQueue(queue?: CallQueueItem) {
        this.activeCallInQueue = queue;
        if (this.activeCall) return;
        if (queue) {
            this.activeCallObj = this.getContactByCallSid(queue.call_sid)
            this.setRinging(true)
        } else {
            this.clearCallData()
            store.dispatch(voiceActions.setVoiceState({ ringing: false, activeCall: {} }))
        }
    }

    private triggerRing = () => {
        if (document && document.getElementById("call_incoming_sound")) {
            const audioElement: HTMLAudioElement = document.getElementById("call_incoming_sound") as HTMLAudioElement
            audioElement.play().then(() => { this.log("play success") }).catch((e) => { this.log("play fail", e) })
        }
    }

    private stopRinging = () => {
        if (document && document.getElementById("call_incoming_sound")) {
            const audioElement: HTMLAudioElement = document.getElementById("call_incoming_sound") as HTMLAudioElement
            try {
                audioElement.pause()
            } catch (error) {
                this.log("error stopping ringing")
            }
        }
    }

    public getActiveCallQueue() {
        return this.activeCallInQueue
    }

    public getActiveParentCallSid(): string {
        if (this.activeCallObj && this.activeCallObj.parent_call_sid) {
            return this.activeCallObj.parent_call_sid;
        }
        return "";
    }

    public getConferenceParticipants(): { [key: string]: ConferenceParticipant } | undefined {
        if (this.activeCallObj && this.activeCallObj.conferenceParticipants) {
            return this.activeCallObj.conferenceParticipants
        }
        return undefined;
    }

    public getActiveCallObj(): ActiveCall {
        return this.activeCallObj ?? {}
    }

    private clearCallData(skipObject = false) {
        this.stopRinging()
        this.removeNotification()
        this.activeIncomingCall = undefined;
        this.activeCallInQueue = undefined;
        this.activeCall = undefined;
        this.callContactSuggestions = undefined;
        this.changingHoldState = false;
        if (!skipObject) {
            this.activeCallObj = undefined;
            this.extraConnectCallback = () => { }
        }
    }

    //#endregion State

    //#region Device Callbacks

    public async initiateDevice() {
        if (this.device != null) this.device.destroy();
        if (this.accessToken) {
            this.device = new Device(this.accessToken, {
                sounds: {
                    incoming: NetworkManager.buildBaseUrl(urlConstants.assets.ringtone)
                }
            });
            trackEvent(WorkizEvents.loadTwilioDevice, {
                type: `${process.env.REACT_APP_WEB ? 'web' : 'desktop'}-dialer`
            })

            /** Register all twilio device events */
            this.device.on(Device.EventName.Registered, this.onDeviceReady.bind(this));
            this.device.on(Device.EventName.Unregistered, this.onDeviceOffline.bind(this));
            this.device.on(Device.EventName.Error, this.onDeviceError.bind(this));
            this.device.on(Device.EventName.Incoming, this.onIncomingCall.bind(this));

            !process.env.REACT_APP_WEB && this.registerAudioDevices();
            this.device.register();
        }
    }

    public setConnection = (conn: Call) => {
        conn.on('disconnect', this.onCallDisconnected.bind(this));
        conn.on('cancel', this.onCallCancel.bind(this));
        conn.on('accept', this.onCallConnect.bind(this));
        conn.on('warning', this.onCallWarning.bind(this));
        conn.on('warning-cleared', this.onCallWarningsCleared.bind(this));
        conn.on('reconnecting', this.onReconnecting.bind(this));
        conn.on('reconnected', this.onReconnected.bind(this));
    }

    private async registerAudioDevices() {
        if (this.device?.audio?.isOutputSelectionSupported) {
            this.device.audio.on("deviceChange", async () => {
                if (this.device?.audio) {

                    if (this.device.audio.availableInputDevices.size) {
                        this.log("default input device", this.device.audio.availableInputDevices)

                        await this.device.audio.setInputDevice("default").catch(() => this.log("setting default device failed"))
                        this.log("done with input device")
                    }

                    if (this.device.audio.speakerDevices.get().size) {
                        this.log("default speaker device", this.device.audio.speakerDevices.get())
                        await this.device.audio.speakerDevices.set("default").catch(() => this.log("setting default speaker failed"))
                        this.log("done with speaker device")
                    }
                }
            })
        }
    }

    public checkMicrophonePermission() {
        if (EventsAdapter.micPermission()) {
            console.log("has permissions")
        } else {
            console.log("has not permissions")
        }
    }

    public testOutputDevice() {
        return new Promise(resolve => {
            if (this.device) {
                this.device.audio?.ringtoneDevices.test()
                    .then(() => {
                        resolve(true)
                    })
                    .catch(() => {
                        resolve(false)
                    })
            }
        })
    }

    public testInputDevice() {
        const res = EventsAdapter.micPermission();
        if (!res) return false;
        return new Promise(resolve => {
            if (this.device) {
                this.device.audio?.speakerDevices.test()
                    .then(() => {
                        resolve(true)
                    })
                    .catch(() => {
                        resolve(false)
                    })
            }
        })
    }

    private onDeviceReady(device: Device) {
        this.log("device ready")
        store.dispatch(voiceActions.setVoiceState({ deviceReady: true, deviceLoading: false, lastError: "" }));

        if(process.env.REACT_APP_WEB) {
            EventsAdapter.runOnPopupReadForWeb();
        }
    }

    private onDeviceError(err: any) {
        this.log("device error", err.message, err.code)
        if (err.code == TwilioErrorCode.TOKEN_EXPIRED || err.code == TwilioErrorCode.TOKEN_EXPIRED_WARNING
            || err.code == TwilioErrorCode.GENERAL_TWILIO_CLIENT_ERROR) {
            const diff = moment().diff(this.lastTokenFetch, "minute");
            this.log("last update was ", diff, " minutes ago");
            this.log(moment().format("HH:mm:ss ms"), this.lastTokenFetch.format("HH:mm:ss ms"))
            if (store.getState().voice.onCall)  {
                this.wasErrorConnectionDuringCall = true;
            }

            else if (diff > 1) {
                this.log("token expired, getting a new one.")
                this.getToken();
                return;
            }
        }
        store.dispatch(voiceActions.setVoiceState({ lastError: err.message, deviceLoading: false }))
    }

    /**
    * This is triggered when the connection to Twilio drops
    * or the device's token is invalid/expired. 
    * In either of these scenarios, 
    * the device cannot receive incoming connections 
    * or make outgoing connections. 
    * If the token expires during an active connection the offline event will be fired, 
    * but the connection will not be terminated. 
    * In this situation you will have to call Twilio.Device.setup() 
    * with a valid token before attempting or receiving the next connection.
    */
    private onDeviceOffline(device: Device) {
        this.log("device offline")
        store.dispatch(voiceActions.setVoiceState({ deviceReady: false }))
    }

    //#endregion Device Callbacks

    //#region Call Callbacks

    private async onCallConnect(conn: Call) {
        this.log("call connected")
        const newState: VoiceReduxState = { onCall: true, ringing: false, transferring: false }
        store.dispatch(voiceActions.setVoiceState(newState))
        /** Prepare call details obj */
        this.log("call active obj before", this.activeCallObj?.isIncoming, this.activeCallObj)
        if (!this.activeCallObj) this.activeCallObj = {}
        this.activeCallObj.call_sid = conn.customParameters.get("CallSid") || conn.parameters.CallSid
        this.activeCallObj.parent_call_sid = this.activeCallObj.parent_call_sid ?? this.activeCallObj.call_sid
        const childCallSid = this.activeCallObj.parent_call_sid ?? this.activeCallObj.call_sid;
        this.activeCallObj.call_time = this.activeCallObj.call_time ?? moment()
        this.activeCallObj.is_recording = conn.customParameters.get("is_recording");
        const callObj = this.getContactByCallSid(this.activeCallObj.parent_call_sid || this.activeCallObj.call_sid)

        this.activeCallObj = { ...callObj, ...this.activeCallObj }
        store.dispatch(voiceActions.setActiveCall(this.activeCallObj))
        this.log("call active obj", this.activeCallObj.isIncoming, this.activeCallObj)
        this.clearCallData(true)
        this.activeCall = conn
        this.extraConnectCallback()
        if(this.activeCallObj.on_conference){
            NetworkManager.post(urlConstants.voice.setConferenceParticipants,{parent: this.activeCallObj.parent_call_sid, child: this.activeCallObj.call_sid})
            .then(() => {})
            .catch((err) => {console.log(err)})
        }
    }

    private onCallDisconnected(call: Call) {
        if (this.wasErrorConnectionDuringCall) {
            this.log("token expired, getting a new one.")
            this.wasErrorConnectionDuringCall = false;
            this.getToken();
        }
        this.log("call disconnected")
        if (this.activeCallObj) {
            const details: Record<string, any> = {
                call_sid: call?.parameters?.CallSid
            }
            if (moment.isMoment(this.activeCallObj.call_time)) {
                const call_length = moment().diff(this.activeCallObj.call_time, "seconds")
                details.call_length = call_length
            }
            trackEvent(this.activeCallObj.isIncoming ? WorkizEvents.AnsweredCall : WorkizEvents.MadeCall, details)

        }
        this.clearCallData()
        store.dispatch(voiceActions.setVoiceState({ onCall: false, muted: false, onHold: false, onConference: false, activeCall: {}, initiatedConf: false }))
    }

    private async onIncomingCall(conn: Call) {
        await delay(300);
        if (!conn.parameters.CallSid) return;
        this.setConnection(conn);
        this.log("incoming call", conn.parameters.CallSid);
        if (!this.activeCallInQueue && !this.activeIncomingCall && !this.activeCall) {
            this.activeIncomingCall = conn;
            this.activeCallObj = this.getContactByCallSid(conn.customParameters.get("CallSid") || conn.parameters.CallSid)
            if(typeof this.activeCallObj !== 'object'){
                this.activeCallObj = {
                    parent_call_sid: conn.customParameters.get("CallSid") || conn.parameters.CallSid
                }
            }
            if(Object.keys(this.activeCallObj).length <= 1){
                this.activeCallObj.full_name = conn.customParameters.get('name') || WorkizLang.get().data.unknownName;
                const number = conn.customParameters.get('number') || '';
                this.activeCallObj.phone = phoneFormat(number);
            }
            this.activeCallObj.isIncoming = true;
            this.activeCallObj.flow = conn.customParameters.get('flow_name');
            this.log("setting active call object")
            this.setRinging()
        } else {
            this.log("ignoring incoming call")
            conn.ignore();
        }
    }

    private setRinging = (withSound = false) => {
        if (withSound) {
            this.triggerRing()
        }
        store.dispatch(voiceActions.setVoiceState({ ringing: true, activeCall: this.activeCallObj }))
        if(!process.env.REACT_APP_WEB) {
            EventsAdapter.focusOnDialer()
        }
        this.setNotification()
    }

    private onCallCancel(conn: Call) {
        this.log("call canceled");
        this.clearCallData();
        store.dispatch(voiceActions.setVoiceState({ ringing: false, activeCall: {} }))
    }

    private onCallWarning = (warningName: string) => {
        let warningType = '';
        if(connectionWarningOptions.includes(warningName)) {
            warningType = 'connection';
        }
        else if(audioWarningOptions.includes(warningName)) {
            warningType = 'audio';
        }

        if(this.activeCallObj && warningType != '') {
            this.activeCallObj.warningType = warningType;
            this.activeCallObj.activeWarning = [...this.activeCallObj.activeWarning || [], warningName]
            store.dispatch(voiceActions.setActiveCall(this.activeCallObj))
        }
        
        const logWarningParams =  {
            To: this.activeCall?.customParameters.get("To"),
            From: this.activeCall?.customParameters.get('From'),
            CallSid: this.activeCallObj?.call_sid,
            warningType, 
            warningName
        }

        try {
            NetworkManager.post(urlConstants.data.logWarningDuringCalls, logWarningParams);
        } catch (e) {
            this.addWarningEntry(logWarningParams);
        }
    }

    private addWarningEntry = (params: object) => {
        const existingEntries = JSON.parse(localStorage.getItem("warningDuringCall") || '[]');
        existingEntries.push(params);
        localStorage.setItem("warningDuringCall", JSON.stringify(existingEntries));
    };

    private onCallWarningsCleared = (warningName: string) => {
        if(this.activeCallObj?.activeWarning && this.activeCallObj.activeWarning.length > 0) {
            const arr = this.activeCallObj?.activeWarning.filter((item) => item !== warningName);
            if (arr.length > 0) {
                const connectionWarning = arr.filter(value => connectionWarningOptions.includes(value));
                this.activeCallObj.warningType = connectionWarning.length > 0 ? "connection": "audio";
            }
            this.activeCallObj.activeWarning = arr;
            store.dispatch(voiceActions.setActiveCall(this.activeCallObj))
        }
    }

    private onReconnecting = () => {console.log('RECONNECTING EVENT')
        if(this.activeCallObj) {
            this.activeCallObj.warningType = 'reconnecting';
            this.activeCallObj.activeWarning = [...this.activeCallObj.activeWarning || [], 'reconnecting'];
            store.dispatch(voiceActions.setActiveCall(this.activeCallObj))
        }
    }

    private onReconnected = () => {
        console.log('RECONNECTED EVENT')
        if(this.activeCallObj?.activeWarning) {
            this.activeCallObj.activeWarning = this.activeCallObj?.activeWarning?.filter((item) => item !== "reconnecting");
            store.dispatch(voiceActions.setActiveCall(this.activeCallObj))
        }
    }

    //#endregion Call Callbacks

    //#region Actions

    public async placeCall(to: string, obj?: ContactSuggestion) {
        const newState: VoiceReduxState = { onCall: true, ringing: false, transferring: false, beforeConnecting: true}
        store.dispatch(voiceActions.setVoiceState(newState))
        if (this.activeCall || this.activeIncomingCall || this.activeCallInQueue) return;
        console.log("place call", to)
        const callTo = clearNumber(to);
        const isMaskingCall = clearNumber(User.get().userData?.masking_number || "0") === callTo ? "1" : "0"
        const activeCallObj: ActiveCall = {
            isIncoming: false,
            call_time: moment(),
            phone: to,
        }
        if (obj) {
            activeCallObj.email = obj.email
            activeCallObj.full_name = obj.name
            activeCallObj.user_id = obj.data
            activeCallObj.user_type = obj.type
            activeCallObj.job_id = obj.job_id
            activeCallObj.client_contact_id = obj.client_contact_id

            if (obj.type === "3" && !isNaN(Number(obj.data)) && Number(obj.data) > 0) {
                activeCallObj.client_id = obj.data
            }
        }
        this.activeCallObj = activeCallObj;
        if (callTo && this.device != null) {
            const phoneNumberToCallFrom = this.getNumberToCallFrom(obj?.phone_number_to_call_from || '');
            const params = {
                To: callTo,
                From: phoneNumberToCallFrom ?? this.callingFrom,
                client_id: activeCallObj.client_id ?? "",
                job_id: activeCallObj.job_id ?? "",
                uid_called: User.get().userData?.user?.id ?? "",
                isWeb: process.env.REACT_APP_WEB ?? "false",
                isMaskingCall,
                client_contact_id:  activeCallObj.client_contact_id ?? ""
                // ...extraParams,
            }
            try {
                const currentCall = await this.device.connect({params});
                this.setConnection(currentCall);
            } catch (error) {
                console.log(error)
                this.device.disconnectAll()
            }
        }
    }

    public connectIncoming() {
        console.log(this.activeIncomingCall, 'log before answering logic');
        if (this.activeIncomingCall != null) {
            this.activeCallObj = {
                parent_call_sid: this.activeIncomingCall.customParameters.get('CallSid'),
                call_sid: this.activeIncomingCall.customParameters.get('CallSid'),
                isIncoming: true,
                ...(this.activeCallObj ?? {})
            }
            this.activeIncomingCall.accept()
        } else if (this.activeCallInQueue) {
            console.log(this.activeCallInQueue, 'before answering call queue call');
            this.connectQueue({}, this.activeCallInQueue)
        }
    }

    public rejectIncoming() {
        this.log("rejecting call")
        if (this.activeIncomingCall != null) {
            this.activeIncomingCall.reject();
            this.log("active incoming call rejected")
        }
        if (this.activeCallInQueue || this.activeIncomingCall) {
            this.log("clearing incoming call/queue details")
            trackEvent(WorkizEvents.RejectedACall, { isCallQueue: this.activeCallInQueue ? true : false })
            this.clearCallData()
            store.dispatch(voiceActions.setVoiceState({ ringing: false }))
        }
    }

    public async connectQueue(call: CallsHistoryItem, queue: CallQueueItem) {
        this.stopRinging()
        const newState: VoiceReduxState = { onCall: true, ringing: false, transferring: false, beforeConnecting: true}
        store.dispatch(voiceActions.setVoiceState(newState))
        const params = {
            To: "",
            From: call.fromNumber ?? "",
            join_conference: "1",
            call_sid: queue.call_sid,
            fromQ: "1",
            group_id: queue.group_id!.toString(),
            user_id: queue.user_id!.toString(),
            phone: call.fromNumber ?? phoneFormat(queue.from),
            uid_called: User.get().userData?.user?.id ?? "",
            is_recording: queue.is_recording,
            flow_id: queue.flow_id!.toString(),
            isWeb: process.env.REACT_APP_WEB ?? "false",
            queue_sid: queue.queue_sid
        }
        this.activeCallObj = {
            parent_call_sid: queue.call_sid,
            phone: params.phone,
            isIncoming: true,
            on_conference: true,
        }
        if (this.device) {
            const currentCall = await this.device.connect({params})
            this.setConnection(currentCall);
        }
    }

    public async connectToHeldCall(call: CallsHistoryItem) {
        trackEvent(WorkizEvents.ConnectedToHeldCall)
        const params = {
            To: "",
            From: call.fromNumber ?? "", /** todo... get correct number. mapping helper maybe? */
            join_conference: "1",
            call_sid: call.call_sid ?? "",
            fromQ: "1",
            phone: call.fromNumber ?? "",
            uid_called: User.get().userData?.user?.id ?? "",
            isWeb: process.env.REACT_APP_WEB ?? "false"
        }
        this.activeCallObj = {
            parent_call_sid: call.call_sid,
            phone: params.phone,
            isIncoming: false,
            on_conference: true,
        }
        if (this.device) {
            this.extraConnectCallback = () => {
                NetworkManager.post(`${urlConstants.voice.unHold}${call.call_sid}/`, params).catch(console.warn)
                this.extraConnectCallback = () => { }
            }
            const currentCall = await this.device.connect({params})
            this.setConnection(currentCall);
        }
    }

    public disconnect() {
        if (this.device != null) {
            this.device.disconnectAll();
        }
        this.clearCallData()
    }

    public sendDigit(digit: string) {
        if (this.activeCall) {
            this.activeCall.sendDigits(digit)
        }
    }

    public mute() {
        if (this.activeCall) {
            const isMuted = !this.activeCall.isMuted()
            this.activeCall.mute(isMuted)
            store.dispatch(voiceActions.setVoiceState({ muted: isMuted }))
        }
    }

    public async joinConference() {
        const params = {
            child: this.activeCall?.parameters?.CallSid,
            parent: this.activeCallObj?.parent_call_sid
        }
        const res = await NetworkManager.post(urlConstants.voice.joinConference, params).catch(console.warn)
        if (this.activeCallObj) {
            this.activeCallObj.on_conference = true;
        }
    }

    public async transferCall(to: any) {
        trackEvent(WorkizEvents.TransferredCall)
        if (to && to.phone && this.activeCallObj?.parent_call_sid) {
            this.log("transferring call")
            const call_sid: string = this.activeCallObj.parent_call_sid;
            const params = {
                type: to.is_dialer ? "user" : "number",
                number: to.phone,
                user_id: to.data,
            }
            store.dispatch(voiceActions.setVoiceState({ transferring: true }))
            const res = await NetworkManager.post(`${urlConstants.voice.transfer}${call_sid}/`, params).catch(() => { })
            if (res && res.flag) {
                await setTimeoutAsync(2000)
            }
            store.dispatch(voiceActions.setVoiceState({ transferring: false }))
            /** todo: check success for transfer */
            this.log("transfer finished")
        }
    }

    public async holdCall(isForMe: boolean) {
        trackEvent(WorkizEvents.PutCallOnHold, { isForMe, fromConference: !!this.activeCallObj?.on_conference })
        if (this.changingHoldState) return;
        this.changingHoldState = true;

        if (isForMe && !this.activeCallObj?.on_conference) {
            await this.joinConference()
        }

        const params = {
            child: this.activeCall?.parameters?.CallSid,
            parent: this.activeCallObj?.parent_call_sid,
            isForMe: isForMe ? "1" : "0",
            direction: this.activeCallObj?.isIncoming ? "inbound" : "outgoing",
        }

        const res = await NetworkManager.post(urlConstants.voice.hold, params).catch(() => { })
        if (res && res.holdState !== undefined) {
            store.dispatch(voiceActions.setVoiceState({ onHold: Boolean(res.holdState) }))
        }
        this.changingHoldState = false;
    }

    public async addToCall(to: any) {
        if (to && to.phone && this.activeCallObj?.parent_call_sid) {
            const call_sid = this.activeCallObj.parent_call_sid

            const callingFrom = store.getState().voice.callingFrom
            const params = {
                type: (to.type === "0" || to.type === 0) ? "user" : "number",
                number: to.phone,
                user_id: to.type == "0" && to.data,
                client_id: to.type == "3" && to.data,
                client: to.is_dialer ? to.data : 0,
                fullname: to.name,
                caller_id: callingFrom,
            }

            if (!this.activeCallObj?.on_conference) {
                await this.joinConference()
            }

            const res = await NetworkManager.post(`${urlConstants.voice.addToConference}${call_sid}/`, params).catch(() => { })

            if (res && res.call_sid) {
                trackEvent(WorkizEvents.UsedConferenceCall)
                store.dispatch(voiceActions.setVoiceState({ onConference: true, initiatedConf: true }))
                this.pendingConfParticipant = {
                    fullName: to.name,
                    participant_type: to.type,
                    participant_number: to.phone,
                    participant_name: to.name,
                    position: 100,
                    call_sid: res.call_sid,
                    call_status: "connecting",
                    phone: to.phone,
                    participant_sid: "",
                }
            }
        }
    }

    public async holdConferenceParticipant(_: ConferenceParticipant) {
        this.log("holding conference participant", _.participant_sid, _.participant_number)
        /** todo: handle participant loading */

        const params = {
            child: this.activeCall?.parameters?.CallSid,
            parent: this.activeCallObj?.parent_call_sid,
            isForMe: "1",
            direction: this.activeCallObj?.isIncoming ? "inbound" : "outgoing",
            participant_call_sid: _.call_sid,
        }

        const res = await NetworkManager.post(urlConstants.voice.hold, params).catch(() => { })

        //  this.fetchConfParticipants();
    }

    public async hangUpConferenceParticipant(_: ConferenceParticipant) {
        this.log("hanging up conference participant", _.participant_sid, _.participant_number)
        /** todo: handle participant loading */
        const call_sid = this.activeCallObj?.parent_call_sid
        if (!call_sid) return;
        NetworkManager.post(`${urlConstants.voice.removeRecipient}${call_sid}/`, { child: _.call_sid }).catch(console.warn)
    }

    //#endregion Actions


    //#region Call Data 

    public async getContactSuggestions(): Promise<Array<any>> {
        if (!this.activeCallObj) return [];
        if (this.callContactSuggestions) {
            return this.callContactSuggestions
        }
        if (this.activeCallObj.client_id || ["3", "0"].includes(String(this.activeCallObj.user_type))) {
            const res = await NetworkManager.post(urlConstants.data.getSmartSuggestions, {
                contact_id: this.activeCallObj.client_id,
                contact_name: this.activeCallObj.full_name,
                contact_type: this.activeCallObj.user_type,
            })
            if (Array.isArray(res?.data)) {
                this.callContactSuggestions = res.data;
                return res.data;
            }
        }

        return [];
    }

    //#endregion

    private log(message?: any, ...optionalParams: any[]) {
        console.log(moment().format("HH:mm:ss.ms"), "==== Twilio LOG ====", message, ...optionalParams)
    }

    public getNumberToCallFrom(phoneNumberToCallFrom: string) {
        const returnCallFromSameNumberFF = isEnabled(FeatureFlagsNames.return_call_from_number_received, false);
        if(phoneNumberToCallFrom && returnCallFromSameNumberFF){
            phoneNumberToCallFrom = clearNumber(phoneNumberToCallFrom);
            const userNumbers = store.getState().user.numbers;
            const index = userNumbers.findIndex(_ =>_.number.includes(phoneNumberToCallFrom));
            if(index!==-1){
                return userNumbers[index].number;
            }
        }   
        return null;
    }
}

export default TwilioManager;
