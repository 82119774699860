import ListRow from 'components/layout/ListRow'
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DialerRootReducer } from 'store/reducers/rootReducer'
import TabContainer from './TabContainer'
import * as stylesHelper from 'styles/stylesHelper'
import TwilioManager from 'helpers/TwilioManager'
import { CallHistoryItemPrepped } from 'constants/types'
import NoResults from 'components/layout/NoResults'
import { formatDate, phoneFormat } from 'helpers/StringFormats'
import urlConstants from 'constants/urlConstants'
import { goToURL } from 'helpers/WorkizHelper'
import User from 'helpers/User'
import Restrictions from 'constants/restrictions'
import CallHistoryFilter from 'components/layout/CallHistoryFilter';
import { getCallsHistory, getQueueName } from 'helpers/Data';
import Loader from 'components/layout/Loader';
import * as callsHistoryActions from 'store/actions/callsHistoryActions';
import Modal from 'components/layout/Modal'
import AddNew from '../ModalsContents/AddNew'
import { ContactSuggestion } from 'constants/types'
import WorkizLang from 'language/WorkizLang'
import * as dialerConstants from 'constants/dialerConstants';
import { WorkizEvents, trackEvent } from 'helpers/TrackActions'

function CallsHistory(props: { active: boolean, queueView?: boolean, setActiveCallModal: (item: CallHistoryItemPrepped | null) => void }) {

    const historyList = useSelector((state: DialerRootReducer) => state.callsHistory)
    const queueState = useSelector((state: DialerRootReducer) => state.callQueue)
    const [showMissedCalls, setShowMissedCalls] = useState(false);
    const [missedCalls, setMissedCalls] = useState<CallHistoryItemPrepped[]>([]);
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState("");
    const [selectedCall, setSelectedCall] = useState<CallHistoryItemPrepped>()
    const [callToObject, setCallToObject] = useState<ContactSuggestion | undefined>(undefined)

    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        setSelectedRow("")
        setSelectedCall(undefined)
        if(showMissedCalls) {
            const params = {
                filters: {
                    Status: ["Missed - Response needed"]
                }
            }
            getCallsHistory(params).then(list => {
                setLoading(false);
                setMissedCalls(list);
            })
        } else {
            getCallsHistory().then(list => {
                dispatch(callsHistoryActions.setCallsHistory(list));
                setLoading(false);
            });
        }
    }, [showMissedCalls])

    useEffect(() => {
        if (selectedCall) {
            const call = selectedCall
            const callTo = (call.direction === "inbound" ? call.fromNumber : call.toNumber) ?? ""
            setCallToObject({
                client_company_name: "",
                data: call.client_id ?? "",
                name: call.clientName ?? "",
                phone: callTo,
                phoneFormatted: phoneFormat(callTo, false, true),
                type: call.client_id ? "3" : "-1",
                client_id: call.client_id ?? "",
                client_contact_id: call.client_contact_id ?? "",
                email: ""
            })
        }
    }, [selectedCall])
    
      

    const getClientLink = (call: CallHistoryItemPrepped, isActionable: boolean) => {
        const trimmedCallerDesc = call.callerDesc.trim();
        const isClient = call.type === "3" && Number(call.client_id) > 0
        const desc = (isClient && trimmedCallerDesc) || call.callerNumber || trimmedCallerDesc;
        if (isActionable && User.get().isAllowed(Restrictions.Clients) && isClient) {
            return (
                <span className={'pointer hoverLink'}
                    onClick={(e) => {
                        goToURL(urlConstants.workizWeb.client + call.client_id, true)
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    {desc}
                </span>
            )
        } else if (User.get().shouldUseMasking()) {
            return (
                <span>{dialerConstants.maskingNumber}</span>
            )
        } else {
            return (
                <span>{desc}</span>
            )
        }
    }

    const getCallList = () => {
        const calls = showMissedCalls && !props.queueView ? missedCalls : historyList.calls;
        return buildCallList(calls);
    }

    const getActionButtons = (call: CallHistoryItemPrepped, isActionable: boolean) => {
        const buttons: Record<string, any> = {};
        if (isActionable) {
            if (!User.get().shouldUseMasking() && call.phone) {
                buttons.call = {
                    icon: "wfi-Phone",
                    action: (e :React.MouseEvent) => {
                         e.preventDefault();
                         e.stopPropagation();
                         TwilioManager.getInstance().placeCall(call.phone,callToObject)
                    }}
            }
           
            if (User.get().isAllowed(Restrictions.Messaging) && call.isClient && !(parseInt(call.client_contact_id)>0)) {
                buttons.message = {
                    icon: "wfi-message",
                    action: () => {
                      const messagingURL = `${urlConstants.workizWeb.message}${call?.client_id ?? ""}-${call?.client_id ? "3" : ""}`;
                      goToURL(messagingURL, true);
                    },
                  };
            }

            buttons.info = {
                icon: "wfi-Information-Circle",
                action: () => {
                    if(User.get().isAllowed(Restrictions.Voice)) {
                        props.setActiveCallModal(call);
                    }
                }}
        }
        return buttons;
    }
   
    const buildCallList = (calls: any[]) => {
        const queueJSX: Array< JSX.Element> = [];
        const historyJSX: Array< JSX.Element> = [];
        const holdJSX: Array< JSX.Element> = [];

        const callQueueObject: { [key: string]: any } = {}
        const openAddNew = (call:CallHistoryItemPrepped) => {
            setOpenModal(true)
            setSelectedCall(call)
            trackEvent(WorkizEvents.ClickedAddNewDialer)
        }

        const onClickRow = (call:CallHistoryItemPrepped) => {
            setSelectedRow(selectedRow === call.call_sid ? "" : call.call_sid ?? "")
            setSelectedCall(call)

        }
        calls.forEach((_) => {
            const design = stylesHelper.getCallStatusDesign(_);
            let isQueue = false;
            let isHold = false;
            let isUnknown = false;
            let rightTop = undefined;
            let leftTop = undefined;
            let rightSection = undefined;
            if (!_.client_id && User.get().isAllowed(Restrictions.AddJobs)) {
                isUnknown = true
                rightTop = (
                    <div className="flex-row pointer" onClick={(e) => {
                        e.stopPropagation(); 
                        e.preventDefault()
                        openAddNew(_);
                    }}>
                        <div className='flex-row center-content blue'>
                            <i className="wfi-circle-fill blue big-text"/>
                            <div className=' text-right-buttons'>{WorkizLang.get().workizWeb.addNew}</div> 
                        </div> 
                    </div>
                );
            }

            let queueName = "";
            if (queueState.allCallQCallsObject[_.call_sid]) {
                isQueue = true;
                queueName = getQueueName(User.get().userData?.acid, queueState.allCallQCallsObject[_.call_sid].user_id.toString(), queueState.allCallQCallsObject[_.call_sid].group_id.toString(), queueState.allCallQCallsObject[_.call_sid].flow_id.toString());
                const curCallQueueObject = callQueueObject[queueName] || {};
                curCallQueueObject[_.call_sid] = _;
                callQueueObject[queueName] = curCallQueueObject;
                const connectToQueueAction = (
                    <div className="flex-row pointer" onClick={(e) => {
                        e.stopPropagation(); 
                        TwilioManager.getInstance().connectQueue(_, queueState.allCallQCallsObject[_.call_sid])
                    }}>
                        <div className="flex-row mini-margin-left">
                        <div className="green-background list-right-buttons" ><i className="wfi-call white" /></div>
                        <div className= "main-black text-right-buttons">Connect</div>
                        </div>
                    </div>
                );
                
                if (isUnknown && !props.queueView) {
                    rightSection = <div className='right-section'> {rightTop} {connectToQueueAction} </div>
                    rightTop = undefined;
                } else { 
                    rightTop = connectToQueueAction 
                }
                if (!props.queueView) {
                    leftTop = <div className='green-background left-top-list white' >{WorkizLang.get().dialer.inQueue}</div>
                }
             
            }

            if (_.is_active === "2") {
                isHold = true
                const holdAction = (
                    <div className="flex-row pointer" onClick={(e) => {
                        e.stopPropagation(); 
                        TwilioManager.getInstance().connectToHeldCall(_)
                    }}>
                        <div className="flex-row mini-margin-left">
                        <div className="green-background list-right-buttons" ><i className="wfi-hold-fill white" /></div>
                        <div className= "main-black text-right-buttons">{WorkizLang.get().dialer.unHold}</div>
                        </div>
                    </div>
                );
                if (isUnknown) {
                    rightSection = <div className='right-section'> {rightTop} {holdAction} </div>
                    rightTop = undefined;
                } else { rightTop = holdAction }
                leftTop = <div className='left-top-list white' >{WorkizLang.get().dialer.onHold}</div>
            }
            const actionButtons = getActionButtons(_, !isHold && !isQueue);
            const JSX = (
                <ListRow
                    key={_.call_sid}
                    title={getClientLink(_, !isQueue && !isHold)}
                    iconName={!props.queueView?_.direction === "outgoing" ? design.outgoing : design.inbound:undefined}
                    leftBottom={formatDate(_.created_sql)}
                    rightTop={rightTop}
                    actionButtons={selectedRow === _.call_sid ? actionButtons : undefined}
                    onClick={() => onClickRow(_)}
                    leftTop={leftTop}
                    rightSection = {rightSection}
                />
            );

            if (isQueue){
                queueJSX.push(JSX);
            }
            else if (isHold) holdJSX.push(JSX)
            else {
                historyJSX.push(JSX);
            }
        });
        if (props.queueView) {
            if (!queueJSX.length) {
                return (
                    <NoResults title={WorkizLang.get().dialer.noCallQueue}/>
                )
            }
            return <div>{queueJSX}</div>
        }
        
        if((showMissedCalls && missedCalls.length === 0) || (!showMissedCalls && historyList.calls.length === 0)) {
            return (
                <NoResults title={WorkizLang.get().dialer.noHistory}/>
            )
        }

        return (
            <>
                <div className="header-list" >{WorkizLang.get().dialer.history}</div>
                <div style = {{marginLeft:50,marginRight:16}}className="border-bottom"></div>
                {queueJSX}
                {holdJSX}
                {historyJSX}
            </>
        );
    }

    if (props.queueView) {
        return getCallList()
    }

    return (
        <>
        <TabContainer active={props.active}>

            <div className="with-scroll margin-top" >
                <CallHistoryFilter 
                    showMissedCalls={showMissedCalls} 
                    setShowMissedCalls={(shouldShowMissed: boolean) => setShowMissedCalls(shouldShowMissed)} 
                />
                {
                    loading ? 
                    <div style={{ marginTop: 40 }}><Loader /></div>
                    : getCallList()
                }
            </div>

          
            
        </TabContainer>
          <Modal
          show={openModal}
          onClose={() => setOpenModal(false)}
          title={WorkizLang.get().workizWeb.addNew}
          >
          {selectedCall &&<AddNew call={selectedCall}/>}
        </Modal>
      </>
    )
}

export default CallsHistory
