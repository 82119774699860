import React, { useEffect, useRef, useState } from 'react'
import './form.scss'

interface SearchBarProps extends React.InputHTMLAttributes<HTMLInputElement> {
    inputExtraClass?: string,
    iconExtraClass?: string,
    className?: string,
    searchIcon?: string,
    initialValue?: string,
    searchTimeout?: number,
    onSearchChanged?: (term: string) => void,
    onTextChange?: (term: string) => string | void,
    isEraseIcon?:boolean
}

const DEF_SEARCH_TIMEOUT = 500;

function SearchBar(props: SearchBarProps) {

    const [term, setTerm] = useState(props.initialValue ?? "")
    const termRef = useRef(term);
    termRef.current = term;

    useEffect(() => {
        if (props.initialValue != term) {
            setTerm(props.initialValue || term)
        }
    }, [props.initialValue])

    const onChange = (e: string) => {
        let value = e;
        if (props.onTextChange) {
            const res = props.onTextChange(value);
            // the value gone through prepare in the parent component
            if (typeof res === "string") value = res;
        }
        // Value didn't change?
        if (value === termRef.current) return;
        setTerm(value);
   
        setTimeout(() => {
            if (value === termRef.current && props.onSearchChanged) {
                props.onSearchChanged(value);
            }
        }, props.searchTimeout || DEF_SEARCH_TIMEOUT);
      
    }

    return (
        <div className={`search-bar flex-row center-content  ${props.className || ""}`}>
            <input
                className={`flex-one ${props.inputExtraClass || ""}`}
                placeholder={props.placeholder}
                value={term}
                onChange={e => onChange(e.target.value)}
                autoFocus={props.autoFocus}
            />
            <i className={`${props.searchIcon} ${props.iconExtraClass??""}`} onClick={props.isEraseIcon?() => onChange(term.slice(0, -1)):undefined} />
        </div>
    )
}

export default SearchBar
