import config from './envConfig';
import io from 'socket.io-client';
import User from './User'

const connection = {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 10000,
    reconnectionDelayMax: 10000,
    secure: true,
    transports: ["websocket"],
    enabledTransports: ['ws', 'wss'] , 
    autoconnect: true
}

/** @type {any} */
let socket = null

export const closeSocket = () => {
    if (socket) {
        socket.disconnect();
    }
}

export const connectSocket = () => {
    if (socket) return socket;
    const socketQuery = User.get().socketParams
    const source = process.env.REACT_APP_WEB ? '' : '&source=desktopDialer';
    connection.query = `env=${socketQuery.env}&repo=dialer.electron&s=${socketQuery.s}&a=${socketQuery.a}&token=${socketQuery.token}&uid=${socketQuery.uid}${source}`
    socket = io(socketQuery.api, connection);
    return socket;
}

export default socket;