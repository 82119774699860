
import config from './envConfig';
import NetworkManager from './NetworkManager';
import WorkizLang from 'language/WorkizLang';
import EventsAdapter from './EventsAdapter'
import  { ActiveCall } from 'constants/storeConstants';

type Contact = "user" | "external" | "unknown" | "client" | "workiz"

export const contactTypes: { [key: string]: Contact } = {
    "0": "user",
    "1": "external",
    "2": "unknown",
    "3": "client",
    "4": "workiz",
}

export const getContactTypeDesc = (type: string | number): string => {
    if (typeof type !== "string") {
        type = type.toString();
    }
    let key: Contact = "unknown";
    if (contactTypes[type]) {
        key = contactTypes[type]
    }
    return WorkizLang.get().userTypes[key]
}
export const createForm = (values: { [key: string]: any }, url: string) => {
    const query = NetworkManager.serializeObjectToQuery(values);
    const submitTo = NetworkManager.buildBaseUrl(url);
    goToURL(submitTo + "?" + query, false)
}

export const setTimeoutAsync = (ms: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export const goToURL = (url: string, useBase: boolean) => {
    const preparedUrl = useBase ? NetworkManager.buildBaseUrl(url) : url;
    EventsAdapter.openExternalLink(preparedUrl)
}

export const isWorkizNumber = (number: string): boolean => {
    /** todo */
    return false
}

export const isUnknownContact = (activeCall: ActiveCall | undefined): boolean => {
    return !activeCall?.full_name || activeCall?.full_name === WorkizLang.get().data.unknownName
}
