enum Restrictions {
    AccountSettings = "AccountSettings",
    AddJobs = "AddJobs",
    CalendarSync = "CalendarSync",
    CancelJobs = "CancelJobs",
    ChangeJobType = "ChangeJobType",
    ChargeCreditCards = "ChargeCreditCards",
    ClientMessaging = "ClientMessaging",
    Clients = "Clients",
    CloseJobs = "CloseJobs",
    FinancialData = "FinancialData",
    FranchiseSettings = "FranchiseSettings",
    Franchises = "Franchises",
    Inventory = "Inventory",
    JobSettings = "JobSettings",
    LeadSettings = "LeadSettings",
    Leads = "Leads",
    Messaging = "Messaging",
    Notifications = "Notifications",
    OverrideRates = "OverrideRates",
    SettleJobs = "SettleJobs",
    TechnicianView = "TechnicianView",
    UncloseJob = "UncloseJob",
    UpdateJobCost = "UpdateJobCost",
    UserSettings = "UserSettings",
    ViewDashboardStatistics = "ViewDashboardStatistics",
    ViewJobHistory = "ViewJobHistory",
    Voice = "Voice",
    VoiceSettings = "VoiceSettings",
    None = "",
}

export default Restrictions;
