import CallButton from 'components/dialer/CallButton'
import { phoneFormat } from 'helpers/StringFormats'
import TwilioManager from 'helpers/TwilioManager'
import WorkizLang from 'language/WorkizLang'
import React from 'react'
import { useSelector } from 'react-redux'
import { DialerRootReducer } from 'store/reducers/rootReducer'
import { isUnknownContact } from 'helpers/WorkizHelper'

import './activeCall.scss';

function IncomingCall() {

    const voiceState = useSelector((state: DialerRootReducer) => state.voice)
    const isUnknown = isUnknownContact(voiceState.activeCall)
    const phoneFormatted = phoneFormat(voiceState.activeCall?.phone, false, true)
    const callerDesc = isUnknown ? phoneFormatted : voiceState.activeCall?.full_name 

    return (
        <div className="flex-one flex-column white">
            <div className="flex-one center-text big-margin-top" >
                <div className="incoming-call-header">{WorkizLang.get().dialer.incomingCall}</div>
                <div className="caller-name">{callerDesc}</div>
                {!isUnknown && <div className="incoming-call-header">{phoneFormatted}</div>}
                {voiceState.activeCall?.client_contact_id && voiceState.activeCall?.parent_client_name && 
                <div className='additional-client-info'>
                <div>{WorkizLang.get().workizWeb.additionalContactOf}</div>
                <div className='additional-client-info-bold'>{voiceState.activeCall?.parent_client_name}</div>
                </div>}
                <div className="incoming-call-info">
                {voiceState.activeCall?.flow && <div className='info-row'><span className="bold">{WorkizLang.get().workizWeb.flow}:</span>&nbsp;&nbsp;{voiceState.activeCall?.flow}</div>}
                {voiceState.activeCall?.ad_group_name && <div className="info-row"><span className="bold">{WorkizLang.get().workizWeb.adGroup}:</span>&nbsp;&nbsp;{voiceState.activeCall?.ad_group_name}</div>}
                </div>
            </div>
            <div className="buttons-container center-content incoming-buttons">
                <CallButton extraClass="margin-right-button" isHangup={false} onClick={() => TwilioManager.getInstance().connectIncoming()} />
                <CallButton isHangup={true} onClick={() => TwilioManager.getInstance().rejectIncoming()} />
            </div>
        </div>
    )
}

export default IncomingCall
