import React from 'react'
import './loader.scss'

function Loader(props: { inverted?: boolean, small?: boolean, whiteDots?: boolean }) {
    return (
        <div className={`spinnerDots ${props.small ? "small" : ""} ${(props.inverted ? "inverted" : "")} ${(props.whiteDots ? "whiteDots" : "")}`} >
            <div className="bounce1" ></div>
            <div className="bounce2" ></div>
            <div className="bounce3" ></div>
        </div>
    )
}

export default Loader
