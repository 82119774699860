import Restrictions from 'constants/restrictions'
import Addons from 'constants/addons'
import { NumericBoolean } from 'constants/types'

interface SocketParams {
    s: string, a: string, token: string, env: string, api: string, uid: string
}


type UserRestrictions = Partial<Record<Restrictions, boolean>>
type UserAddons = Partial<Record<Addons, boolean>>
type AddonsBackendType = Partial<Record<Addons, NumericBoolean>>

export interface UserData {
    socket_params: SocketParams,
    workiz_number: string,
    masking_number: string,
    restrictions: UserRestrictions,
    acid?: string,
    account_name?: string,
    expired?: boolean,
    isPendingSignup: NumericBoolean,
    user?: {
        id: string,
        name: string,
        masking_active: boolean,
        email: string,
        is_master: string,
        trial: number,
        phone: string,
    },
    blockLoginStatus?: {
        blockLogin: boolean,
        reason: string,
        actionTitle: string,
        actionURL: string
    },
    [key: string]: any
}

export default class User {

    private constructor() { }

    private static instance?: User

    public static get(): User {
        if (!User.instance) {
            User.instance = new User();
        }
        return User.instance;
    }

    public static clearInstance() {
        User.instance = undefined;
    }

    socketParams: SocketParams = { s: "", a: "", token: "", env: "", api: "", uid: "" }
    userData?: UserData
    queueGroups: Array<string> = ["-1"]
    restrictions: UserRestrictions = {}
    addons: AddonsBackendType = {}

    setUserData = (userData: UserData) => {
        this.socketParams = userData.socket_params
        this.userData = userData
        if (userData.restrictions) {
            this.setUserRestrictions(userData.restrictions)
        }
        if (userData.addons) {
            this.addons = userData.addons
        }
    }

    setQueueGroups = (data: Array<string>) => {
        this.queueGroups = data
        this.queueGroups.push("-1")
    }

    setUserRestrictions = (data: UserRestrictions) => {
        this.restrictions = data
    }

    setUserAddons = (data: AddonsBackendType) => {
        this.addons = data
    }

    isAddonOn = (key: Addons) => {
        return this.addons[key] == 1
    }

    isAllowed = (key: Restrictions) => {
        return this.restrictions[key] !== true
    }

    shouldUseMasking = (): boolean => {
        return this.userData?.user?.masking_active ?? false
    }

}