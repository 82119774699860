import NetworkManager from './NetworkManager';
import urlConstants from 'constants/urlConstants'
import moment from 'moment';
import { CallHistoryItemPrepped, CallsHistoryItem, ClientDetails, ContactSuggestion, PhoneNumber, user_types } from 'constants/types';
import User, { UserData } from './User'
import { phoneFormat } from './StringFormats';
import * as tracking from 'helpers/TrackActions'

export const prepareHistoryItem = (item: CallsHistoryItem): CallHistoryItemPrepped => {
    let callerName = item.direction == "outgoing" ? item.to : item.from;
    const callerNumber = (item.direction == "outgoing" ? item.toNumber : item.fromNumber) ?? ""
    if (callerNumber == callerName) callerName = "Unknown";
    const formattedNumber = phoneFormat(callerNumber, false, true);
    const callerDesc = callerName || "Unknown";

    let userType: user_types = "-1";
    if (item.direction == "outgoing" && item.uid_answered) {
        userType = "0";
    } else if (item.direction == "inbound" && Number(item.uid_called) > 0) {
        userType = "0";
    } else if (Number(item.client_id) > 0) {
        userType = "3";
    }

    let recordings = [
        ...(item.recording_url ? [item.recording_url] : []),
        ...(item.conf_recordings ?? [])
    ]

    recordings = recordings.map(_ => _.replace("http://", "https://"))

    return {
        callerName,
        callerNumber,
        callerDesc,
        formattedNumber,
        name: callerDesc,
        isClient: userType == "3",
        phone: callerNumber,
        type: userType,
        data: item.client_id,
        recordings,
        ...item,
    }
}

export const getCallsHistory = async (customParams = {}): Promise<Array<CallHistoryItemPrepped>> => {

    const final_q = moment().subtract(100, "week").format("DD.MM.YY") + "_" + moment().format("DD.MM.YY");
    const params = {
        final_q,
        showBlocked: false,
        pageSize: 50,
        page: 0,
        react: true,
        sorted: [{
            id: "created", desc: true,
        }],
        filters: {}
    }

    const res = await NetworkManager.post(urlConstants.data.callsHistory, {...params, ...customParams}).catch(console.log);
    if (res && Array.isArray(res.data)) {
        const data = res.data.map(prepareHistoryItem)
        return data;
    }
    return [];
}

export const getPhoneNumbers = async (): Promise<Array<PhoneNumber>> => {
    let numbers: Array<PhoneNumber> = [];
    const res = await NetworkManager.post(urlConstants.voiceSettings.getNumbers, {}).catch(() => { })
    console.log('Raw response:', res);
    console.log('Response data type:', typeof res?.data);
    console.log('Is data array?', Array.isArray(res?.data));
    if (res && Array.isArray(res.data)) {
        numbers = res.data;
        numbers.forEach(_ => {
            console.log('Number:', _);
            _.value = _.number;
            _.label = phoneFormat(_.number);
        });
        console.log('Numbers:', numbers);
    }
    return numbers;
}
export const getContacts = async (search_str: string, include_secondry_phone = false): Promise<Array<ContactSuggestion>> => {
    const res = await NetworkManager.post(urlConstants.data.getContacts, { search_str, include_secondry_phone}).catch(console.warn)
    if (res && Array.isArray(res.suggestions)) {
        return res.suggestions;
    }
    return [];
}

export const getClients = async (search_str: string, page = 0): Promise<Array<ClientDetails>> => {
    const res = await NetworkManager.post(urlConstants.data.getClients, { search_str, page: String(page) }).catch(console.warn)
    if (res && Array.isArray(res.data)) {
        return res.data;
    }
    return [];
}
export const searchContacts = async (search_str: string, include_secondry_phone = false): Promise<Array<ContactSuggestion>> => {
    const res = await NetworkManager.post(urlConstants.data.getContactsOnSearch, { search_str, include_secondry_phone }).catch(console.warn)
    if (res && Array.isArray(res.data)) {
        return res.data;
    }
    return [];

}

export const getUserData = async (): Promise<boolean> => {
    const userData = await NetworkManager.post(urlConstants.user.getUserData, {}).catch(console.warn);
    if (userData?.data) {
        const user: UserData = userData.data;
        User.get().setUserData(user)
        tracking.identify()
        tracking.trackEvent(tracking.WorkizEvents.MountElectronDialer)
        if (!user.blockLoginStatus?.blockLogin) {
            return true;
        }
    }
    return false;
}

export const getBadgeCounter = async (): Promise<number> => {
    const res = await NetworkManager.post(urlConstants.data.getBadgeIconCounter, {}).catch(console.log);
    if (res) {
        return res.data
    }

    return 0;
}

export const getQueueName = (accountId: string | undefined, userId: string | number, groupId: string | number, flowId: string | number): string => {
    const recipientId = parseInt(userId.toString()) > 0 ? userId : groupId;
    const type = parseInt(userId.toString()) > 0 ? "_u_" : "_";
    return "call_queue_"+`${accountId}`+`${type}`+`${recipientId}_${flowId}`;

}