
import { CallHistoryItemPrepped, CallQueueItem, CallsHistoryItem, ConferenceParticipant, ConferenceSocketData, ContactSuggestion, ParsedCallQueue } from 'constants/types';
import moment from 'moment';
import { clearNumber, phoneFormat } from './StringFormats'
import TwilioManager from './TwilioManager';
import User from './User';
import CryptoJS from 'crypto-js'
import { prepareHistoryItem } from './Data';
import { ActiveCall } from 'constants/storeConstants';
import { getQueueName } from './Data';

const callMaskingFlow = "ClientPhoneMasking"

export const tryParseJSON = (json: string, def: any = null): any => {
    if (!json) return def;
    let parsed: any = def
    try {
        parsed = JSON.parse(json)
    } catch (error) {
        console.log("parsing json failed", json)
    }
    return parsed
}

export const parseCallQueue = (data: string): { arr: Array<CallQueueItem>, obj: ParsedCallQueue, queueName: string } => {
    const obj: ParsedCallQueue = {};
    const arr: Array<CallQueueItem> = [];
    let queueName = "";
    const queue_data = tryParseJSON(data)
    if (data) {
        const extras = tryParseJSON(queue_data?.extra_params, {});
        queueName = getQueueName(User.get().userData?.acid, extras?.user_id, extras?.group_id, extras?.flow_id);
        if (Array.isArray(queue_data?.members)) {
            queue_data.members.forEach((item: { [key: string]: string }) => {
                if (item.call_sid) {
                    const member = {
                        call_sid: item.call_sid ?? "",
                        position: item.position ?? "",
                        from: item.from ?? "",
                        base_url: extras?.base_url ?? "",
                        group_id: extras.group_id ?? "-1",
                        user_id: extras.user_id ?? "-1",
                        date_enqueued: moment(item.date_enqueued),
                        is_recording: extras.is_recording ?? "",
                        flow_id: extras?.flow_id ?? "",
                        queue_sid: item.queue_sid ?? ""
                    }
                    obj[item.call_sid] = member;
                    arr.push(member);
                }
            });
        }
    }
    return { obj: obj, arr: arr, queueName };
}

export const parseCallFromActiveCall = (data: ActiveCall): CallHistoryItemPrepped => {
    const numberFormatter = phoneFormat(data.phone ?? "", false, true)
    const callSid = data?.newCallQ ? data?.parent_call_sid : data?.call_sid;
    return {
        callerDesc: (data.full_name ?? "").trim(),
        callerNumber: (data.phone ?? "").trim(),
        formattedNumber: numberFormatter,
        isClient: data.user_type == 3,
        name: (data.full_name ?? "").trim(),
        ad_group_id: data.ad_group_id,
        phone: data.phone ?? "",
        recordings: [],
        call_sid: callSid ?? "",
        user_type: data.user_type,
        clientId: data.client_id,
    }
}


export const parseCallFromSocket = (data: string): CallHistoryItemPrepped => {
    let call: CallsHistoryItem = {};
    const parsedData = tryParseJSON(data);
    if (parsedData && parsedData.call_sid && parsedData.from) {

        call = parsedData;

        //Call masking override
        if (call['flow_name'] == callMaskingFlow) {
            call["direction"] = "outgoing";
            call["to"] = call["childTo"];
        }

        call["toNumber"] = phoneFormat(call['to']);
        call["fromNumber"] = phoneFormat(call['from']);

        //Inboud
        if (call["direction"] == "inbound") {
            call["clientNumber"] = phoneFormat(call["from"]);
            call["userNumber"] = phoneFormat(call["to"]);
            call["from"] = call["client_id"] != null ? call['client_name'] : call["uid_called"] != null ? call["user_name"] : phoneFormat(call["from"]);
            call["to"] = phoneFormat(call['to']);
            call["clientName"] = call["from"];
        }
        //Outbound
        else {
            call["clientNumber"] = phoneFormat(call["to"]);
            call["userNumber"] = phoneFormat(call["from"]);
            call["from"] = call["uid_called"] != null ? call['user_name'] : "";
            call["to"] = call["client_id"] != null ? call['client_name'] : phoneFormat(call["to"]);
            call["clientName"] = call["to"];
        }

        //If we could not find client, use number
        if (call["from"] == "") call["from"] = call["fromNumber"];

        //Override private number
        if (call["from"] == "266696687") {
            call["from"] = "Private";
        }

        //Update status for rejected
        if ((call["rejected"] ?? 0) == 1) {
            call["dial_call_status"] = "rejected";
        }

        call["created_sql"] = call['created'];
        const created = moment(call['created']);
        call["created"] = created.format("ddd MMM Do, hh:mmA");
        call["dayTitle"] = created.format("MMMM Do, YYYY");
        call["timeTitle"] = created.format("h:mm a");

        call["clientId"] = call['client_id'];
        call["uid_answered"] = call["uid_answered"] != null ? call['user_name_answered'] : "";
        // TODO: job number method
        // call["job"] = SAJhelper.getGlobal("jobNumberMethod") == "1" ? call['uuid'] : call['job_serial'];

        //Duration
        call['call_duration_int'] = call['call_duration'];
        const minutes = Math.floor(call['call_duration'] / 60);
        const seconds = call['call_duration'] - minutes * 60;
        call["call_duration"] = (minutes > 0 ? (minutes + " Min ") : "") + seconds + " Sec";

    }

    return prepareHistoryItem(call);
}

const getInitiateConfParticipants = (arr: Array<ConferenceParticipant>, parent_call_sid: string): { [key: string]: ConferenceParticipant } => {
    const activeCall = TwilioManager.getInstance().getActiveCallObj()
    let parentCall: any = {}
    let childCall: any = {}
    if (arr[0]?.call_sid === parent_call_sid) {
        parentCall = arr[0]
        childCall = arr[1]
    } else {
        parentCall = arr[1]
        childCall = arr[0]
    }

    const myCall: ConferenceParticipant = {
        isMe: true,
        fullName: User.get().userData?.user?.name ?? "",
        ...(activeCall.isIncoming ? childCall : parentCall)
    }

    const firstParticipant: ConferenceParticipant = {
        phone: activeCall.phone,
        fullName: activeCall.full_name,
        ...(activeCall.isIncoming ? parentCall : childCall)
    }

    return {
        [myCall.call_sid!]: myCall,
        [firstParticipant.call_sid!]: firstParticipant
    }
}

export const parseConferenceDetails = (data: string, originalParticipants?: { [key: string]: ConferenceParticipant }): { counter?: number, participants?: { [key: string]: ConferenceParticipant } } => {
    const tw = TwilioManager.getInstance();
    const obj: ConferenceSocketData = tryParseJSON(data)
    const call_sid = tw.getActiveParentCallSid()
    let counter = 0;
    if (obj && call_sid && obj.call_sid == call_sid && Array.isArray(obj.active_participants)) {
        let participants = originalParticipants
        if (!participants && obj.active_participants.length) {
            participants = getInitiateConfParticipants(obj.active_participants, call_sid)
        }
        if (!participants) return {}

        if (Array.isArray(obj.conference_data)) {
            obj.conference_data.forEach((item: any) => {
                const isActive = (item.participant_sid && ["participant-join", "connecting"].includes(item.call_status));
                participants![item.participant_sid] = {
                    ...(participants![item.participant_sid] ?? {}),
                    ...item,
                    position: +item.id,
                    isMe: item.user_id == User.get().userData?.user?.id,
                    isActive
                }
            });
        }

        obj.active_participants.forEach(_ => {
            if (participants![_.call_sid!]) {
                participants![_.call_sid!] = {
                    ...participants![_.call_sid!],
                    ..._,
                }
            } else if (tw.pendingConfParticipant && tw.pendingConfParticipant.call_sid === _.call_sid) {
                participants![_.call_sid!] = {
                    ...tw.pendingConfParticipant,
                    ..._
                }
            } else {
                console.log("unknown call sid received in socket", _.call_sid, _)
            }
            if (participants![_.call_sid!]?.isActive) {
                counter++;
            }
        })

        return { counter, participants }
    }
    return {}
}

const encryptionText = 'jbh9rtan2fti0yesy6tru2gs4kntuYse';
const encryptionVi = '8901234561234567';

export const decryptCallFromBrowser = (text: string) => {

    const bytes = CryptoJS.AES.decrypt(text, "myworkizlovelysecret")
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    return parseCallFromBrowser(decryptedData);
}

export const parseCallFromBrowser = (callObj: any) => {
    if (callObj?.extraParams?.fromQ) {
        return {
            fromQ: true,
            call_sid: callObj.extraParams.call_sid,
        }
    }
    if (callObj?.extraParams?.unhold) {
        return {
            fromHold: true,
            call_sid: callObj.extraParams.call_sid
        }
    }
    if (callObj && callObj.phoneNumber) {
        const obj: ContactSuggestion = {
            client_company_name: "",
            data: callObj?.extraParams?.client_id ?? "",
            name: callObj?.extraParams?.displayName ?? "",
            phone: clearNumber(callObj.phoneNumber),
            phoneFormatted: phoneFormat(callObj.phoneNumber),
            type: callObj?.extraParams?.client_id ? "3" : "-1",
            client_id: callObj?.extraParams?.client_id ?? "",
            email: callObj?.extraParams?.email ?? "",
            job_id: callObj?.extraParams?.job_id ?? "",
            client_contact_id: callObj?.extraParams?.client_contact_id ?? "",
            parent_client_name:  callObj?.extraParams?.parent_contact_name ?? "",
            phone_number_to_call_from: callObj?.extraParams?.phoneNumberToCallFrom ?? ""

        }
        return obj
    }


    return callObj
}

export const encryptCall = (data: any) => {
    // const text = JSON.stringify(data)
    // const cipher = crypto.createCipheriv('aes-256-cbc', encryptionText, encryptionVi)
    // let encryptedVal = cipher.update(text, "utf-8", "hex")
    // encryptedVal += cipher.final("hex")
    // console.log("=====ENCRYPTION RESULT ==== ")
    // console.log(encryptedVal)
    // console.log("=====END ENCRYPTION=====");
    // return encryptedVal
}