import React, { useState } from 'react'
import CallAction from './CallAction'
import TwilioManager from 'helpers/TwilioManager'
import CallButton from 'components/dialer/CallButton'
import WorkizLang from 'language/WorkizLang'
import { useSelector } from 'react-redux'
import { DialerRootReducer } from 'store/reducers/rootReducer'
import Modal from 'components/layout/Modal'
import Keypad from 'components/dialer/Keypad'
import ContactSuggestions from '../ModalsContents/ContactSuggestions'
import { ConferenceParticipant } from 'constants/types'
import ListRow from 'components/layout/ListRow'
import Timer from 'components/dialer/Timer'
import moment from 'moment'
import { phoneFormat } from 'helpers/StringFormats'
import Loader from 'components/layout/Loader'
import ActionButtons from 'components/layout/ActionButtons'

function ConferenceCall() {
    const lang = WorkizLang.get().dialer;
    const voiceState = useSelector((state: DialerRootReducer) => state.voice)
    const [activeModal, setActiveModal] = useState(false)
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false)
    const [keypadOpen, setKeypadOpen] = useState(false)

    const callActionsConfig = [
        { title: voiceState.muted ? lang.unmute : lang.mute, iconName: voiceState.muted ? "wfi-Microphone-Off" : "wfi-Microphone", onClick: () => TwilioManager.getInstance().mute() },
        { title: lang.keypad, iconName: "wfi-Dial-Pad", onClick: () => setKeypadOpen(true) },
        { title: lang.addToCall, iconName: "wfi-add-contact", onClick: () => setShowSuggestions(true) },
    ];

    const getActionButtons = () => {
        const buttons: Record<string, any> = {};
        if (voiceState.activeCall?.participantsCounter) {
            buttons.conferenceCall = {
                text: "Show participants",
                action: () => {
                    setActiveModal(true) 
                }
            }
        }
        return buttons
    };
    
    const contactSelectedAction = (contact: any) => {
        setShowSuggestions(false)
        TwilioManager.getInstance().addToCall(contact)
    }

    const renderParticipantActions = (item: ConferenceParticipant) => {
        if (item.isMe || !voiceState.initiatedConf) {
            return ""
        }
        if (item.status == "loading") {
            return (
                <Loader small />
            )
        }
        return (
            <div className={"flex-row"}>
                <CallButton
                    onClick={() => TwilioManager.getInstance().hangUpConferenceParticipant(item)}
                    extraClass="margin-right"
                    small
                    isHangup
                />
                <CallButton
                    onClick={() => TwilioManager.getInstance().holdConferenceParticipant(item)}
                    iconClassName="wfi-hold-fill"
                    dark
                    extraClass={item.is_holding ? "active-action" : ""}
                    small
                />
            </div>
        )
    }
    const renderActionBox = () => {
        return (
            <div className={`actionsRow ${keypadOpen ? '': 'conference-actions'}`}>
                {keypadOpen ? (
                    <>
                    <Keypad onClick={(digit) => TwilioManager.getInstance().sendDigit(digit)} />
                    <div className="hideButton" onClick={() => setKeypadOpen(false)}>
                    <i className="wfi-up" />{WorkizLang.get().dialer.hide}
                    </div>
                    </>
                ) : (
                    <>
                    {callActionsConfig.map((action, index) => (
                            <CallAction key={index} title={action.title} iconName={action.iconName} onClick={action.onClick} />
                    ))}
                    <div className="buttons-container center-content">
                    <CallButton isHangup={true} onClick={() => TwilioManager.getInstance().disconnect()} />
                    </div>
                    </>
                )}
            </div>
        );
    }

    const renderConferenceParticipants = () => {
        const JSX: any = []
        if (voiceState.activeCall?.conferenceParticipants) {
            Object.values(voiceState.activeCall.conferenceParticipants).forEach((_: ConferenceParticipant) => {
                if (!_.isActive) return;
                const phoneNumber = phoneFormat(_.participant_number, false, true)
                const fullName = (_.participant_name || "").trim() || phoneNumber
                const trail = _.isMe ? " (me)" : ""
                const title = fullName + trail
                const desc = <span className={"small"}>{fullName == phoneNumber ? "Unknown" : phoneNumber}</span>
                const rightTop = (
                    <div className="flex-column">
                        <div>{title}</div>
                        <div className="margin-top">{desc}</div>
                    </div>
                )
                
                JSX[_.position ?? 100] = (
                    <ListRow
                        key={_.participant_sid}
                        title={rightTop}
                        rightTop={renderParticipantActions(_)}
                    />
                )
            })
        }
        return JSX;
    }

    return (
        <>
             <div className="center-content flex-column" >
                <div className="flex-column margin-all  center-text">
                    <div className={"flex-one  font-16 text-ellipsis"}>
                        <div className="conference-header">{WorkizLang.get().dialer.conference}</div>
                        <div className="timer">
                        <Timer active={voiceState.onCall ?? false} startingTime={voiceState.activeCall?.call_time ?? moment()} />
                        </div>
                    </div>
                </div>
                {<div className='action-buttons-container'>
                    <ActionButtons extraClass='active-call' buttons = {getActionButtons()}/>
                </div>}
                {renderActionBox()}
            </div>
            {
                !voiceState.activeCall?.isIncoming || 
                typeof(voiceState.activeCall?.is_recording) === 'string' && voiceState.activeCall?.is_recording === '1' ?
                <div className='recordingLogo'>
                    <div className='logo'></div>
                    <span>{WorkizLang.get().dialer.recordingOn}</span>
                </div>
                : null
            }

            <Modal title={WorkizLang.get().dialer.conference} scrollAble={false} onClose={() => setActiveModal(false)} show={activeModal}>
                {renderConferenceParticipants()}
            </Modal>
            <ContactSuggestions
                isOpen={showSuggestions}
                onSelect={contactSelectedAction}
                title={"Add To Call"}
                onClose={() => setShowSuggestions(false)}
                allowWorkizNumber={false}
            />
        </>
    )
}

export default ConferenceCall
