import React, {useState} from 'react';
import WorkizLang from 'language/WorkizLang';
import style from "./callHistoryFilter.module.scss";
import ButtonRadio from '../form/ButtonRadio'
import { WorkizEvents, trackEvent } from 'helpers/TrackActions';

interface CallHistoryFilterProp {
    showMissedCalls: boolean,
    setShowMissedCalls: (shouldShowMissed: boolean) => void
}

function CallHistoryFilter(props: CallHistoryFilterProp) {
    const CALL_FILTER_OPTION = [
        { value: "all", label: "All" },
        { value: "missed", label: "Missed" },
    ]

    const onClickFilter = () => {
        props.setShowMissedCalls(!props.showMissedCalls)
        trackEvent(props.showMissedCalls ? WorkizEvents.ClickedAllCallsDialer : WorkizEvents.clickedMissedCallsDialer)

    }
    return (
    <div className="padding-screen">
        <div className={style.marginFilter}>
            <ButtonRadio
                    onChange={() => onClickFilter()}
                    options={CALL_FILTER_OPTION}
                    value={props.showMissedCalls?"missed":"all"}
                    />
        
        </div>
     </div>
    )
}

export default CallHistoryFilter;