import amplitude from "amplitude-js";
import EventsAdapter from "./EventsAdapter";
import User from "./User";

export enum WorkizEvents {
    SignedUp = "signedUp",
    SignedUpComplete = "signedUpComplete",
    ClickedGettingStartedItem = "clickedGettingStartedItem",
    AddedJob = "addedJob",
    AddedLead = "addedLead",
    Converted = "converted",
    ConvertedSelf = "convertedSelf",
    AddedUser = "addedUser",
    AddedStubInvoice = "addedStubInvoice",
    AddedStubEstimate = "addedStubEstimate",
    AddedJobEstimate = "addedJobEstimate",
    AddedJobInvoice = "addedJobInvoice",
    AddedPayment = "addedPayment",
    SentInvoice = "sentInvoice",
    SentBulkInvoice = "sentBulkInvoice",
    SentEstimate = "sentEstimate",
    CreatedNewLineItem = "createdNewLineItem",
    AddedLineItem = "addedLineItem",
    AddedLineItemGroup = "addedLineItemGroup",
    ViewedPricingOnTrial = "viewedPricingOnTrial",
    AnsweredCall = "answeredCall",
    MadeCall = "madeCall",
    TurnOnAddon = "turnOnAddon",
    GotWorkizNumber = "gotWorkizNumber",
    CallsOnboardingStep = "callsOnboardingStep",
    CreateCustomField = "createCustomField",
    CreateJobType = "createJobType",
    SentMessage = "sentMessage",
    EnabledWorkizPay = "enabledWorkizPay",
    ChangedJobStatus = "changedJobStatus",
    ChangedLeadStatus = "changedLeadStatus",
    AddedTag = "addedTag",
    CreatedNewTag = "createdNewTag",
    ClickToCall = "clickToCall",
    ConnectedToQBO = "connectedToQBO",
    OpenedJobRightPane = "openedJobRightPane",
    ViewJobAvailability = "viewJobAvailability",
    TurnedOnQBAutoInvoice = "turnedOnQBAutoInvoice",
    ViewedTechRouteOnSchedule = "viewedTechRouteOnSchedule",
    PlayedVideo = "playedVideo",
    EndVideo = "endVideo",
    ClickedGuide = "clickedGuide",
    EnabledAutoInvoicing = "enabledAutoInvoicing",
    AttachedFile = "attachedFile",
    ClickedClockOutNotification = "clickedClockOutNotification",
    ElectronDialerLogin = "loginToElectronDialer",
    MountElectronDialer = "mountElectronDialer",
    ChangedDialerAvailability = "changedDialerAvailability",
    LoggedOut = "loggedOut",
    RejectedACall = "rejectedACall",
    loadTwilioDevice = "loadTwilioDevice",
    ConnectedToHeldCall = "connectedToHeldCall",
    TransferredCall = "transferredCall",
    PutCallOnHold = "putCallOnHold",
    UsedConferenceCall = "usedConferenceCall",
    LoggedInWithSocial = "loggedInWithSocial",
    RecordingSent = "recordingSent",
    PlayedRecording = "playedRecording",
    ClickedRecentsDialer = "clickedRecentsDialer",
    ClickedContactsDialer = "clickedContactsDialer",
    ClickedKeypadDialer = "clickedKeypadDialer",
    ClickedAddNewDialer = "clickedAddNewDialer",
    ClickedCallQueueDialer = "clickedCallQueueDialer",
    ClickedSetAvailabilityDialer = "clickedSetAvailabilityDialer",
    ClickedAllCallsDialer = "clickedAllCallsDialer",
    clickedMissedCallsDialer = "clickedMissedCallsDialer",
    fromSocketCallQueue = "fromSocketCallQueue",
}

/**
 * Initialize amplitude tracking.
 * Run once on app first load
 */
export const initTracking = () => {
    amplitude.getInstance().init("6bc83c6e638777a30428eb63b4a5068f");
}

/**
 * Set the user identity for amplitude.
 * Need to run this function after getting user data
 */

const getPlatform = () => {
    return process.env.REACT_APP_WEB ? "electronDialerWeb" : "electronDialer";
}

export const identify = () => {
    try {
        amplitude.getInstance().setUserId(User.get().userData?.user?.id ?? "");
        amplitude.getInstance().setUserProperties({
            name: User.get().userData?.user?.name ?? "",
            email: User.get().userData?.user?.email ?? "",
            is_master: User.get().userData?.user,
            account_id: User.get().userData?.acid ?? "",
            is_trial: User.get().userData?.user?.trial ?? "",
            platform: getPlatform(),
            env: (process.env.REACT_APP_BUILD_ENV || process.env.NODE_ENV),
            version: EventsAdapter.getVersion()
        });
        amplitude.getInstance().setVersionName(EventsAdapter.getVersion())
        amplitude.getInstance().setGroup("account", User.get().userData?.acid ?? "");
        const groupIdentify = new amplitude.Identify()
        groupIdentify.set("name", User.get().userData?.account_name ?? "");
        groupIdentify.set("is_trial", User.get().userData?.user?.trial ?? "");
        amplitude.getInstance().groupIdentify("account", User.get().userData?.acid ?? "", groupIdentify);
    } catch (error) {
        console.warn("amplitude identity error", error)
    }
}

/**
 * This send specific event to amplitude with the user details set beforehand
 * @param event The action the user did
 * @param params Any additional data that may need to be tracked
 */
export const trackEvent = (event: WorkizEvents, params: Record<string, any> = {}) => {
    try {
        params.onTrial = User.get().userData?.user?.trial ? 1 : 0;
        params.phonePlatform = getPlatform();
        // params.env = process.env.NODE_ENV;
        // params.electronVersion = getVersion()
        amplitude.getInstance().logEvent(event, params);
        //@ts-ignore
        if(window?.FS){
            //@ts-ignore
            window.FS.event(event, params);
        }
        //@ts-ignore
        console.log(`tracked event, fs is ${Boolean(window?.FS)}`, event , params)
    } catch (error) {
        console.warn("amplitude event error", error)
    }
}

