
import { CallQueueReduxState, CallQueueActionType, callQueueActions } from 'constants/storeConstants';
import { parseCallQueue } from 'helpers/Parser';

const initialState: CallQueueReduxState = {
    jsonMSG: "",
    userGroups: [],
    queueList: [],
    queueObject: {},
    allCallQCallsObject: {},
    openQueueName: "",
    isOpenCallQueues: {}
}

function callsHistoryReducer(state: CallQueueReduxState = initialState, action: CallQueueActionType = { type: callQueueActions.None, payload: "" }): CallQueueReduxState {
    switch (action.type) {

        case callQueueActions.SetList:
            if (typeof (action.payload) === "string") {
                const parsed = parseCallQueue(action.payload)
                const newQueueObject = JSON.parse(JSON.stringify(state.queueObject));
                const newAllCallQCallsObject: any = {};
                const newQueueList: Array<any> = [];
                newQueueObject[parsed.queueName] = parsed.obj;
                Object.values(newQueueObject).forEach((curQueueObject: any) => {
                    Object.values(curQueueObject || {}).forEach((call: any) => {
                        newQueueList.push(call);
                        newAllCallQCallsObject[call.call_sid] = call;
                    })             
                })
                return { ...state, jsonMSG: action.payload, queueList: newQueueList, queueObject: newQueueObject,  allCallQCallsObject: newAllCallQCallsObject};
            }
            break;
        case callQueueActions.SetOpenQueueName:
            if (typeof (action.payload) === "string") {
                return { ...state, openQueueName: action.payload};
            }
            break;
        case callQueueActions.setQueueOpenStatus:
            if (typeof (action.payload) === "string") {
                const parsedQueueData = JSON.parse(action.payload);
                const newIsOpenCallQueues = JSON.parse(JSON.stringify(state.isOpenCallQueues));
                if(parsedQueueData?.queueName){
                    newIsOpenCallQueues[parsedQueueData?.queueName] = parsedQueueData?.isOpen;
                }
                return { ...state, isOpenCallQueues: newIsOpenCallQueues};
            }
            break;
        default:
            break;
    }
    return state;
}

export default callsHistoryReducer;
