import SubmitButton from 'components/form/SubmitButton'
import React from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'

import oops from 'assets/images/oops.svg'
import * as workizHelper from 'helpers/WorkizHelper'
import urlConstants from 'constants/urlConstants'
import EventsAdapter from 'helpers/EventsAdapter'
import Routing from 'constants/routingConstants'
import NetworkManager from 'helpers/NetworkManager'

const oopsImage = process.env.REACT_APP_WEB ? NetworkManager.buildBaseUrl(urlConstants.assets.oops) : oops;

function NoPhoneNumber(props: RouteComponentProps) {
    return (
        <div className="full-size flex-column padding-horizontal padding-vertical">
            <div className="flex-one center-content margin-horizontal flex-column">
                <img src={oopsImage} />
                <h2 className="margin-top">
                    Oops
                </h2>
                <p className="margin-top  center-text">
                    It looks like you don't have any numbers yet...
                </p>
                <p className="margin-top  center-text">
                    <Link to={Routing.Main} className="pointer _clearLink center" >
                        Check Again
                    </Link>
                </p>

            </div>
            <div className="margin-horizontal">
                <SubmitButton
                    onClick={() => {
                        workizHelper.goToURL(urlConstants.workizWeb.number, true)
                    }}
                    title="Claim / buy your number"
                />
            </div>
            <div className="center-content margin-top margin-bottom flex-column">
                <p className="small-margin-bottom">
                    OR
                </p>
                <div>
                    <a onClick={() => EventsAdapter.logout()} className={"pointer _clearLink center"}>
                        Login with a different account
                    </a>
                </div>
            </div>
        </div>
    )
}

export default NoPhoneNumber
