import { v4 as uuidGenerator } from 'uuid';
import TwilioManager from 'helpers/TwilioManager';
import WorkizLang from 'language/WorkizLang';

let uuid:string;

export const setUuid = (newUuid: string) => {
    uuid = newUuid;
}

export const openExternalLink = (link: string) => {
    const win = (window as any);

    if (hasOpener()) {
        win.opener.location.replace(link);
        const parent = win.open('', 'workizParent');
        parent!.focus();
    } else {
        const parent = win.open(win.location.origin, '');
        parent.location.replace(link);
        parent!.focus();
    }
}

const hasOpener = () => {
    const win = (window as any);
    try { // use try catch, in case opener changed origin
        if (win.opener && win.opener.browserCall) {
            return true;
        }
    }
    catch (e) { 
        console.log('window opener changed origin')
    }
}

export const addIncomingMsgToWindow = (text: string, accept: () => void, reject: () => void) => {
    if (hasOpener()) {
        const win = (window as any);
        win.opener.browserCall.setIncoming(
            text,
            reject,
            accept
        )
    }
}

export const removeIncomingMsgToWindow = () => {
    if (hasOpener()) {
        const win = (window as any);
        win.opener.browserCall.hideDiv();
    }
}

export const micPermission = () => {
    return true;
}

export const getVersion = () => {
    return process.env.REACT_WEB_VERSION;
}

const getDialerDefaults = () => {
    const defaults = localStorage.getItem('dialerDefaults');
    if (defaults) {
        return JSON.parse(defaults);
    }

    return {
        active: {},
        inactive: {},
    };
}

export const setDialerDefaults = (isAvailable: boolean, addSelf = true) => {
    const defaults = getDialerDefaults();
    delete defaults.active[uuid];
    delete defaults.inactive[uuid];

    // on component un-mount there is no need to add current dialer
    if (addSelf) {
        if (isAvailable) {
            defaults.active[uuid] = true;
        } else {
            defaults.inactive[uuid] = true;
        }
    }

    localStorage.setItem('dialerDefaults', JSON.stringify(defaults));
}

const closeDialerAction = () => {
    if(hasOpener()) {
        const _browserCall = (window as any).opener.browserCall;
        _browserCall.isUnloadEventSet = false;
        _browserCall.activePopup = null;
        _browserCall.hideDiv();
        _browserCall.clearCall();
    }
}

export const browserSetup = (twilioInstance: TwilioManager) => {
    setUuid(uuidGenerator());
    setDialerDefaults(twilioInstance.available);
    setActivePopupOnParentWindowUnload();

    (window as Window).addEventListener('beforeunload' ,(e) => { 
        e.preventDefault(); 
        return e.returnValue = WorkizLang.get().alerts.toLeaveMessage;
    });

    (window as Window).addEventListener('unload', () => {
        closeDialerAction();
        setDialerDefaults(twilioInstance.available, false);
    });
}

export const closeModal = (modal: any) => {
    if(modal && hasOpener()) {
        (window as any).opener.browserCall.closeModal(modal);
    }
}

const setActivePopupOnParentWindowUnload = () => {
    if(hasOpener()) {
        const parentWindow = (window as any).opener;
        const browserCall = parentWindow.browserCall;
        if(!browserCall.isUnloadEventSet) {
            browserCall.isUnloadEventSet = true;
            browserCall.activePopup = window;
            parentWindow.addEventListener('unload', unloadTimer)
        }
    }
}

const unloadTimer = () => setTimeout(setActivePopupOnParentWindowUnload, 5000);

export const runOnPopupReadForWeb = () => {
    const win = (window as any);
    if (process.env.REACT_APP_WEB && hasOpener() && typeof win.opener.browserCall.onPopupRead === "function") {
        win.opener.browserCall.onPopupRead();
    }
}