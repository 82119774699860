import React from 'react'
import ListRow from 'components/layout/ListRow'
import TwilioManager from 'helpers/TwilioManager'

function HoldCall(props: { onClose: VoidFunction }) {

    const changeHoldState = (forMe: boolean) => {
        TwilioManager.getInstance().holdCall(forMe)
        props.onClose()
    }

    return (
        <div>
            <ListRow title={"Hold for me"} onClick={() => changeHoldState(true)} />
            <ListRow title={"Hold for everyone"} leftBottom={<span className="small">Other users can pickup</span>} onClick={() => changeHoldState(false)} />
        </div>
    )
}

export default HoldCall
