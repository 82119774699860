import React from 'react'
import './socialLoginButton.scss';

interface SocialLoginButtonProps {
    icon: string,
    text: string,
    extraClassName?: string,
    backgroundColor: string,
    onClick: VoidFunction
}

function SocialLoginButton(props: SocialLoginButtonProps) {
    return (
        <div
            className={"social-button pointer " + (props.extraClassName ?? "")}
            style={{ backgroundColor: props.backgroundColor }}
            onClick={props.onClick}
        >
            <div className="iconContainer">
                <img src={props.icon} />
            </div>
            <div className="flex-one center-text">
                {props.text}
            </div>
        </div>
    )
}

export default SocialLoginButton
