import React from 'react';
import './callNotifications.scss';

interface CallNotificationsProps {
    icon: string,
    text: string,
    onClose: VoidFunction
}

const CallNotifications = ({icon, text, onClose}: CallNotificationsProps) => {
    return (
        <div className={'call_notifications'}>
            <div className={'call_notifications_inner'}>
                <div className={'left_container'}>
                    <i className={icon+ " call_notification_icon"} />
                    <div className={'call_notifications_text'}>
                        {text}
                    </div>
                </div>
                <div className='close_icon' >
                    <i className={"wfi-circle-2 pointer"} onClick={onClose}></i>
                </div>
            </div>
        </div>
    )
}

export default CallNotifications;