import React, { useEffect, useState } from 'react'
import Modal from 'components/layout/Modal'
import WorkizLang from 'language/WorkizLang'
import "./select.scss"
import { SearchBox } from '@workiz/ui';
interface SelectOption {
    value: any,
    label: any,
    [x: string]: any
}

interface SelectProps {
    options: Array<SelectOption>,
    onSelect: (value: any) => void,
    selectedValue: any,
    title: string,
    useSearchBar?: boolean,
    onSearchBarChange?: (value: any) => void,
    searchBarPlaceHolder?: string,
    modalExtraClass?: string
}

function Select(props: SelectProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLabel, setSelectLabel] = useState<any>("");
    const [searchValue, setSearchValue] = useState<string>('');

    const updateLabel = () => {
        if (props.selectedValue) {
            const idx = props.options.findIndex(_ => _.value == props.selectedValue);
            if (idx != -1) {
                setSelectLabel(props.options[idx].label);
                return;
            }
        }
        setSelectLabel("");
        return;
    }

    useEffect(updateLabel, [props.selectedValue])

    const selectItem = (item: SelectOption) => {
        props.onSelect(item.value);
        setIsOpen(false);
    }

    const Option = (_props: React.PropsWithChildren<{ item: SelectOption,isLast:boolean }>) => {
        const optionClasses = `pointer flex-row list-item center-content ${_props.isLast ? "" : "border-bottom"}`;
        return (
            <div className={optionClasses} onClick={() => selectItem(_props.item)}>
                <div className="flex-one">
                    {_props.item.label}
                </div> 
                <div className={`option ${_props.item.value === props.selectedValue?"selected-item":""}`}></div>
            </div>
        )
    }

    const ModalContent = () => {
        return (
            <div className="padding-screen">
                {props.options.map((_,index) => <Option key={_.label + "_modal_item"} item={_}  isLast={index === props.options.length - 1} />)}
            </div>
        )
    }

    const onChangeSearch = (value: any) => {
        const valueLowerCase = value.toLowerCase();
        props.onSearchBarChange && props.onSearchBarChange(valueLowerCase);
        setSearchValue(valueLowerCase);
    }

    return (
        <>
            <div className={"flex-one flex-row center-content small pointer"} onClick={() => setIsOpen(true)}>
                <div className={"selected-label center-text small-margin-right flex-one"}>
                    {selectedLabel ? selectedLabel : props.title}
                </div>
                <i className={"wfi-down select-icon"} />
            </div>
            <Modal show={isOpen} title={props.title} onClose={() => setIsOpen(false)} extraClass={props.modalExtraClass ?? ""}>
                {props.useSearchBar && 
                    <div className={"padding-screen padding-bottom-only"}>
                    <SearchBox
                        onChange={(e:any)=>onChangeSearch(e.target.value)} 
                        placeholder={props.searchBarPlaceHolder}
                        name="search"
                        value={searchValue}
                    />
                    </div>
                }
                <ModalContent />
            </Modal>
        </>
    )
}

export default Select
