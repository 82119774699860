import TwilioManager from 'helpers/TwilioManager'
import { getContactTypeDesc } from 'helpers/WorkizHelper'
import React, { useEffect, useState } from 'react'
import TabContainer from './TabContainer'
import SearchBar from 'components/form/SearchBar'
import ListRow from 'components/layout/ListRow'
import { phoneFormat } from 'helpers/StringFormats'
import * as data from 'helpers/Data'
import { ContactSuggestion } from 'constants/types'
import Loader from 'components/layout/Loader'
import NoResults from 'components/layout/NoResults'
import WorkizLang from 'language/WorkizLang'

function Contacts(props: { active: boolean }) {

    const [contacts, setContacts] = useState<Array<ContactSuggestion>>([])
    const [isSearching, setIsSearching] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        if (!isLoaded && !isSearching && props.active) {
            setIsSearching(true)
            setIsLoaded(true)
            submitSearch("")
        }
    }, [props.active])


    const getSearchResults = () => {
        if (isSearching) {
            return <div style={{ marginTop: 40 }}><Loader /></div>
        }
        if (!contacts.length) {
            return (
                <NoResults title={WorkizLang.get().data.noContacts} />
            )
        }
        return (
            <div style={{paddingLeft:2}} className="with-scroll">
                {contacts.map(_ => {
                    const is_client = Boolean(_.type=="3")
                    return (
                        <ListRow
                            key={_.data+"_"+ _.is_primary_phone}
                            onClick={() => TwilioManager.getInstance().placeCall(_.phone,_)}
                            title={String(_.name).trim() || "Unknown"}
                            small={is_client?_.client_company_name:""}
                            rightTop={<small className='bright-text' >{getContactTypeDesc(is_client?"3":"0")}</small>}
                            leftBottom={<small>{_.phoneFormatted}</small>}
                        />
                    )
                })}
            </div>
        )

    }

    const onCallToChange = (term: string) => {
        setIsSearching(true)
    }

    const submitSearch = async (term: string) => {
        const searchStr = term;
        const includeSecondryPhone = true;
        const suggestions = await data.searchContacts(searchStr, includeSecondryPhone)
        const preparedSugg: Array<ContactSuggestion> = [];
        suggestions.forEach(_ => {
            if (!_.phone) return;
            _.phoneFormatted = phoneFormat(_.phone, false, true)
            preparedSugg.push(_);
        })
        setContacts(preparedSugg)
        setIsSearching(false)
    }

    return (
        <TabContainer active={props.active}>
            <SearchBar
                searchTimeout={800}
                onTextChange={onCallToChange}
                onSearchChanged={submitSearch}
                placeholder={WorkizLang.get().data.searchClientsOrUsers}
                className={"margin-horizontal padding-vertical padding-screen relative"}
                inputExtraClass={"padding-input border-input border-radius"}
                searchIcon="wfi-search"
                iconExtraClass="search-icon-input"
                
            />
            {getSearchResults()}
        </TabContainer>
    )
}

export default Contacts