import launchDarklyTokens from "constants/launchDarklyTokens";
import User from 'helpers/User';
import {getEnvironmentByLocationHostname} from 'helpers/envHelper';
import * as LDClient from 'launchdarkly-js-client-sdk';

let client;


export const initFeatureFlags = async (isWebApp) => {
    try{
        const {accountId, ...custom} = getAttributes();
        const user = { key: accountId.toString(), custom };
        const clientSideId = getClientSideId();
        if(isWebApp){
            client = LDClient.initialize(clientSideId, user, {});
            client.on('ready', () => console.log("launchDarkly on web is ready!!!!!!555"))

        }
        else{
            const LDElectron = await import('launchdarkly-electron-client-sdk');
            client = await LDElectron.initializeInMain(clientSideId, user, {
                streaming: true,
                streamReconnectDelay: 10000
            });
            client.waitUntilReady().then(() => {
                console.log("launchDarkly is ready!!!!!!")
              })
              .catch(err => console.log(err));
        }
    } catch (e) {
        console.error(e);
    }
}

export function isEnabled(flagName, defaultValue) {
    let value;
    try {
        if(client){
            value = client.variation(flagName, defaultValue);
        }
    } catch (e) {
        console.error(e);
        value = defaultValue;
    }
    return value;
}

const getClientSideId = () => {
    return launchDarklyTokens[process.env.REACT_APP_BUILD_ENV] ?? launchDarklyTokens["development"];
}

const getAttributes = () => {
    return {
        accountId: Number(User.get().userData?.acid),
        converted: User.get().userData?.status==="converted",
        trial: Boolean(User.get().userData?.user?.trial),
        email: User.get().userData?.user?.email
    }
}

