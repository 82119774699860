import en_us from './en_us';

const languages = {
    en_us,
};

/**
 * @class WorkizLang
 */
export default class WorkizLang {
    /** @type {import('./WorkizDictionary').WorkizDictionary} */
    static currentLang = null;

    /**
     * @returns {import('./WorkizDictionary').WorkizDictionary}
     */
    static get() {
        if(this.currentLang == null) {
            this.setCurrentLang("en_us");
        }
        return this.currentLang;
    }

    /**
     * @param {"en_us"} language 
     * Changes the default language of the app
     */
    static setCurrentLang(language = "en_us") {
        WorkizLang.currentLang = languages[language];
    }
}
