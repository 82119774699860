import { WorkizDictionary } from "./WorkizDictionary";

const en_us: WorkizDictionary = {
    alerts: {
        cancel: "Cancel",
        close: "Close",
        toLeaveMessage: "Leaving this page will hang up the phone. are you sure?",
        oops: "Oops",
        somethingWrong: "Something is wrong"
    },
    login: {
        title: "Login",
        password: "Password",
        logout: "Log out",
        splashTitle: "For better communication",
    },
    forbiddenErrors: {
        disabled: "It seems that this account has been disabled, closed or canceled at the account owners request.",
        expired: "It looks like your free trial is over.",
        selectAPlan: "Select a plan",
        noAllowedIP: "Its seems your IP address (87.70.99.80) is restricted for this user. You might want to take it up with you account admin...",
        pendingSignUp: "It seems that you didn't complete your signup",
        completeSignUp: "Complete My Signup",
    },
    settings: {
        markAvailable: "Mark as available",
        markUnavailable: "Mark as unavailable",
    },
    data: {
        noContacts: "No contacts found",
        searchClients: "Search clients",
        searchClientsOrUsers: "Search contacts",
        searchContacts: "Search contacts",
        unknownName: "Unknown",
        searchNumbers: "Search",
    },
    dialer: {
        contacts: "Contacts",
        history: "Recents",
        keypad: "Keypad",
        numberOrContact: "Call phone or contact",
        addToCall: "Add to call",
        hold: "Hold",
        mute: "Mute",
        unmute: "Unmute",
        transfer: "Transfer call",
        unHold: "UnHold",
        holdCall: "Hold call",
        onHold: "On hold",
        conference: "Conference call",
        incomingCall: "Incoming call",
        activeCall: "Active call",
        allCalls: "All",
        missedCalls: "Missed",
        missedCallsCTA: "Unresolved",
        allMissedCalls: "All - Missed",
        connect: "Connect",
        wifiWarning: "Poor call connection",
        audioWarning: "No audio. Check headset and audio settings",
        reconnecting: "Reconnecting",
        connecting: "Connecting",
        hide: "Hide",
        recordingStandby: "Recording on standby",
        recordingOn: "Call is being recorded",
        inQueue: "In queue",
        noHistory: "No calls history",
        noCallQueue: "You have no calls waiting in queue",
        callsInQueue: "Calls in queue"
    },
    userTypes: {
        client: "Client",
        external: "External",
        unknown: "Unknown",
        user: "User",
        workiz: "Workiz",
    },
    workizWeb: {
        addNew: "Add new",
        sendVia: "Send via",
        job: "Job",
        lead: "Lead",
        client: "Client",
        flow: "Flow",
        adGroup: "Ad source",
        additionalContactOf: "Additional contact of:"
    },
    _2fa: {
        verifyItsYou: "Verify it's you",
        pleaseEnter: "Please enter the verification code we sent to",
        resendCode: "Resend code",
        in: "in",
        code: "Code",
        verify: "Verify",
        cancel: "Cancel",
        invalidCode: "Incorrect code."
    },
    sendRecording: {
        failed: "Failed to send recording. please try again",
    }
}

export default en_us;
