import CallButton from 'components/dialer/CallButton'
import Keypad from 'components/dialer/Keypad'
import TwilioManager from 'helpers/TwilioManager'
import React, { useEffect, useState } from 'react'
import TabContainer from './TabContainer'
import SearchBar from 'components/form/SearchBar'
import { maskPhoneNumber } from 'helpers/StringFormats'
import { useSelector } from 'react-redux'
import { DialerRootReducer } from 'store/reducers/rootReducer'
import User from 'helpers/User'

function DialPad(props: { active: boolean }) {

    const [callToNumber, setCallToNumber] = useState("")
    const [isLongInput, setIsLongInput] = useState(false);

    const callsHistoryList = useSelector((state: DialerRootReducer) => state.callsHistory)
    const placeCall = (callToNumber: string) => {
        if (!callToNumber || callToNumber === "") {
            callToNumber = User.get().shouldUseMasking() ? "" : callsHistoryList?.calls[0]?.phone
            setCallToNumber(callToNumber)
        } else {
            TwilioManager.getInstance().placeCall(callToNumber)
        }
    }

    const getKeypad = () => {
        return (
            <div className="keypad-container">
              <Keypad onClick={(digit) => onCallToChange(callToNumber + digit)} />
              <div className="center-content buttons-container-keypad">
              <CallButton onClick={() => placeCall(callToNumber)} />
              </div>
            </div>
        )
    }

    const onCallToChange = (term: string) => {    
        setIsLongInput(term.length > 14); 
        const masked = maskPhoneNumber(term)
        setCallToNumber(masked);     
        return masked;
    }

    return (
        <TabContainer active={props.active}>
            <div style={{ height: 30 }} />
            <div style={{ height: 50 }}>
                <SearchBar
                    autoFocus
                    searchTimeout={800}
                    initialValue={callToNumber}
                    onTextChange={onCallToChange}
                    className="big-margin-horizontal overflow-x-hidden"
                    searchIcon="wfi-back-delete-full"
                    inputExtraClass={`dial-pad-search no-border ${isLongInput ? 'small' : 'big'}`}
                    iconExtraClass={"dial-pad-search-icon" + (callToNumber === "" ? " invisible":"")}
                    isEraseIcon
                />
            </div>
            {
                getKeypad()
            }
        </TabContainer>
    )
}

export default DialPad