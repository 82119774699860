import { NotificationContent } from "constants/types";
import urlConstants from "constants/urlConstants";
import NetworkManager from "./NetworkManager";



export const notifyIncomingCall = (_: NotificationContent): Notification => {
    const notification = new Notification(_.title, {
        icon: NetworkManager.buildBaseUrl(urlConstants.assets.notificationIcon),
        body: _.body,
        tag: _.tag,
        requireInteraction: true,
        renotify: true
    });
    return notification;
}