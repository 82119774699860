
import React from 'react'
import { trackEvent, WorkizEvents } from '../../helpers/TrackActions';

interface AudioBarProps {
    recordings: Array<string>

}


function AudioBar(props: AudioBarProps) {

    const recordings = props.recordings

    if (!recordings.length) {
        return (
            <div>
                This call wasn't recorded
            </div>
        )
    }

    const onPlayCallback = () => {
        trackEvent(WorkizEvents.PlayedRecording, {source: 'dialer'});
    }

    const recordingsJSX = recordings.map((item, idx) => {
        return (
            <audio
                key={idx}
                style={{ width: "294px", height: "50px", marginBottom: "0.5em" }}
                controls
                id="audioPlayer"
                src={item}
                onPlay={onPlayCallback}
            >
                <p>audio not supported</p>
            </audio>
        )
    })

    return (
        <div>
            {recordingsJSX}
        </div>
    )
}

export default AudioBar;