import React from 'react'

function TransferringCall() {
    return (
        <div className="margin-left">
            Transferring...
        </div>
    )
}

export default TransferringCall
