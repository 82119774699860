import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom'
import NetworkManager from 'helpers/NetworkManager'
import urlConstants from 'constants/urlConstants'
import { Button, Input, Typography } from '@workiz/ui';
import Routing from 'constants/routingConstants'
import WorkizLang from 'language/WorkizLang'
const _2faScreen = (props: RouteComponentProps | any) => {
    const [code, setCode] = useState('');
    const [timer, setTimer] = useState(60);
    const [showResendButton, setShowResendButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const lng2FA = WorkizLang.get()._2fa;
   
    useEffect(() => {
        generateSession();

        const asyncCreate2FA = async () => {
            await create2FA();
        };

        asyncCreate2FA()

        const countdown = setInterval(() => {
            setTimer((prevTimer) => prevTimer - 1);
        }, 1000);

        return () => clearInterval(countdown);
    }, []);

    useEffect(() => {
        if (timer === 0) {
            setShowResendButton(true);
        }
    }, [timer]);

    const generateSession = () => {
        const session =  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        NetworkManager.set2FASession(session);
    };

    const create2FA = async (isResend = false) => {      
        await NetworkManager.post(urlConstants.user.create2FA, {
                                viaSms: true,
                                phoneNumber: props.location.state?.phoneNumber,
                                globalVerification: false,
                                sendToDefaultPhone: false,
                                forLogin: true,
                                session: NetworkManager.get2FASession(),
                                isResend})  
    }


    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value);
        setShowErrorMessage(false);
    };

    const handleResendCode = () => {
        setShowResendButton(false);
        setTimer(60);
        create2FA(true)
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        const res:any = await NetworkManager.post(urlConstants.user.verify2FA, {userProvidedCode:code, globalVerification: false, viaSms: true,  ttl: 60 * 60 * 24 * 30, session: NetworkManager.get2FASession()})
        if (res?.data?.success) {
            props.history.push(Routing.Main);
        } else {
            setShowErrorMessage(true);
            setLoading(false);
        } 
    };

    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const backToLogin = () => {
        props.history.push(Routing.Login);
    };

    const formatPhoneNumber = (phoneNumber: string) => {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const lastTwoDigits = cleaned.slice(-2); 
        return ' +1-' +'XXX-XXX-XX' + lastTwoDigits;
    }; 

    return (
        <div className='_2faContainer'>
            <i className='wfi-back-chevron' onClick={backToLogin}></i>
            <div className="_2faCodeContainer">
                <Typography variant="h3">{lng2FA.verifyItsYou}</Typography>
                <Typography variant="body1">{lng2FA.pleaseEnter} {formatPhoneNumber(props.location.state?.phoneNumber)}</Typography>
                <div className="codeInputSection">
                    <div className="inputWrapper">
                        <Input isError={showErrorMessage} withError={true} errorMessage={lng2FA.invalidCode} onChange={handleCodeChange} isFormField={false} name={"2fa"} value={code} placeholder='Verification code'></Input>
                    </div>
                    <div className={`resendSection ${showResendButton && "resendMargin"}`}>
                        {showResendButton ? 
                        <Button extraClass = {"resendButton"} type='tertiary' text = {lng2FA.resendCode} clickHandler={handleResendCode}></Button>
                        :
                        <>
                            <Typography variant='body2'>{lng2FA.resendCode}</Typography>
                            <Typography>{`${lng2FA.in} `} <span>{formatTime(timer)}</span></Typography>
                        </>}
                    </div>
               
                </div>
               
            </div>
            <div className='buttonContainer'>
                <Button loading={loading} text = {lng2FA.verify} clickHandler={handleSubmit}></Button>
                <Button type='tertiary' text = {lng2FA.cancel} clickHandler={backToLogin}></Button>
            </div>
            
        </div>
    );
};

export default _2faScreen;