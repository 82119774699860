import React from 'react'
import './keypad.scss';
import { useSelector } from 'react-redux';
import { DialerRootReducer } from 'store/reducers/rootReducer';
interface KeypadProps {
    /** The action that will be triggered upon click */
    onClick: (digit: string) => void,
  
    extraClass?: string
}

interface DigitProps {
    /** The main digit that will be sent on tapping this digit button */
    digit: string,
    /** Will be displayed in smaller font beneath the digit */
    letters: string,
    /** The action that will be triggered upon click */
    onClick: (digit: string) => void,
   
}


function Keypad(props: KeypadProps) {
    const voiceState = useSelector((state: DialerRootReducer) => state.voice)
    const isActiveCall = voiceState.onCall 
    const keys = [
        { digit: "1", letters: " " }, { digit: "2", letters: "ABC" }, { digit: "3", letters: "DEF" },
        { digit: "4", letters: "GHI" }, { digit: "5", letters: "JKL" }, { digit: "6", letters: "MNO" },
        { digit: "7", letters: "PQRS" }, { digit: "8", letters: "TUV" }, { digit: "9", letters: "WXYZ" },
        { digit: "*", letters: " " }, { digit: "0", letters: "+" }, { digit: "#", letters: " " }
    ]

    const onClick = (digit: string) => {
        props.onClick(digit);
    }

    return (
        <div className={`keypad ${isActiveCall?"active-call-keypad":""}`}>
            {keys.map(item => <Digit key={"dialer-digit-" + item.digit} onClick={onClick} {...item} />)}
        </div>
    )
}

function Digit(props: DigitProps) {
    const isAsterisk = props.digit === '*';
    return (
        <div className={`digit ${isAsterisk ? 'big-asterisk' : ''}`} onClick={() => props.onClick(props.digit)}>
            <span className={`number ripple ${isAsterisk ? 'big-asterisk-text' : ''}`}>{props.digit}</span>
            <span className={'dial-letters'}>{props.letters}</span>
        </div>
    );
}


export default Keypad
