import React from 'react';

const ButtonRadio = (props: {
    value: string,
    options: Array<{
        value: string, label: string
    }>,
    onChange: (value: string) => void
}) => {
    return (
        <div className="flex-row btnRadio">
            {
                props.options.map((opt) => {
                    const selected = (opt.value == props.value) ? " selected" : "";
                    return (
                        <div
                            key={opt.value}
                            className={"flex-one btnRadioBtn pointer " + selected}
                            onClick={selected ? undefined : () => props.onChange(opt.value)}>
                            {opt.label}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ButtonRadio