import React from 'react'
import styles from './header.module.scss'

interface HeaderProps {
    title: string
}

function Header(props: HeaderProps) {
    return (
        <div className={styles.container}>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.logo}>
                <i className="wi-com-logo" />
            </div>
        </div>
    )
}

export default Header
