import urlConstants from 'constants/urlConstants'
import NetworkManager from 'helpers/NetworkManager'
import TwilioManager from 'helpers/TwilioManager'
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DialerRootReducer } from 'store/reducers/rootReducer';
import * as callsHistoryActions from 'store/actions/callsHistoryActions'
import * as callQueueActions from 'store/actions/callsQueueActions'
import * as voiceActions from 'store/actions/voiceActions'
import * as userActions from 'store/actions/userActions';
import ActiveCall from 'containers/Dialer/ActiveCall/ActiveCall';
import Header from './Header'
import Footer from './Footer'
import { getCallsHistory } from 'helpers/Data'
import { connectSocket } from 'helpers/Socket'
import * as parser from 'helpers/Parser'
import * as data from 'helpers/Data'
import CallHistoryPage from './Tabs/CallHistoryPage'
import { CallsHistory, Contacts, DialPad } from './Tabs'
import EventsAdapter from 'helpers/EventsAdapter'
import './dialerMain.scss'
import { tabTypes } from 'constants/storeConstants'
import User from 'helpers/User'
import { RouteComponentProps } from 'react-router-dom'
import { CallHistoryItemPrepped, CallsHistoryItem, PhoneNumber } from 'constants/types'
import { setActiveTab } from 'store/actions/navigationActions'
import DeviceFailure from './ErrorPages/DeviceFailure'
import Routing from 'constants/routingConstants'
import {isEnabled} from 'helpers/FeatureFlags';
import * as FeatureFlagsNames from 'constants/FeatureFlagsNames';
import { trackEvent, WorkizEvents } from 'helpers/TrackActions'
import { callQueueAccountsEventsList } from './constants'
interface DialerMainProps extends RouteComponentProps {
    handledDeepLink?: () => void,
    deepLinkCall?: any,
}


export const DialerMain = (props: DialerMainProps) => {
    let socket: any;
    const dispatch = useDispatch();
    const voiceState = useSelector((state: DialerRootReducer) => state.voice)
    const navState = useSelector((state: DialerRootReducer) => state.navigation)
    const qState = useSelector((state: DialerRootReducer) => state.callQueue)

    const [loadingNumbers, setLoadingNumbers] = useState(true)
    const [phoneNumbers, setPhoneNumbers] = useState<Array<PhoneNumber>>([])
    const [activeCallModal, setActiveCallModal] = useState<CallHistoryItemPrepped | null>(null)
    const JWT_ERROR = "JWT Token Expired"
    
    useEffect(() => {
        const tm = TwilioManager.getInstance();
        if (!tm.available) return;
        const activeCallQueue = tm.getActiveCallQueue();
        const lastCallIndex = qState.queueList.length - 1;

        if (activeCallQueue) {
            const queueName = data.getQueueName(User.get().userData?.acid, activeCallQueue.user_id, activeCallQueue.group_id, activeCallQueue.flow_id);
            const queueObject = qState.queueObject[queueName] || {};
            if (!queueObject[activeCallQueue.call_sid]) {
                // Call queue that is ringing now was dequeued
                tm.setActiveCallQueue(undefined);
                delete tm.handledCallQueues[activeCallQueue.call_sid]
            }
        } else if (qState.queueList.length && !tm.handledCallQueues[qState.queueList[lastCallIndex].call_sid]) {
            // Call that wasn't handled yet is ringing now
            if(!isEnabled(FeatureFlagsNames.call_queue_revamp,false)){
                tm.setActiveCallQueue(qState.queueList[lastCallIndex]);
                tm.handledCallQueues[qState.queueList[lastCallIndex].call_sid] = true;
            }
        }
        if (!qState.queueList.length) {
            // We have no call in queue, so we can clear the handled call queues tracker
            tm.handledCallQueues = {}
        }
    }, [qState.jsonMSG])

    useEffect(() => {
        console.log("dialer main deep link", props.deepLinkCall)
        if (typeof props.deepLinkCall === "string") {
            const data = props.deepLinkCall.split("://")[1]
            if (data) {
                const decrypted = parser.decryptCallFromBrowser(data)
                initiateCallFromSite(decrypted);
            }
            if (typeof props.handledDeepLink === "function") {
                props.handledDeepLink()
            }
        }
    }, [props.deepLinkCall])

    useEffect(() => {
        const warningsArray = JSON.parse(localStorage.getItem("warningDuringCall") || '[]');
        if (warningsArray.length) { 
            NetworkManager.post(urlConstants.data.logWarningDuringCalls, { warningsArray }).then(() => {
                localStorage.removeItem("warningDuringCall");
            })
        }
    }, [])

    useEffect(() => {        
        if(process.env.REACT_APP_WEB) {
            (window as any).setCaller = (phoneNumber: any, modal: any, extraParams:any , cb: any) => {
                if(voiceState.onCall) {
                    if(typeof cb == 'function') cb('incall');
                    return 'incall';
                }
    
                const parsedCall = parser.parseCallFromBrowser({
                    phoneNumber,
                    extraParams
                })
                initiateCallFromSite(parsedCall);
                EventsAdapter.closeModal(modal);
    
                if(typeof cb == 'function') {
                    cb();
                }
            }
        }
    }, [qState.queueObject, voiceState.onCall])

    const initiateCallFromSite = (callObj: any) => {
        console.log("callObj call from browser", callObj)
        if (callObj && callObj.call_sid) {
            const call = TwilioManager.getInstance().getCallBySid(callObj.call_sid)
            const filtteredList = qState.queueList.filter(call => callObj.call_sid==call.call_sid);
            if (callObj.fromQ && filtteredList[0]?.call_sid) {
                TwilioManager.getInstance().connectQueue(call ?? {}, filtteredList[0]);
            }
            else if (call?.call_sid && callObj.fromHold) {
                TwilioManager.getInstance().connectToHeldCall(call);
            }
        } else if (callObj && callObj.phoneFormatted) {
            TwilioManager.getInstance().placeCall(callObj.phoneFormatted, callObj)
        }
    }

    const qStateRef = useRef(qState.jsonMSG);
    qStateRef.current = qState.jsonMSG;

    const activeCallRef = useRef(voiceState.activeCall?.conferenceParticipants);
    activeCallRef.current = voiceState.activeCall?.conferenceParticipants;
    
    const confRef = useRef(voiceState.onConference);
    confRef.current = voiceState.onConference;


    const socketListener = (msg: string) => {
        try {
            
            if (qStateRef.current != msg) {
                const before = parser.parseCallQueue(qStateRef.current)
                const after = parser.parseCallQueue(msg)
                // The socket sends for each call flow separately, 
                // therefore - need to check we are on the same data as before
                // base url is unique per flow
                const sameAsBefore = after.arr.length && before.arr.length && before.arr[0].base_url == after.arr[0].base_url;
                const inMyGroup = after.arr.length && User.get().queueGroups.includes(String(after.arr[0].group_id))
                const user_id = after.arr[0]?.user_id
                const logged_user = User.get().userData?.user?.id
                const call_sid = after.arr[0]?.call_sid
                const isEmpty = after.arr.length === 0
                const account_id = User.get().userData?.acid?.toString() || ''
                if (sameAsBefore || isEmpty || (!sameAsBefore && inMyGroup)  || (!sameAsBefore && (user_id == logged_user))) {
                    dispatch(callQueueActions.setCallQueue(msg));
                    if (callQueueAccountsEventsList.includes(account_id)){
                        trackEvent(WorkizEvents.fromSocketCallQueue,{user_id:logged_user,call_sid, account_id})
                    }
                }
            }
        }
        catch (e) {
            console.log("socket error")
        }
    }

    const callChangeListener = (msg: string) => {
        const call = parser.parseCallFromSocket(msg);
        if (call.call_sid) {
            dispatch(callsHistoryActions.updateCallsHistoryItem(call))
        }
    }

    const conferenceUpdated = (msg: string) => {
        const data = parser.parseConferenceDetails(msg, activeCallRef.current)
        if (data.participants) {
            dispatch(voiceActions.updateActiveCall({
                conferenceParticipants: data.participants,
                participantsCounter: data.counter,
            }))
            dispatch(voiceActions.setVoiceState({ onConference: confRef.current || (data.counter ?? 0) > 2 }))
        }
    }

    const getLatestGroupsMembers = () => {
        NetworkManager.post(urlConstants.queue.getQueueGroups, {}).then((res) => {
            if (Array.isArray(res.data)) {
                User.get().setQueueGroups(res.data);
            }
        }).catch((err) => console.log(err))
    }

    const loadAccountPhoneNumbers = async () => {
        setLoadingNumbers(true)
        const numbers = await data.getPhoneNumbers() || []
        setLoadingNumbers(false)
        setPhoneNumbers(numbers)
        dispatch(userActions.setUserNumbers(numbers));
        if (numbers.length > 0 && User.get().userData) {
            let idx = -1
            // todo: try fetching user defaults first
            const callingFromLS = TwilioManager.getInstance().getDialerSettings()?.callingFrom
            const workizNumber = User.get().userData?.workiz_number
            const callingFrom = callingFromLS && callingFromLS != "" ? callingFromLS : workizNumber
            
            if (callingFrom) {
                idx = numbers.findIndex(_ => _.number.includes(callingFrom))
            }

            if (idx == -1) idx = 0;
         
            TwilioManager.getInstance().setCallingFrom(numbers[idx].number)
            dispatch(voiceActions.setVoiceState({ callingFrom:numbers[idx].number }))
        } else {
            props.history.push(Routing.NoNumbers)
        }
    }

    const preLoad = async () => {
        loadAccountPhoneNumbers();
        getLatestGroupsMembers();
        getCallsHistory().then(list => {
            dispatch(callsHistoryActions.setCallsHistory(list));
        });

        TwilioManager.getInstance().getToken();
    }

    useEffect(() => {
        if (!User.get().socketParams.a) {
            props.history.push(Routing.Splash)
            return;
        }
        preLoad();
        socket = connectSocket();
        socket.on("call_queue_update", socketListener);
        socket.on("callUpdate", callChangeListener);
        socket.on("updateConferenceCall", conferenceUpdated);
        socket.on("groupsUpdated", getLatestGroupsMembers);
        return () => {
            console.log("leave dialer main")
            socket.off("call_queue_update", socketListener);
            socket.off("callUpdate", callChangeListener);
            socket.off("updateConferenceCall", conferenceUpdated);
            socket.off("groupsUpdated", getLatestGroupsMembers);
        }
    }, []);

    if (voiceState.deviceLoading || loadingNumbers) {
        return (
            <div className="center-content full-size flex-row">
                <img style={{ maxWidth: "200px" }} src={NetworkManager.buildBaseUrl(urlConstants.assets.mainLoader)} />
            </div>
        )
    }

    if (voiceState.lastError && !voiceState.onCall) {
        if (!(voiceState.lastError==JWT_ERROR && voiceState.onCall)) {
            return (
                <DeviceFailure msg={voiceState.lastError} />
            )
        }
    }

    return (

        <div className={"flex-column full-size"}>     
            <>
                <Header queueCounter={qState.queueList.length} callFromOptions={phoneNumbers} />
                <div className="flex-one set_containing_block">
                    <CallsHistory setActiveCallModal={setActiveCallModal}  active={navState.tab == tabTypes.HISTORY && activeCallModal == null} />
                    <Contacts active={navState.tab == tabTypes.CONTACTS} />
                    <DialPad active={navState.tab == tabTypes.KEYPAD} />
                    {activeCallModal !== null && !voiceState.onCall && <CallHistoryPage call={activeCallModal}  setActiveCallModal={setActiveCallModal} />}
                    <ActiveCall active={voiceState.onCall || voiceState.ringing} queueCounter={qState.queueList.length} />
                </div>
                <Footer setActiveCallModal={setActiveCallModal} />
            </>
            <audio id="call_incoming_sound" loop={true}>
                <source src={NetworkManager.buildBaseUrl(urlConstants.assets.ringtone)} type={"audio/wav"} />
            </audio>
        </div>
    )
}

export default DialerMain
