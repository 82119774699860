import React, {useState, useEffect} from 'react';
import * as data from '../../helpers/Data'
import Routing from '../../constants/routingConstants'
import { Redirect, RouteComponentProps } from "react-router-dom";
import NetworkManager from '../../helpers/NetworkManager';
import urlConstants from '../../constants/urlConstants';
import {initFeatureFlags} from 'helpers/FeatureFlags';

const WebLogin = (props: RouteComponentProps) => {
    const [isLogedIn, setIsLogedIn] = useState<boolean | null>(null);

    const loadUser = async() => {
        const flag = await data.getUserData();
        setIsLogedIn(flag);
        if(flag){
            initFeatureFlags(true);
        }
    }

    useEffect(() => {
        loadUser();
    })

    if (isLogedIn === true) {
        return <Redirect to={Routing.Main} />
    }
    if (isLogedIn === false) {
        return <Redirect to={Routing.NotAllowed} />
    }

    return (
        <div className="center-content full-size flex-row">
            <img style={{ maxWidth: "200px" }} src={NetworkManager.buildBaseUrl(urlConstants.assets.mainLoader)} />
        </div>
    )
}

export default WebLogin;