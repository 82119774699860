import React, { useEffect, useState } from 'react'
import Header from 'components/layout/Header'
import FloatingLabelInput from 'components/form/FloatingLabelInput'
import SubmitButton from 'components/form/SubmitButton'
import WorkizLang from 'language/WorkizLang'
import NetworkManager, { NetworkResponseType } from 'helpers/NetworkManager'
import { notifyIncomingCall } from "helpers/Notify"
import urlConstants from 'constants/urlConstants'
import './style.scss'
import { RouteComponentProps } from 'react-router-dom'
import * as data from 'helpers/Data'
import * as workizHelper from 'helpers/WorkizHelper'
import EventsAdapter from 'helpers/EventsAdapter'
import googleIcon from 'assets/images/icons/google_icon.svg'
import appleIcon from 'assets/images/icons/appleIcon.svg'
import facebookIcon from 'assets/images/icons/facebookIcon.svg'
import SocialLoginButton from 'components/form/SocialLoginButton'
import { trackEvent, WorkizEvents } from 'helpers/TrackActions'
import Routing from 'constants/routingConstants'
import {initFeatureFlags} from 'helpers/FeatureFlags';


export const LoginScreen = (props: RouteComponentProps) => {

    const [password, setPassword] = useState("");
    const [username, setUsername] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState("")
    const [isValid, setIsValid] = useState(false)

    let socialLoginSource = ""

    useEffect(() => {
        EventsAdapter.addEventListener("social-auth-received", onSocialLoginSuccess)
        return () => {
            EventsAdapter.removeEventListener("social-auth-received", onSocialLoginSuccess)
        }
    }, [])

    const onSocialLoginSuccess = async (event: any, data: any) => {
        if (data && data.accessToken && !isLoading) {
            afterLoginRequest(data, true, socialLoginSource)
        }
    }

    const validate = (username: string, password: string) => {
        setIsValid(!!(username.length && password.length))
        setErr("")
    }

    const submit = async () => {
        setErr("")
        setIsLoading(true);
        const res = await NetworkManager.auth(urlConstants.user.login, { username, password }).catch(() => { });
        afterLoginRequest(res)
    }

    const afterLoginRequest = async (res: NetworkResponseType | void, isSocial = false, socialSource = "") => {
        if (res && res.accessToken) {
            NetworkManager.setAuthToken(res.accessToken);
            if (res.refreshToken) {
                NetworkManager.setAuthRefreshToken(res.refreshToken)
            }
            if (res.use_login_2fa) {
                props.history.push({
                    pathname: Routing._2FA,
                    state: { phoneNumber: res.phone_number } 
                });
                return;
            }   
            const flag = await data.getUserData();
            trackEvent(WorkizEvents.ElectronDialerLogin, { isSocial, socialSource });
            if (flag) {
                initFeatureFlags(process.env.REACT_APP_WEB);
                props.history.push(Routing.Main);
            } else {
                props.history.push(Routing.NotAllowed);
            }
        } else {
            if (typeof res === "string") {
                setErr(res)
            } else {
                setErr("Login Failed")
            }
            setIsLoading(false)
        }
    }

    const getError = () => {
        if (err) {
            return <div className="red margin-top margin-horizontal">{err}</div>
        }
        return null;
    }

    const googleLogin = () => {
        EventsAdapter.googleLogin()
        socialLoginSource = "google"
    }

    const facebookLogin = () => {
        EventsAdapter.facebookLogin()
        socialLoginSource = "facebook"
    }

    return (
        <div className={"flex-column full-size"}
            onKeyUp={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter") {
                    submit()
                }
            }}
        >
            <Header title={WorkizLang.get().login.title} />

            <div className="container flex-one">

                <SocialLoginButton
                    icon={googleIcon}
                    text="Continue with Google"
                    extraClassName="margin-top"
                    onClick={googleLogin}
                    backgroundColor="#409AF7"
                />

                <SocialLoginButton
                    backgroundColor="#4C6297"
                    icon={facebookIcon}
                    text="Continue with Facebook"
                    extraClassName="margin-top"
                    onClick={facebookLogin}
                />

                {/* <SocialLoginButton
                    backgroundColor="#000"
                    icon={appleIcon}
                    text="Continue with Apple"
                    extraClassName="margin-top"
                    onClick={googleLogin}
                /> */}

                <div className="big-margin-top flex-row center-content ">
                    <div className="divider" />
                    <div className="margin-horizontal"> OR </div>
                    <div className="divider" />
                </div>
                <FloatingLabelInput
                    onChange={e => {
                        validate(e.target.value, password)
                        setUsername(e.target.value)
                    }}
                    value={username}
                    placeholder="Username"
                    className="margin-bottom big-margin-top" />
                <FloatingLabelInput
                    onChange={e => {
                        validate(username, e.target.value)
                        setPassword(e.target.value)
                    }}
                    value={password}
                    placeholder="Password"
                    className="margin-bottom"
                    type="password" />
                <SubmitButton
                    title={"Login"}
                    onClick={submit}
                    disable={!isValid}
                    isLoading={isLoading} />
                {getError()}
            </div>
            <div className="margin-all">
                <a onClick={() => {
                    workizHelper.goToURL(urlConstants.user.forgotPassword, true)
                }} className={"_clearLink pointer "}>Forgot Password</a>
            </div>
        </div>
    )
}


export default LoginScreen
